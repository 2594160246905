import * as React from "react";

import { InputNumber  } from "antd";

function ProductInputNumber(props) {
  const { productInfo, setProductInfo } = props;
  const { step, field } = props;
  const { errorField, setErrorField } = props;
  const { disabled, min, max, formatter, parser, onChangeHandle } = props;
  return (
    <div>
      <InputNumber
        style={{
          border: errorField !== undefined && errorField.includes(field)
            ? "1px solid red"
            : "1px solid #d9d9d9",
          borderRadius: "8px",
          background: `var(--backgroundColor)`,
          padding: "4px 12px",
          width: "100%"
        }}
        value={
          productInfo === undefined || productInfo[field] == undefined ? "" : productInfo[field]
        }
        placeholder={"0"}
        defaultValue ={ productInfo === undefined || productInfo[field] == undefined ? "" : productInfo[field]}
        disabled={disabled === undefined ? false : disabled}
        formatter={formatter === undefined ?  "": formatter}
        parser={parser === undefined ?  "": parser}
        
        min={min === undefined ? "0" : min}
        max={max === undefined ? "1000000" : max}
        step={step === undefined ? "1" : step}
        onChange={(value) => {
          var info = productInfo;
          info[field] = value;
          if (errorField !== undefined && errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            // console.log(errors);
            setErrorField([...errors]);
          }
          setProductInfo({ ...info });
          if(onChangeHandle !== undefined){
            onChangeHandle(value)
          }
        }}
      />
    </div>
  );
}

export default ProductInputNumber;
