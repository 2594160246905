import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetSetting, urlUpdateSetting } from "./CONSTANTS";



export const apiGetSetting = () => {
  const url = urlGetSetting;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiUpdateSetting(data) {
  const url = urlUpdateSetting;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("rent", data.rent);
  formData.append("tax_vat", data.tax_vat);
  formData.append("primary_currency", data.primary_currency);
  formData.append("chef_print_header_title", data.chef_print_header_title);
  formData.append("chef_print_header_description", data.chef_print_header_description);
  formData.append("chef_print_footer_title", data.chef_print_footer_title);
  formData.append("chef_print_footer_description", data.chef_print_footer_description);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}