import React from "react";
import { message, Spin, Tabs } from 'antd';
import "./EntAddPage.css";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { useEffect } from "react";
import { useState } from "react";
import EntInventory from "./EntInventory/EntInventory";
import EntRecipe from "./EntRecipe/EntRecipe";
import EntGroup from "./EntGroup/EntGroup";
import { apiGetSalesList } from "services/salesService";
const { TabPane } = Tabs;

const EntAddPage = () => {

    const [isBusy, setIsBusy] = useState(false);
    const [ownCommonData, setOwnCommonData] = useState({});
    const [entList, setEntList] = useState([]);

    useEffect(() => {
        setIsBusy(true)
        apiGetSalesList()
            .then((data) => {
                setIsBusy(false)
                getData(data)
            })
            .catch((err) => {
                setIsBusy(false)
                // message.error("Sorry! " + err);
            });
    }, []);

    const AddEntAction = (record) => {
        let No = 0
        if (entList.length !== 0) {
            No = entList[entList.length - 1].key + 1
        }
        
        let average_price_per_unit = 0
        if (record.hasOwnProperty('has_yield')) {
            average_price_per_unit = ownCommonData.salable_inv_List.filter(e => e.id === record.id)[0].average_price_per_unit
        }
        else {
            let item = ownCommonData.salable_recipe_List.filter(e => e.id === record.id)[0]
            average_price_per_unit = item.total_cost
        }

        let tmp = {
            key: No,
            id: record.id,
            name: record.name,
            thumbnail_image : record.thumbnail_image,
            enter_qty: record.enter_qty,
            average_price_per_unit : average_price_per_unit, 
            total_price : average_price_per_unit * record.enter_qty,
            is_inventory: record.hasOwnProperty('has_yield'),
            unit_id: record.unit_id,
        }
        let tmpList = [...entList]
        tmpList = [...tmpList, tmp]
        setEntList(tmpList)
    }
    const RemoveEntAction = (record) => {
        let updateData = [...entList];
        var storeIndex = updateData.findIndex(x => x.key == record.key);
        updateData.splice(storeIndex, 1);
        setEntList(updateData)
    }

    const getData = (data) => {
        const { salable_inv_List } = data === undefined ? { salable_inv_List: undefined } : data;
        const { salable_recipe_List } = data === undefined ? { salable_recipe_List: undefined } : data;
        const { unitList } = data === undefined ? { unitList: undefined } : data;
        const { unitConvertGlobalList } = data === undefined ? { unitConvertGlobalList: undefined } : data;
        const { unitConvertCustomList } = data === undefined ? { unitConvertCustomList: undefined } : data;
        if (data != undefined) {
            setOwnCommonData({
                salable_inv_List: salable_inv_List,
                salable_recipe_List: salable_recipe_List,
                unitList: unitList,
                unitConvertGlobalList: unitConvertGlobalList,
                unitConvertCustomList: unitConvertCustomList,
            })
        }
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <h1>Create Ent</h1>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Inventory List" key="1">
                        <EntInventory
                            ownCommonData={ownCommonData}
                            dataList={ownCommonData.salable_inv_List}
                            AddEntAction={AddEntAction}
                        />
                    </TabPane>
                    <TabPane tab="Recipe List" key="2">
                        <EntRecipe
                            ownCommonData={ownCommonData}
                            dataList={ownCommonData.salable_recipe_List}
                            AddEntAction={AddEntAction}
                        />
                    </TabPane>
                    <TabPane tab={<span style={{ color: 'var(--redColor)' }} >Your ENT List*<span> ({entList.length}) </span></span>} key="3">
                        <EntGroup
                            ownCommonData={ownCommonData}
                            dataList={entList}
                            RemoveEntAction={RemoveEntAction}
                        />
                    </TabPane>
                </Tabs>
            </Spin>
        </CommonDivWidget>
    );
}

export default EntAddPage;
