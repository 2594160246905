import React, { useState, useEffect } from 'react'
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import "./InventoryPage.css"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton'
import ProductSearch from 'components/ProductSearch/ProductSearch'
import { apiGetInventoryList, apiCreateInventory, apiUpdateInventory, apiDeleteInventory } from "services/inventoryService"
import { message, Row, Col, Spin, Switch, DatePicker } from 'antd'
import InventoryItemWidget from './InventoryItemWidget/InventoryItemWidget'
import InventoryListTableWidget from './InventoryListTableWidget/InventoryListTableWidget'
const { RangePicker } = DatePicker;

function InventoryPage() {

  const [inventoryList, setInventoryList] = useState([])
  const [isBusy, setIsBusy] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    apiGetInventoryList()
      .then((data) => {
        setInventoryList(data)
        setSearchList(data)
      })
      .catch((err) => {
        // message.error("Server failed. apiGetInventoryList " + err)
      })

  }, [])

  const onClickDelete = (pInfo) => {
    setIsBusy(true);
    apiDeleteInventory(pInfo.id)
      .then((data) => {
        setIsBusy(false);
        setInventoryList(data);
        setSearchList(data)
      })
      .catch((err) => {
        setIsBusy(false);
        //message.error("Server error. " + err);
        setInventoryList([]);
        setSearchList([])

      });
  }
  const onClickEdit = async (pInfo) => {

    // let info = pInfo
    // var res = await showAddInventoryDialog({
    //   title: "Edit Inventory",
    //   inventoryInfo: info,
    //   isEditing: true
    // });

    // console.log("res", res)
    // if (res === null) return;

    // setIsBusy(true);
    // apiUpdateInventory(res)
    //   .then((data) => {
    //     setIsBusy(false);
    //     if (data === null) {
    //       message.error("There's something wrong while updating a inventory");
    //     }
    //     let updateData = [...inventoryList];
    //     var storeIndex = updateData.findIndex(x => x.id == data[0].id);
    //     updateData.splice(storeIndex, 1, data[0]);
    //     setInventoryList(updateData)
    //     setSearchList(updateData)

    //   })
    //   .catch((err) => {
    //     setIsBusy(false);
    //     message.error("Sorry. updateNewInventory " + err);
    //   });
  }
  const addNewInventory = async () => {
    // var res = await showAddInventoryDialog({
    //   title: "Create New Inventory",
    //   isEditing: false
    // });
    // if (res === null) return;
    // setIsBusy(true);
    // apiCreateInventory(res)
    //   .then((data) => {
    //     setIsBusy(false);
    //     if (data === null) {
    //       message.error("There's something wrong while creating a inventory");
    //     }
    //     let updateData = [...inventoryList];
    //     updateData = [...data, ...updateData];
    //     setInventoryList(updateData)
    //     setSearchList(updateData)
    //   })
    //   .catch((err) => {
    //     setIsBusy(false);
    //     message.error("Error! " + err);
    //   });

  }

  const inventoryListUI = searchList == undefined || searchList.length == 0 ? <div>You don't have any inventory list</div> : searchList.map((info, index) => {
    return (
      <Col xs={24} sm={8} md={8} lg={8} xl={4} key={index}>
        <InventoryItemWidget
          inventoryInfo={info}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}

        />
      </Col>
    );
  });
  const onViewMode = (checked) => {
    setViewMode(checked)
  }

  const handleDateFilter = (startDate, endDate) => {
    if (startDate == "" || endDate == "") {
      setSearchList([...inventoryList])
    } else {
      const start_date = new Date(startDate + "T00:00:00");
      const end_date = new Date(endDate + "T23:59:59");
      const tmpList = inventoryList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
      //const tmpList = inventoryList.filter((info) => start_date <= new Date(info.add_timestamp.replace(/ /g, "T")) && new Date(info.add_timestamp.replace(/ /g, "T")) <= end_date)
      setSearchList([...tmpList])
    }
  }
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="inventory-header">
          Inventory List
          <Switch
            checkedChildren="Grid"
            unCheckedChildren="List"
            defaultChecked
            onChange={onViewMode}
            style={{ marginLeft: "10px" }}
          />
          <div className="inventory-extra">
            <CommonButton onClick={() => {
              addNewInventory()
            }}>New Inventory</CommonButton>
          </div>
        </div>


        <div>

          <Row>
            <Col>
              <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                <RangePicker size={'default'}
                  onChange={(e, date) => {
                    if (date == null) {
                      handleDateFilter("", "")
                    } else {
                      handleDateFilter(date[0], date[1])
                    }

                  }} />
              </div>
            </Col>
            <Col flex={'auto'}>
              <ProductSearch ownProducts={inventoryList} onSelectProduct={onClickEdit}></ProductSearch>

            </Col>

          </Row>



          {
            viewMode ?
              <Row align={'middle'} gutter={16} style={{ marginTop: "10px" }} className="product-page-favourite-products">
                {inventoryListUI}
              </Row>
              :
              <div className="table-widget">
                <InventoryListTableWidget
                  inventoryList={searchList}
                  onUpdateAction={onClickEdit}
                  onDeleteAction={onClickDelete}
                />
              </div>
          }


        </div>
      </Spin>
    </CommonDivWidget>
  )
}

export default InventoryPage
