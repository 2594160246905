import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Table, Row, List, Avatar } from "antd";
import { showColumnAction } from './CategoryListTableColumn';
import "./CategoryListTableWidget.css"
import { PicCenterOutlined } from '@ant-design/icons';
function CategoryListTableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { categoryList, onUpdateAction, onDeleteAction, } = props;
  if (categoryList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      width: '30px',
      align: "center",
      dataIndex: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "name",
    //   align: "center",
    //   sorter: {
    //     compare: (a, b) => a.name.localeCompare(b.name),
    //   },
    //   render: (text, record) => {
    //     return <span>This is Testing Description.</span>
    //   }
    // },

    {
      title: "Created",
      width: '120px',
      align: "center",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated",
      align: "center",
      width: '120px',
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Status",
      align: "center",
      width: '50px',
      dataIndex: "active",
      sorter: {
        compare: (a, b) => a.active - b.active,
      },
      render: (text, record) => {
        if (record.active === "1") {
          return <span className="active-btn">A</span>
        }
        else {
          return <span className="inactive-btn">I</span>
        }
      }
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: '100px',
      render: (text, record) => {
        return showColumnAction(text, record, onUpdateAction, onDeleteAction)
      },
    },
  ];
  const categoryDataSource = categoryList.map((info) => {
    return { ...info, key: info.id };
  });
  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={categoryDataSource}
      />
    </div>
  )
}

export default withRouter(CategoryListTableWidget)
