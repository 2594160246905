// @flow strict

import * as React from "react";

import { Input, Typography } from "antd";

const { Text } = Typography;
function NormalInput(props) {
  const { vendorInfo, setVendorInfo } = props;
  const { placeholder, field } = props;
  const { errorField, setErrorField } = props;
  const { disabled, type, is_password } = props;
  return (
    <div style={{ textAlign: "left", marginBottom: 10 }}>
      <Input
        style={{
          border: errorField.includes(field)
            ? "1px solid red"
            : "1px solid #707070",
          borderRadius: "8px",
          background: `var(--backgroundColor)`,
          padding: "12px"
        }}
        placeholder={placeholder}
        value={vendorInfo[field] == undefined ? "" : vendorInfo[field]}
        disabled={disabled === undefined ? false : disabled}
        type={type === undefined ? "text" : type}
        
        onChange={(e) => {
          var info = vendorInfo;
          info[field] = e.target.value;
          if (errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            // console.log(errors);
            setErrorField([...errors]);
          }
          setVendorInfo({ ...info });
        }}
      />
    </div>
  );
}

export default NormalInput;
