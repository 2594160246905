
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetPayHistoryList, urlGetPayHistoryListWithDate } from "./CONSTANTS";

export const apiGetPayHistoryList = () => {
    const url = urlGetPayHistoryList;
    return new Promise((resolve, reject) => {
      axiosGet(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export function apiGetPayHistoryListWithDate(data) {
    const url = urlGetPayHistoryListWithDate;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("start_date", data?.start_date);
    formData.append("end_date", data?.end_date);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }