import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateUserSiteSubCategory, urlDeleteUserSiteSubCategory, urlGetUserSiteSubCategoryList, urlUpdateUserSiteSubCategory, urlUpdateUserSiteSubCategoryPosition } from "./CONSTANTS";

export function apiCreateSubCategory(data, main_category_id) {
  const url = urlCreateUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  formData.append("name", data.name);
  formData.append("status", data.status);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateSubCategory(data, main_category_id) {
  const url = urlUpdateUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("main_category_id", main_category_id);
  formData.append("name", data.name);
  formData.append("status", data.status);
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateSubCategoryPosition(dataList) {
  const url = urlUpdateUserSiteSubCategoryPosition;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var idList = []
  for (let i = 0; i<dataList.length; i++) {
    idList.push({id : dataList[i]['id']})
  }
  var formData = new FormData();
  formData.append("dataList", JSON.stringify(idList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetSubCategoryList = (main_category_id) => {
  const url = urlGetUserSiteSubCategoryList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};


export function apiDeleteSubCategory(id) {
  const url = urlDeleteUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
