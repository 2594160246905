import { Col, Input, InputNumber, message, Modal, Row } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateCustomUnitConvert, apiUpdateCustomUnitConvert } from "services/unitConvertService";
import "./AddNewRecipeUnitConvertDialog.css";
const { TextArea } = Input;
function AddNewRecipeUnitConvertDialog(props) {
  const { proceed, show } = props;
  const { selInfo, oldList, ownCommonData } = props;
  const [newUnit, setNewUnit] = useState({});
  const { isEdit, title } = props;

  useEffect(()=>{
    if(selInfo !== undefined){
      setNewUnit(selInfo)
    }
  }, [selInfo])
//   console.log(oldList);
  const createNewUnit = () => {
    if (newUnit.recipe_id == "" || newUnit.recipe_id === undefined){
      message.error(`Please select Recipe`);
      return;
    } 
    if (newUnit.unit_id == "" || newUnit.unit_id === undefined){
      message.error(`Please select Master Unit`);
      return;
    } 
    if (newUnit.standard_unit_id == "" || newUnit.standard_unit_id === undefined){
      message.error(`Please select Standard Unit`);
      return;
    } 
    apiCreateCustomUnitConvert(newUnit)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Unit. " + err);
        });
  };

  const updateNewUnit = () => {
    if (newUnit.unit_id == "" || newUnit.unit_id === undefined){
      message.error(`Please select Master Unit`);
      return;
    } 
    if (newUnit.standard_unit_id == "" || newUnit.standard_unit_id === undefined){
      message.error(`Please select Standard Unit`);
      return;
    } 
    apiUpdateCustomUnitConvert(newUnit)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Unit. " + err);
        });
  };

  const onOKAction = () => {
    if (isEdit === false) {
      createNewUnit()
    }
    else {
      updateNewUnit()
    }
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-custom-unit-convert"
      onOk={onOKAction}
    >
      <div>Please Enter correct Info.</div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
            <ProductTypeSelector
              placeholder={"Select Recipe"}
              field={"recipe_id"}
              productInfo={newUnit}
              setProductInfo={setNewUnit}
              list={ownCommonData.recipeList}
              displayField="name"
              idField="id"
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <div>
              <InputNumber
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "4px 12px",
                  width: "100%"
                }}
                defaultValue={1}
                disabled={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={5}>
            <ProductTypeSelector
              placeholder={"Select Unit"}
              field={"unit_id"}
              productInfo={newUnit}
              setProductInfo={setNewUnit}
              list={ownCommonData.unitList}
              displayField="name"
              idField="id"
            />
          </Col>
          <div> = </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={4}>
            <ProductInputNumber
              field={"rate"}
              productInfo={newUnit}
              setProductInfo={setNewUnit}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={5}>
            <ProductTypeSelector
              placeholder={"Select Unit"}
              field={"standard_unit_id"}
              productInfo={newUnit}
              setProductInfo={setNewUnit}
              list={ownCommonData.unitList}
              displayField="name"
              idField="id"
            />
          </Col>
      </Row>
    </Modal>
  );
}

export default confirmable(AddNewRecipeUnitConvertDialog);
