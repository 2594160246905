import React from 'react';
import "./TopWidget.css"
import { Row, Col } from "antd";

import { ReactComponent as storeIcon } from '../../../assets/icons/ic_store.svg';
import { ReactComponent as promotionIcon } from '../../../assets/icons/ic_promotion.svg';
import { ReactComponent as productIcon } from '../../../assets/icons/ic_product.svg';
import { ReactComponent as followerIcon } from '../../../assets/icons/ic_follower.svg';
import CategoryItemWidget from './CategoryItemWidget/CategoryItemWidget';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
import { ROUTE_INGREDIENT_LIST, ROUTE_INVENTORY_NAME, ROUTE_PROFILE, ROUTE_RECIPE_LIST } from 'navigation/CONSTANTS';

const TopWidget = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div>
            <Row align={'middle'} gutter={32}>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={storeIcon}
                        title="You will manage total Inventory Info"
                        count={''}
                        type="Inventory List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_INVENTORY_NAME}))
                            history.push(ROUTE_INVENTORY_NAME)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={promotionIcon}
                        title="With Inventory and Yield, You will manage all ingredient"
                        count={''}
                        type="Ingredient List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_INGREDIENT_LIST}))
                            history.push(ROUTE_INGREDIENT_LIST)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={productIcon}
                        title="You will setup all recipe info"
                        count={''}
                        type="Recipe List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_RECIPE_LIST}))
                            history.push(ROUTE_RECIPE_LIST)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={followerIcon}
                        title="Super Admin Setup"
                        count={''}
                        type="Profile"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PROFILE}))
                            history.push(ROUTE_PROFILE)
                        }}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default TopWidget;