import Icon, { SettingOutlined, ShoppingCartOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { ReactComponent as dashboardIcon } from '../assets/icons/ic_dashboard.svg';
import { ReactComponent as storeIcon } from '../assets/icons/ic_store.svg';
import { ReactComponent as analyticsIcon } from '../assets/icons/ic_analytics.svg';
import { ReactComponent as promotionIcon } from '../assets/icons/ic_promotion.svg';
import { ReactComponent as ingredientIcon } from '../assets/icons/ic_ingredient.svg';
import { ReactComponent as bestdealIcon } from '../assets/icons/ic_bestdeal.svg';
import { ReactComponent as productIcon } from '../assets/icons/ic_product.svg';
import { ReactComponent as postIcon } from '../assets/icons/ic_post.svg';
import { ReactComponent as categoryIcon } from '../assets/icons/ic_category.svg';
import { ReactComponent as locationIcon } from '../assets/icons/ic_location.svg';
import { ReactComponent as unitIcon } from '../assets/icons/ic_unit.svg';

export const ROOT = "/";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_TABLE_PLAN = "/table/plan";
export const ROUTE_TABLE_PLAN_LIST = "/table/plan/list";
export const ROUTE_INVENTORY = "/inventory";
export const ROUTE_INVENTORY_NAME = "/inventory/name";
export const ROUTE_INVENTORY_LOCATION = "/inventory/location";
export const ROUTE_INVENTORY_FILED = "/inventory/filed";
export const ROUTE_RECIPE_ADD = "/recipe/add";
export const ROUTE_RECIPE_LIST = "/recipe/list";
export const ROUTE_RECIPE_EDIT = "/recipe/edit";
export const ROUTE_YIELD_RULE = "/yield/rule";
export const ROUTE_YIELD_RULE_EDIT = "/yield/edit";
export const ROUTE_YIELD_RULE_LIST = "/list/yield/rule";
export const ROUTE_CATEGORY = "/category";
export const ROUTE_LOCATION = "/location";
export const ROUTE_UNIT = "/unit";
export const ROUTE_INGREDIENT_LIST = "/ingredient/list";
export const ROUTE_PAY_HISTORY = "/pay/history";
export const ROUTE_ORDER = "/order";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_USERS = "/users";
export const ROUTE_SPOILAGE_ADD = "/spilage/add";
export const ROUTE_SPOILAGE_LIST = "/spilage/list";
export const ROUTE_SALABLE_TYPE = "/salable/type";
export const ROUTE_ENT_ADD = "/ent/add";
export const ROUTE_ENT_LIST = "/ent/list";
export const ROUTE_TEAM_MEMBER_LIST = "/team/member";
export const ROUTE_SALARY = "/salary/";
export const ROUTE_SETTING = "/setting/";
export const ROUTE_REPORT = "/report/";
export const ROUTE_COST = "/cost/";
export const ROUTE_SALES_ANALYSIS = "/sales/analysis/";
export const ROUTE_USER_ITEM_MAIN_CATEGORY = "/user/item/main/category";
export const ROUTE_USER_ITEM_SUB_CATEGORY  = "/user/item/sub/category";
export const ROUTE_USER_MAIN_SUB_ITEM_LIST  = "/user/main/sub/item/list";
export const ROUTE_USER_MAIN_SUB_ITEM_ADD  = "/user/main/sub/item/add";
export const ROUTE_USER_MAIN_SUB_ITEM_EDIT  = "/user/main/sub/item/edit";

export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";



export const MENU_ITEMS = [
    { route: ROUTE_DASHBOARD, icon: <Icon component={dashboardIcon} className="menu-item-icon" />, title: "Dashboard", type:"sidebar" },
    { route: ROUTE_TABLE_PLAN_LIST, icon: <Icon component={storeIcon} className="menu-item-icon" />, title: "Manage Room", type:"sidebar"},
    // { route: ROUTE_INVENTORY, icon: <Icon component={storeIcon} className="menu-item-icon" />, title: "Outside Inventory", type:"sidebar"},
    { route: ROUTE_INVENTORY_NAME, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Manage Inventory", type:"sidebar", 
        // submenu:[
        //     { route: ROUTE_INVENTORY_NAME, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "By Name", type:"sidebar"},        
        //     { route: ROUTE_INVENTORY_CATEGORY, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "By Category", type:"sidebar"},
        //     { route: ROUTE_INVENTORY_LOCATION, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "By Location", type:"sidebar"},        
        // ]
    },
    { route: ROUTE_YIELD_RULE, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Create Yield Rule", type:"sidebar"},
    { route: ROUTE_YIELD_RULE_LIST, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Yield Rule List", type:"sidebar"},
    { route: ROUTE_INGREDIENT_LIST, icon: <Icon component={bestdealIcon} className="menu-item-icon" />, title: "Ingredient List", type:"sidebar"},
    { route: ROUTE_RECIPE_ADD, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "Create Recipe", type:"sidebar"},
    { route: ROUTE_RECIPE_LIST, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Recipe List", type:"sidebar"},
    { route: ROUTE_ORDER, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Order List", type:"sidebar"},
    { route: ROUTE_PAY_HISTORY, icon: <Icon component={ingredientIcon} className="menu-item-icon" />, title: "Payment History", type:"sidebar"},
    { route: ROUTE_SPOILAGE_ADD, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Create Spoilage", type:"sidebar"},
    { route: ROUTE_SPOILAGE_LIST, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Spoilage List", type:"sidebar"},
    { route: ROUTE_ENT_ADD, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Create ENT", type:"sidebar"},
    { route: ROUTE_ENT_LIST, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "ENT List", type:"sidebar"},
    { route: ROUTE_TEAM_MEMBER_LIST, icon: <Icon component={ingredientIcon} className="menu-item-icon" />, title: "Team Members", type:"sidebar"},
    { route: ROUTE_SALARY, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Salary List", type:"sidebar"},
    { route: ROUTE_CATEGORY, icon: <Icon component={categoryIcon} className="menu-item-icon" />, title: "Category List", type:"sidebar"},
    { route: ROUTE_LOCATION, icon: <Icon component={locationIcon} className="menu-item-icon" />, title: "Location List", type:"sidebar"},
    { route: ROUTE_UNIT, icon: <Icon component={unitIcon} className="menu-item-icon" />, title: "Unit List", type:"sidebar"},
    { route: ROUTE_SALABLE_TYPE, icon: <Icon component={unitIcon} className="menu-item-icon" />, title: "Sellable Type", type:"sidebar"},
    { route: ROUTE_REPORT, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "Manage Report", type:"sidebar"},
    { route: ROUTE_COST, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "Manage Cost", type:"sidebar"},
    //{ route: ROUTE_SALES_ANALYSIS, icon: <Icon component={bestdealIcon} className="menu-item-icon" />, title: "Sales Analysis", type:"sidebar"},
    // { icon: <Icon component={postIcon} className="menu-item-icon" />, title: "Manage Report", type:"sidebar", 
    //     submenu:[
    //         { route: ROUTE_REPORT, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Generate Report", type:"sidebar"},
    //         { route: ROUTE_SALES_ANALYSIS, icon: <Icon component={bestdealIcon} className="menu-item-icon" />, title: "Sales Analysis", type:"sidebar"},
    //     ]
    // },
    { route: ROUTE_PROFILE, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Profile", type:"sidebar"},
    { route: ROUTE_SETTING, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "Setting Info", type:"sidebar"},
    { route: ROUTE_USER_ITEM_MAIN_CATEGORY, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "User Site Category", type:"sidebar"},

]

export const SUPER_ITEM = [
    { route: ROUTE_USERS, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Users", type:"sidebar"},
]
