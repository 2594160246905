import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import "./SalableTypeListTableWidget.css"

function SalableTypeListTableWidget(props) {
    const { dataList, setDataList, onUpdateAction, onDeleteAction, } = props;
    if (dataList === undefined) return <div />
    const columns = [
        {
          title: "ID",
          align: "center",
          dataIndex: "id"
        },
        {
          title: "Name",
          dataIndex: "name",
          width : '400px',
          align: "center",
          sorter: {
            compare: (a, b) => a.name.localeCompare(b.name),
          },
        },
        {
          title: "Fill Color On Chef Site",
          dataIndex: "fill_color",
          align: "center",
          sorter: {
            compare: (a, b) => a.fill_color.localeCompare(b.fill_color),
          },
          render: (text, record) => {
            return <span className="fill-color" style={{ backgroundColor: record.fill_color}}>{record.fill_color}</span>
          }
        },
        {
          title: "Created At",
          align: "center",
          dataIndex: "add_timestamp",
          sorter: {
            compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
          },
        },
        {
          title: "Updated At",
          align: "center",
          dataIndex: "update_timestamp",
          sorter: {
            compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
          },
        },
        {
          title: "Status",
          align: "center",
          dataIndex: "active",
          sorter: {
            compare: (a, b) => a.active - b.active,
          },
          render: (text, record) => {
            if (record.active === "1") {
              return <span className="active-btn">A</span>
            }
            else {
              return <span className="inactive-btn">I</span>
            }
          }
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {

                return <div size="middle" direction={"vertical"}>
                    <a className="edit-btn"
                        onClick={() => {
                            onUpdateAction(record)
                        }}
                    >
                        Edit
                    </a>
                    <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                        var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                        if (res == false) return;
                        onDeleteAction(record)
                    }}>
                        Delete
                    </a>
                </div>
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(SalableTypeListTableWidget)
