import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { message, Spin, DatePicker, Row, Col, Switch } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import { useHistory, useParams } from 'react-router-dom';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
import TableWidget from './TableWidget/TableWidget';
import { apiAddUserSiteItemMainSub, apiDeleteUserSiteItemMainSub, apiGetUserSiteItemMainSubByID, apiGetUserSiteItemMainSubList, apiUpdateUserSiteItemMainSub } from 'services/userSiteItemMainSubService';
import { ROUTE_USER_MAIN_SUB_ITEM_ADD } from 'navigation/CONSTANTS';
import { showAddItemDialog } from '../AddItemDialog/showAddItemDialog';
import ProductImageWidget from '../AddItemDialog/ProductImageWidget';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { isEmpty } from 'utils/GlobalFunctions';
import { showAddExtraOptionDialog } from './ExtraOptionDialog/showAddExtraOptionDialog';
import ExtraOptionTableWidget from './ExtraOptionTableWidget/ExtraOptionTableWidget';
import { showAddExtraGroupDialog } from './ExtraGroupDialog/showAddExtraGroupDialog';
import ExtraGroupTableWidget from './ExtraGroupTableWidget/ExtraGroupTableWidget';
import TableWidgetRecipeExtra from './TableWidget/TableWidgetRecipeExtra';
const { RangePicker } = DatePicker;

function AddItemPage(props) {

    const { main_category_id, sub_category_id, edit_id } = useParams();
    const history = useHistory();
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [sortList, setSortList] = useState([]);
    const [dataExtraRecipeList, setDataExtraRecipeList] = useState([]);
    const [selItem, setSelItem] = useState({ "show_note": "0", "status": "0" });
    const [errorField, setErrorField] = useState([]);
    const [dataExtraList, setDataExtraList] = useState([]);
    useEffect(() => {
        if (edit_id) {
            setIsBusy(true)
            apiGetUserSiteItemMainSubByID(edit_id)
                .then((data) => {
                    setIsBusy(false);
                    let tmp_selItem = {
                        "name": data["name"],
                        "thumbnail_image": data["thumbnail_image"],
                        "show_note": data["show_note"],
                        "status": data["status"],
                    }
                    setSelItem({ ...tmp_selItem })

                    setDataList([...data["recipeList"]])
                    setSortList([...data["recipeList"]])
                    if (data["extraOptionList"] !== null) {
                        setDataExtraList([...data["extraOptionList"]])
                    }
                    if (data["recipe_extra"] !== null) {
                        setDataExtraRecipeList([...data["recipe_extra"]])
                    }
                    

                })
                .catch((err) => {
                    setIsBusy(false);
                    message.error("Error! " + err);
                });
        }
    }, []);

    const onViewMode = (checked) => {
        setSelItem({
            ...selItem, "show_note": checked === true ? "1" : "0"
        });
    }

    const onViewModeStatus = (checked) => {
        setSelItem({
            ...selItem, "status": checked === true ? "1" : "0"
        });
    }

    const validateFields = async () => {
        var errorList = Array();
        errorList = isEmpty(selItem, "name", errorList);
        errorList = isEmpty(selItem, "image_name", errorList);
        setErrorField([...errorList]);
        return errorList.length == 0;
    };

    const onSave = async () => {
        if ((await validateFields()) === false) return;
        if (sortList.length === 0) {
            message.warning("Please add recipe items")
            return
        }

        setIsBusy(true)
        apiAddUserSiteItemMainSub(main_category_id, sub_category_id, selItem, sortList, dataExtraList, dataExtraRecipeList)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while adding Item");
                }
                message.success("Successfully Saved!");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });

    }

    const onUpdate = async () => {

        if (sortList.length === 0) {
            message.warning("Please add recipe items")
            return
        }

        setIsBusy(true)
        apiUpdateUserSiteItemMainSub(edit_id, selItem, sortList, dataExtraList, dataExtraRecipeList)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating Item");
                }
                message.success("Successfully Saved!");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });

    }

    const onAddItem = async () => {
        var res = await showAddItemDialog({
            title: "Add Recipe on User Site",
            itemList: dataList
        });
        if (res === null || res.length == 0) return;
        setDataList(res)
        setSortList(res)
    }
    const onAddExtraRecipe = async () => {
        var res = await showAddItemDialog({
            title: "Add Extra Recipe on User Site",
            itemList: dataExtraRecipeList
        });
        if (res === null || res.length == 0) return;
        setDataExtraRecipeList(res)
    }

    const onAddExtraGroup = async () => {
        var res = await showAddExtraGroupDialog({
            title: "Create Extra Group",
            isEdit: false,
        });
        if (res === null || res.length == 0) return;
        setDataExtraList([...dataExtraList, res])
    }

    const onClickDelete = (pInfo) => {
        let updateData = [...dataList]
        var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
        updateData.splice(storeIndex, 1);
        var tmpData = [...updateData];
        setDataList(tmpData);
    }

    const onClickDeleteExtraRecipe = (pInfo) => {
        let updateData = [...dataExtraRecipeList]
        var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
        updateData.splice(storeIndex, 1);
        var tmpData = [...updateData];
        setDataExtraRecipeList(tmpData);
    }

    const onClickExtraDelete = (pInfo) => {
        let updateData = [...dataExtraList]
        var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
        updateData.splice(storeIndex, 1);
        var tmpData = [...updateData];
        setDataExtraList(tmpData);
    }

    const onClickDetail = (pInfo) => {

    }
    const onClickEdit = async (pInfo) => {

    }
    const onClickExtraEdit = async (pInfo) => {
        var res = await showAddExtraGroupDialog({
            title: "Edit Extra Group",
            isEdit: true,
            selInfo: pInfo
        });
        if (res === null || res.length == 0) return;
        let updateData = [...dataExtraList]
        var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
        updateData.splice(storeIndex, 1, res);
        var tmpData = [...updateData];
        setDataExtraList(tmpData);
    }

    const onSortAction = async (newState) => {
        setSortList([...newState])
    }

    
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <a><u onClick={() => { history.goBack() }}>Go back</u></a>


                <div style={{ marginTop: 10 }}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h1>
                                {edit_id ? "Edit Menu Item" : "Create Mune Item"}
                            </h1>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row gutter={16} justify="end">
                                <CommonButton
                                    onClick={() => {
                                        edit_id ? onUpdate() : onSave()
                                    }}>
                                    Save
                                </CommonButton>
                            </Row>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <ProductImageWidget
                                id={"thumbnail_image"}
                                field={"image_name"}
                                productInfo={selItem}
                                setProductInfo={setSelItem}
                                errorField={errorField}
                                setErrorField={setErrorField}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <div>Item Name* </div>
                            <ProductDescriptionInput
                                placeholder="Enter Name"
                                field={"name"}
                                productInfo={selItem}
                                setProductInfo={setSelItem}
                                errorField={errorField}
                                setErrorField={setErrorField}
                            />
                            <Switch
                                checkedChildren="Show"
                                unCheckedChildren="Hide"
                                onChange={onViewModeStatus}
                                checked={selItem["status"] === "1" ? true : false}
                            />

                            <div style={{ paddingTop: 10 }}>Display Notes</div>
                            <Switch

                                checkedChildren="Show"
                                unCheckedChildren="Hide"
                                onChange={onViewMode}
                                checked={selItem["show_note"] === "1" ? true : false}
                            />
                        </Col>
                    </Row>
                    <div style={{ paddingBottom: 25, paddingTop: 25 }}>
                        <CommonButton
                            onClick={() => {
                                onAddItem();
                            }}>
                            Add Recipe Items
                        </CommonButton>
                    </div>


                    <TableWidget
                        dataList={dataList}
                        onDetailAction={onClickDetail}
                        onUpdateAction={onClickEdit}
                        onDeleteAction={onClickDelete}
                        onSortAction={onSortAction}
                    />
                    <div style={{ paddingBottom: 25, paddingTop: 25 }}>
                        <CommonButton
                            onClick={() => {
                                onAddExtraRecipe();
                            }}>
                            Add Extra Recipe
                        </CommonButton>
                    </div>


                    <TableWidgetRecipeExtra
                        dataList={dataExtraRecipeList}
                        onDeleteAction={onClickDeleteExtraRecipe}
                    />

                    <div style={{ paddingBottom: 25, paddingTop: 0 }}>
                        <CommonButton
                            onClick={() => {
                                onAddExtraGroup();
                            }}>
                            Add Extra Groups
                        </CommonButton>
                    </div>


                    <ExtraGroupTableWidget
                        dataList={dataExtraList}
                        setDataList={setDataExtraList}
                        onDetailAction={onClickDetail}
                        onUpdateAction={onClickExtraEdit}
                        onDeleteAction={onClickExtraDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    )
}

export default AddItemPage
