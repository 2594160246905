import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin } from "antd";

import "./AddRoomDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddRoomDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({
        "status": "0"
      });
    }
  }, [record]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    errorList = isEmpty(data, "width", errorList);
    errorList = isEmpty(data, "height", errorList);
    errorList = isEmpty(data, "status", errorList);
    if (data["status"] == "1") {
      errorList = isEmpty(data, "reservation_name", errorList);
      errorList = isEmpty(data, "reservation_start_timestamp", errorList);
      errorList = isEmpty(data, "reservation_end_timestamp", errorList);
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  function onChangeDatePicker(date, dateString) {
    var tmpData = data
    tmpData["reservation_start_timestamp"] = dateString[0]
    tmpData["reservation_end_timestamp"] = dateString[1]
    setData({ ...tmpData })
  }

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-team-member-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Room Image*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Room Name*</div>
              <ProductDescriptionInput
                placeholder="Room Name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div>Width(m)*</div>
              <ProductInputNumber
                field={"width"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div>Height(m)*</div>
              <ProductInputNumber
                field={"height"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation Status*</div>
              <Select defaultValue="0" style={{
                border: errorField !== undefined && errorField.includes("status")
                  ? "1px solid red"
                  : "1px solid #d9d9d9",
                width: "100%"
              }}
                value={data["status"]}
                onChange={(value) => {
                  data["status"] = value
                  setData({ ...data })
                }}
              >
                <Option value="1">Yes</Option>
                <Option value="0">No</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation Name*</div>
              <ProductDescriptionInput
                disabled={data["status"] == "1" ? false : true}
                placeholder="Reservation Name"
                field={"reservation_name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Reservation Time*</div>
              <RangePicker
                disabled={data["status"] == "1" ? false : true}
                style={{
                  border: errorField !== undefined && (errorField.includes("reservation_start_timestamp") || errorField.includes("reservation_end_timestamp"))
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  width: "100%"
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                format="YYYY-MM-DD HH:mm:ss"
                size={"large"}
                ranges={{
                  "Today": [moment(), moment()],
                  "This Week": [moment().startOf("week"), moment().endOf("week")],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "This Quarter": [
                    moment().startOf("quarter"),
                    moment().endOf("quarter"),
                  ],
                  "This Year": [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                }}
                value={[moment(data["reservation_start_timestamp"]), moment(data["reservation_end_timestamp"])]}
                onChange={onChangeDatePicker} />

            </Col>

          </Row>


        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddRoomDialog)
