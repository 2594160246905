import React ,{ useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Input, message, Spin } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import AvatarWidget from "../AvatarWidget/AvatarWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { getCurrencyInfo, isEmpty } from "utils/GlobalFunctions";
import { apiGetProfileInfo, apiUpdateProfile } from "services/profileService";
import { updateUser } from "redux/actions/userActions";
import 'react-phone-number-input/style.css'
import "./UserProfilePage.css"


const UserProfilePage = () => {
    const dispatch = useDispatch();
    const userDataStore = useSelector(x => x.userDataStore);
    const userInfo = userDataStore.user;
    const [userData, setUserData] = useState("");
    const [ newImageUrl, setNewImageUrl ] = useState("")
    const [ errorField, setErrorField ] = useState([])
    const [ isBusy, setIsBusy ] = useState(false)

    // useEffect(() => {
    //     if(userInfo !== undefined){
    //         setUserData({...userInfo, countryCode: userInfo.countryCode, phoneNumber: userInfo.phoneNumber, phone: "+"+userInfo?.countryCode + userInfo?.phoneNumber});
    //     }
    // }, [userInfo]);

    useEffect(() => {
        setIsBusy(true);
        apiGetProfileInfo()
            .then((data) => {
                setIsBusy(false);
                setUserData(data)
                dispatch(updateUser({user:data}));
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
        });
    }, []);

    const validateFields = () => {
        var errorList = Array();
        errorList = isEmpty(userData, "first_name", errorList);
        errorList = isEmpty(userData, "last_name", errorList);
        if(userData.new_password !== undefined && userData.new_password !== ""){
            errorList = isEmpty(userData, "old_password", errorList);
            errorList = isEmpty(userData, "new_password", errorList);
            errorList = isEmpty(userData, "confirm_password", errorList);
            if (userData["new_password"] !== userData["confirm_password"]) {
                errorList = [...errorList, "new_password"];
                errorList = [...errorList, "confirm_password"];
                message.error("New password didn't match. Please correct it")
            }
        }
        setErrorField([...errorList]);
        return errorList.length == 0;
    };
    const onClickUpdate = () => {
        if(validateFields()){
            setIsBusy(true)
            apiUpdateProfile(userData, newImageUrl)
                .then((data) => {
                setIsBusy(false);
                message.success("User Profile Info was changed successfully.")
                dispatch(updateUser({user:data}));
                setUserData({...userData, old_password: "", new_password: "",  confirm_password: ""});
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
        }
    }
    return (
        <CommonDivWidget style={{ padding: 0}}>
            <Spin spinning={isBusy}>
                <div className="profile-top-background"></div>
                <AvatarWidget defaultImageUrl={userData.image_url} setNewImageUrl={setNewImageUrl}></AvatarWidget>
                <div className="profile-avatar-txt">
                    <div>{userData.email}</div>
                    <div>Last Update : &nbsp;
                        {userData.update_timestamp != null && userData.update_timestamp != "" && new Intl.DateTimeFormat("en-US", {
                            dateStyle: "medium",
                        }).format(new Date(userData.update_timestamp.replace(/ /g,"T")))}
                    </div>
                </div>
                <div style={{padding:20}}>
                    <Row gutter={32} justify={"center"}>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                            <div style={{ paddingBottom: "5px" }}>First Name*</div>
                            <Input
                            value={userData.first_name}
                            className={errorField !== undefined && errorField.includes("first_name")?`common-input-error`:`common-input`}
                            onChange={(e) => {
                                if (errorField.includes("first_name")) {
                                    let errors = errorField.filter((x) => x != "first_name");
                                    setErrorField([...errors]);
                                }
                                setUserData({...userData, first_name: e.target.value});
                            }}/>
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                            <Input
                            value={userData.last_name}
                            className={errorField !== undefined && errorField.includes("last_name")?`common-input-error`:`common-input`}
                            onChange={(e) => {
                                if (errorField.includes("last_name")) {
                                    let errors = errorField.filter((x) => x != "last_name");
                                    setErrorField([...errors]);
                                }
                                setUserData({...userData, last_name: e.target.value});
                            }}/>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                    </Row>
                    <Row gutter={32} justify={"center"} style={{marginTop:20}}>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                            <div style={{ paddingBottom: "5px" }}>Email*</div>
                            <Input
                                type={"email"}
                                value={userData.email}
                                disabled={userData.register_with=="Email"?true:false}
                                className={errorField !== undefined && errorField.includes("email")?`common-input-error`:`common-input`}
                                onChange={(e) =>{
                                    if (errorField.includes("email")) {
                                        let errors = errorField.filter((x) => x != "email");
                                        setErrorField([...errors]);
                                    }
                                    setUserData({...userData, email: e.target.value});
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                            <div style={{ paddingBottom: "5px" }}>Old Password*</div>
                            <Input
                            type={"password"}
                            value={userData.old_password}
                            className={errorField !== undefined && errorField.includes("old_password")?`common-input-error`:`common-input`}
                            onChange={(e) => {
                                if (errorField.includes("old_password")) {
                                    let errors = errorField.filter((x) => x != "old_password");
                                    setErrorField([...errors]);
                                }
                                setUserData({...userData, old_password: e.target.value});
                            }}/>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                    </Row>
                    <Row gutter={32} justify={"center"} style={{marginTop:20}}>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                            <div style={{ paddingBottom: "5px" }}>New Password*</div>
                            <Input
                            type={"password"}
                            value={userData.new_password}
                            className={errorField !== undefined && errorField.includes("new_password")?`common-input-error`:`common-input`}
                            onChange={(e) => {
                                if (errorField.includes("new_password")) {
                                    let errors = errorField.filter((x) => x != "new_password");
                                    setErrorField([...errors]);
                                }
                                setUserData({...userData, new_password: e.target.value});
                            }}/>
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <div style={{ paddingBottom: "5px" }}>Confirm Password*</div>
                            <Input
                            type={"password"}
                            value={userData.confirm_password}
                            className={errorField !== undefined && errorField.includes("confirm_password")?`common-input-error`:`common-input`}
                            onChange={(e) => {
                                if (errorField.includes("confirm_password")) {
                                    let errors = errorField.filter((x) => x != "confirm_password");
                                    setErrorField([...errors]);
                                }
                                setUserData({...userData, confirm_password: e.target.value});
                            }}/>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}/>
                    </Row>
                    <Row style={{marginTop:50, marginBottom: 50}} justify={"center"}>
                        <CommonButton style={{ padding: "7px 28px"}} onClick={()=>{onClickUpdate()}}>Update</CommonButton>
                    </Row>
                    
                </div>
            </Spin>
        </CommonDivWidget>
    );
};

export default UserProfilePage;