import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { InputNumber, Table, } from "antd";
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';

const TableWidget = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, ownCommonData, setErrorField, handleChangeQtyforEachItem } = props;
  
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "No",
      dataIndex: "virtual_id",
      align: 'center',
    },
    {
      title: "Yield Name",
      dataIndex: "name",
      align: 'center',
      render:(text, record) => {
        if (record.yield_name_id === undefined || record.yield_name_id === null) {
          return <span className="ingredient-rule-inventory-name-item">  {text} </span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item"> {text} </span>
        }
      }
    },
    {
      title: "Yield Qty",
      dataIndex: "qty",
      align: 'center',
      render: text => <span> {parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit_id",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_id - b.unit_id,
      },
      render:(text, record) => {
        return <span>{ownCommonData.unitList.filter(e => e.id === record.unit_id)[0].name}</span>
      }
    },
    {
      title: "Cost/Unit",
      dataIndex: "average_price_per_unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.average_price_per_unit - b.average_price_per_unit
      },
      render: text => <span>$ {parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "Created At",
      dataIndex: "add_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated At",
      dataIndex: "update_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Enter QTY",
      key: "enter_qty",
      render: (text, record) => {

        return <InputNumber
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              background: `var(--backgroundColor)`,
              padding: "4px 12px",
              width: "120px"
            }}
            value={
              record === undefined || record["enter_qty"] == undefined ? "0" : record["enter_qty"]
            }
            defaultValue={0}
            min={"0"}
            step={ "1" }
            onChange={(value) => {
              record["enter_qty"] = value
              handleChangeQtyforEachItem(record)
            }}/>
      },
    },
    {
      title: "Select Unit for QTY",
      key: "enter_unit_id",
      width : 200,
      render: (text, record) => {
        return <ProductTypeSelector
        placeholder={"Select Unit"}
        field={"enter_unit_id"}
        list={ownCommonData.unitList}
        displayField="name"
        idField="id"
        productInfo={record}
        onChangeHandle={(value) => {
          record["enter_unit_id"] = value
          handleChangeQtyforEachItem(record)
        }}

      />
      }
    },

   
    
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.virtual_id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default TableWidget
