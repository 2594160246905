import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlAddUserSiteItemMainSub, urlDeleteUserSiteItemMainSub, urlGetUserSiteItemMainSubByID, urlGetUserSiteItemMainSubList, urlGetUserSiteItemRecipeList, urlUpdateUserSiteItemMainSub, urlUpdateUserSiteItemMainSubPosition } from "./CONSTANTS";

export function apiAddUserSiteItemMainSub(main_category_id, sub_category_id, data, itemList, extraOptionList, recipe_extra) {
  const url = urlAddUserSiteItemMainSub;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  formData.append("sub_category_id", sub_category_id);
  formData.append("name", data["name"]);
  formData.append("show_note", data["show_note"]);
  formData.append("status", data["status"]);

  var idList = []
  for (let i = 0; i<itemList.length; i++) {
    idList.push({id : itemList[i]['id']})
  }

  formData.append("idList", JSON.stringify(idList));
  formData.append("extraOptionList", JSON.stringify(extraOptionList));
  formData.append("recipe_extra", JSON.stringify(recipe_extra));

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetUserSiteItemMainSubList = (main_category_id, sub_category_id) => {
  const url = urlGetUserSiteItemMainSubList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("main_category_id", main_category_id);
  formData.append("sub_category_id", sub_category_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const apiGetUserSiteItemMainSubByID = (id) => {
  const url = urlGetUserSiteItemMainSubByID;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export function apiUpdateUserSiteItemMainSubPosition(dataList) {
  const url = urlUpdateUserSiteItemMainSubPosition;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var idList = []
  for (let i = 0; i<dataList.length; i++) {
    idList.push({id : dataList[i]['id']})
  }
  var formData = new FormData();
  formData.append("dataList", JSON.stringify(idList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiDeleteUserSiteItemMainSub(id) {
  const url = urlDeleteUserSiteItemMainSub;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetUserSiteItemRecipeList = () => {
  const url = urlGetUserSiteItemRecipeList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiUpdateUserSiteItemMainSub(id, data, itemList, extraOptionList, recipe_extra) {
  const url = urlUpdateUserSiteItemMainSub;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  formData.append("name", data["name"]);
  formData.append("show_note", data["show_note"]);
  formData.append("status", data["status"]);

  var idList = []
  for (let i = 0; i<itemList.length; i++) {
    idList.push({id : itemList[i]['id']})
  }

  formData.append("idList", JSON.stringify(idList));
  formData.append("extraOptionList", JSON.stringify(extraOptionList));
  formData.append("recipe_extra", JSON.stringify(recipe_extra));

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}