import React from "react";
import { Row, Spin, Col, DatePicker, Space, message } from 'antd';
import "./EntListPage.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CsvDownloader from 'react-csv-downloader';
import ReactExport from "react-export-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { apiDeleteEnt, apiGetEntList } from "services/entService";
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function EntListPage() {
    const [searchList, setSearchList] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [entList, setEntList] = useState([]);

    useEffect(() => {
        apiGetEntList()
            .then((data) => {
                setSearchList(data)
                setEntList(data)
            })
            .catch((err) => {
                // message.error("Sorry! " + err);
            });
    }, []);

    const handleSearch = (searchText) => {
        const tmpList = entList.filter((info) => info.author_name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(entList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...entList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");            
            const tmpList = entList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g,"T")) && new Date(info.update_timestamp.replace(/ /g,"T")) <= end_date)
            setSearchList([...tmpList])
        }
        
    }
    const onDeleteAction = (pInfo) => {
        setIsBusy(true);
        apiDeleteEnt(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...entList]
                var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
                updateData.splice(storeIndex, 1);
                var tmpData = [...updateData];
                setEntList(tmpData);
                setSearchList(tmpData);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const columns = [{
        id: 'id',
        displayName: 'Id'
      }, {
        id: 'author_name',
        displayName: 'Author Name'
      }, {
        id: 'totalBudget',
        displayName: 'Budget(USD)'
      }, {
        id: 'add_timestamp',
        displayName: 'Created At'
      }, {
        id: 'update_timestamp',
        displayName: 'Updated At'
      }
    ];
    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Ent PDF";
        const headers = [["Id", "Author Name", 'Budget(USD)', 'Created At', 'Updated At']];
        const data = searchList.map(elt=> [elt.id, elt.author_name, elt.totalBudget, elt.add_timestamp, elt.update_timestamp]);

    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("ent_info.pdf")
      }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div>
                    <Row justify={"space-between"}>
                        <Col>
                            <span style={{ fontFamily: 'mediumFont', paddingRight: 20 }}>Date Filter Period: </span>
                            <RangePicker size={'default'}
                                onChange={(e, date) => {
                                    if (date == null) {
                                        handleDateFilter("", "")
                                    } else {
                                        handleDateFilter(date[0], date[1])
                                    }

                                }} />
                        </Col>
                        <Col>
                            {
                                <div>
                                    <CsvDownloader
                                        filename="ent_info"
                                        extension=".csv"
                                        className="common-button"
                                        style={{border: 'none', margin: 10}}
                                        columns={columns}
                                        datas={searchList}
                                        text="Export CSV" />
                                    <ExcelFile filename = {"ent_info"} element={<button className="common-button"
                                        style={{border: 'none', margin: 10}} >Export XLXS</button>}>
                                        <ExcelSheet data={searchList} name="ent">
                                            <ExcelColumn label="Id" value="id" widthCh={300}/>
                                            <ExcelColumn label="Author Name" value="author_name"/>
                                            <ExcelColumn label="Budget(USD)" value="totalBudget"/>
                                            <ExcelColumn label="Created At" value="add_timestamp"/>
                                            <ExcelColumn label="Updated At" value="update_timestamp"/>
                                        </ExcelSheet>
                                    </ExcelFile>
                                    <CommonButton onClick={() => {
                                              exportPDF()
                                    }} style={{ margin: 10 }}>Export PDF</CommonButton>
                                </div>
                            }
                        </Col>

                    </Row>
                    <div style={{ marginTop: 10 }}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <TableWidget
                            dataList={searchList}
                            setDataList={setSearchList}
                            onDeleteAction={onDeleteAction}
                        />
                    </div>
                </div>
            </Spin>
        </CommonDivWidget>

    );
}

export default EntListPage;
