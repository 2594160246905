import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { getCurrencyInfo, isEmpty } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList} = props;


    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Sales Main",
            dataIndex: "order_price",
            render: text => <div>{ "$(" + getCurrencyInfo() + ") " } { text === undefined ? 0 : text}</div>,
        },
        {
            title: "Main Cost",
            dataIndex: "order_cost",
            render: text => <div>{ "$(" + getCurrencyInfo() + ") " } { text === undefined ? 0 : text}</div>,
        },
        {
            title: "Ent Cost",
            dataIndex: "ent_price",
            render: text => <div>{ "$(" + getCurrencyInfo() + ") " } { text === undefined ? 0 : text}</div>,
        },
        {
            title: "Spoilage Cost",
            dataIndex: "spoilage_price",
            render: text => <div>{ "$(" + getCurrencyInfo() + ") " } { text === undefined ? 0 : text}</div>,
        },
        {
            title: "COGS(Cost of Goods Sold)",
            dataIndex: "cogs",
            render: text => <div>{ "$(" + getCurrencyInfo() + ") " } { text === undefined ? 0 : text}</div>,
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
