import React from "react";
import { Row, Spin, Col, DatePicker, Space, message, Radio } from 'antd';
import "./GenerateReport.css";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import moment from "moment"
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiGenerateReport } from "services/reportService";
import TableWidget from "./TableWidget/TableWidget";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function GenerateReport() {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({})
    const [dataList, setDataList] = useState([]);

    useEffect(() => {

    }, []);

    const onChangeRadio = e => {
        var tmpData = data
        tmpData["selected_date_period"] = e.target.value
        tmpData["start_date"] = ""
        tmpData["end_date"] = ""
        setData({ ...tmpData })
    }

    function onChangeDatePicker(date, dateString) {
        if (data["selected_date_period"] === "range_date") {
            var tmpData = data
            tmpData["start_date"] = dateString[0]
            tmpData["end_date"] = dateString[1]
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "date") {
            var tmpData = data
            tmpData["start_date"] = dateString
            tmpData["end_date"] = dateString
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "week") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('week').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('week').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "month") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('month').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('month').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "quarter") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('quarter').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('quarter').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "year") {
            var tmpData = data
            tmpData["start_date"] = moment(dateString).startOf('year').format(dateFormat)
            tmpData["end_date"] = moment(dateString).endOf('year').format(dateFormat)
            setData({ ...tmpData })
        }
        else {
            var tmpData = data
            tmpData["start_date"] = ""
            tmpData["end_date"] = ""
            setData({ ...tmpData })
        }
    }

    const onGenerateReport = () => {
        if (!data["start_date"] || !data["end_date"]) {
            message.info("Please select date")
            return
        }
        setIsBusy(true);
        apiGenerateReport(data)
            .then((data) => {
                setIsBusy(false);
                prepareData(data)
            })
            .catch((err) => {
                setIsBusy(false);
                prepareData([])
                message.error(err);
            });
    }

    const prepareData = (res) => {
        let salableTypeList = res["salableTypeList"]
        if (salableTypeList === undefined) return
        let tmpList = []
        let totalSalesMain = 0
        let totalCogs = 0
        for (let i = 0; i < salableTypeList.length; i++) {
            let tmp = { id: salableTypeList[i]["id"], name: salableTypeList[i]["name"] }
            if (res["order"][salableTypeList[i]["id"]]) {
                tmp["order_price"] = res["order"][salableTypeList[i]["id"]]["price"]
                tmp["order_cost"] = res["order"][salableTypeList[i]["id"]]["cost"]
            } else {
                tmp["order_price"] = 0
                tmp["order_cost"] = 0
            }

            if (res["spoilage"][salableTypeList[i]["id"]]) {
                tmp["spoilage_price"] = res["spoilage"][salableTypeList[i]["id"]]["price"]
            } else {
                tmp["spoilage_price"] = 0
            }

            if (res["ent"][salableTypeList[i]["id"]]) {
                tmp["ent_price"] = res["ent"][salableTypeList[i]["id"]]["price"]
            } else {
                tmp["ent_price"] = 0
            }
            totalSalesMain = totalSalesMain + tmp["order_price"]
            tmp["cogs"] = tmp["order_cost"] + tmp["spoilage_price"] + tmp["ent_price"]
            totalCogs = totalCogs + tmp["cogs"]
            tmpList.push(tmp)
        }
        //console.log("tmpList: ", tmpList)
        setDataList(tmpList)

        let tmpData = data
        let settingList = res["settingList"]
        if (settingList !== undefined) {
            for (let i = 0; i < settingList.length; i++) {
                tmpData[settingList[i]["option_name"]] = settingList[i]["option_value"]
            }
        }
        tmpData["teamCost"] = res["teamCost"]
        tmpData["totalSalesMain"] = totalSalesMain
        tmpData["totalCogs"] = totalCogs
        tmpData["numberOfDays"] = res["numberOfDays"]
        tmpData["expense_sum"] = res["expense_sum"]
        tmpData["investment_sum"] = res["investment_sum"]

        //console.log("tmpData:", tmpData)
        setData({ ...tmpData })
    }

    const getTotalSales = () => {
        let res = data["totalSalesMain"]
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }
    const getTotalSalesPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return 100;
    }
    const getRent = () => {
        /*let res = data["rent"]
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res*/
        let rent = data["rent"]
        let revenue = data["totalSalesMain"]
        if (!rent || !revenue) {
            return 0
        }
        return (revenue / 100 * rent).toFixed(2)
    }
    const getRentPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getRent() / getTotalSales()) * 100).toFixed(2);
    }

    const getTeamCost = () => {
        /*let res = data["teamCost"]
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res*/
        let teamCost = data["teamCost"]
        // let numberOfDays = data["numberOfDays"]
        if (!teamCost) {
            return 0
        }
        return parseFloat(teamCost).toFixed(2);
    }
    const getTeamCostPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getTeamCost() / getTotalSales()) * 100).toFixed(2);
    }

    const getInvestment = () => {
        let investment_sum = data["investment_sum"]
        if (investment_sum === undefined || isNaN(investment_sum) || investment_sum === null) {
            return 0
        }
        return parseFloat(investment_sum).toFixed(2)
    }
    const getInvestmentPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getInvestment() / getTotalSales()) * 100).toFixed(2);
    }
    const getExpensePowerGasOil = () => {
        /*let res = data["expense_power_gas_oil"]
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res*/
        let expense = data["expense_sum"]
        if (expense === undefined || isNaN(expense) || expense === null) {
            return 0
        }
        return parseFloat(expense).toFixed(2)
    }
    const getExpensePowerGasOilPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getExpensePowerGasOil() / getTotalSales()) * 100).toFixed(2);
    }
    const getTaxVat = () => {
        let res = getTotalSales() * data["tax_vat"] / 100
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }
    const getTaxVatPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getTaxVat() / getTotalSales()) * 100).toFixed(2);
    }
    const getGrossFit = () => {
        let res = (getTotalSales() - data["totalCogs"] - getRent() - getTeamCost() - getExpensePowerGasOil() - getInvestment() - getTaxVat()).toFixed(5)
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }
    const getGrossFitPercentage = () => {
        if (getTotalSales() === 0) {
            return 0;
        }
        return ((getGrossFit() / getTotalSales()) * 100).toFixed(2);
    }
    const exportPDF = () => {
        if (dataList.length == 0 || data == undefined) {
            message.info("Firstly, generate report. Then, try again")
            return
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Report Data from " + data["start_date"] + " to " + data["end_date"];
        const headers = [["Id", "Name", 'Sales Main', 'Main Cost', 'Ent Cost', 'Spoilage Cost', 'COGS(Cost of Goods Sold)']];
        const pdf_data = dataList.map(elt => [elt.id, elt.name, '$' + elt.order_price, '$' + elt.order_cost, '$' + elt.ent_price, '$' + elt.spoilage_price, '$' + elt.cogs]);


        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text(title, marginLeft, 60);


        let content = {
            startY: 80,
            head: headers,
            body: pdf_data
        };

        doc.autoTable(content);

        doc.setFontSize(10)
        let finalY = doc.lastAutoTable.finalY + 20; // The y position on the page
        doc.text(marginLeft, finalY, "Total Sales : $" + getTotalSales())
        doc.text(marginLeft, finalY + 15, "Rent : $" + getRent())
        doc.text(marginLeft, finalY + 30, "Team Cost : $" + getTeamCost())
        doc.text(marginLeft, finalY + 45, "Expenses : $" + getExpensePowerGasOil())
        doc.text(marginLeft, finalY + 60, "Investment : $" + getInvestment())
        doc.text(marginLeft, finalY + 75, "Tax VAT : $" + getTaxVat())
        doc.text(marginLeft, finalY + 90, "Gross Profit : $" + getGrossFit())

        doc.setFontSize(12)

        doc.text(marginLeft, finalY + 100, "Generation Date : " + moment().format(dateFormat))

        doc.save("report.pdf")
    }
    const exportExcel = () => {
        const datatable = dataList.map(elt => [elt.id, elt.name, '$' + elt.order_price, '$' + elt.order_cost, '$' + elt.ent_price, '$' + elt.spoilage_price, '$' + elt.cogs]);
        const start_date = data["start_date"]
        const end_date = data["end_date"]

        let multiDataSet = [
            {
                xSteps: 0, // Will start putting cell with 1 empty cell on left most
                ySteps: 0,
                columns: ["Report Data from " + start_date + " to " + end_date],
                data: []
            },
            {
                xSteps: 0, // Will start putting cell with 1 empty cell on left most
                ySteps: 1,
                columns: ["Id", "Name", 'Sales Main', 'Main Cost', 'Ent Cost', 'Spoilage Cost', 'COGS(Cost of Goods Sold)'],
                data: datatable
            },
            {
                xSteps: 0, // Will start putting cell with 1 empty cell on left most
                ySteps: 1,
                columns: ["Total Sales", "Rent", "Team Cost", "Expenses", "Investment", "Tax VAT", "Gross Profit"],
                data: [["$" + getTotalSales(), "$" + getRent(), "$" + getTeamCost(), "$" + getExpensePowerGasOil(), "$" + getInvestment(), "$" + getTaxVat(), "$" + getGrossFit()]]
            },
            {
                xSteps: 0, // Will start putting cell with 1 empty cell on left most
                ySteps: 2,
                columns: ["Generation Date"],
                data: [[moment().format(dateFormat)]]
            },
        ];
        return multiDataSet
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row justify="space-between">
                    <Col>
                        <div className="text-header">Report for daily Monthly Yearly</div>
                    </Col>
                    <Col >
                        <ExcelFile filename={"report"} element={<CommonButton style={{ margin: 10 }}>Export XLXS</CommonButton>}>
                            <ExcelSheet dataSet={exportExcel()} name={moment().format(dateFormat)}></ExcelSheet>
                        </ExcelFile>
                        <CommonButton onClick={() => {
                            exportPDF()
                        }} style={{ margin: 10 }}>Export PDF</CommonButton>
                        <CommonButton onClick={() => {
                            onGenerateReport()
                        }}>Generate</CommonButton>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Radio.Group onChange={onChangeRadio} value={data["selected_date_period"]}>
                        <Space>
                            <Radio value={"date"}>
                                <Col> Select date </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "date" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"week"}>
                                <Col> Select week </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="week" disabled={data["selected_date_period"] === "week" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"month"}>
                                <Col> Select month </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="month" disabled={data["selected_date_period"] === "month" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"quarter"}>
                                <Col> Select quarter </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="quarter" disabled={data["selected_date_period"] === "quarter" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"year"}>
                                <Col> Select year </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="year" disabled={data["selected_date_period"] === "year" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"range_date"}>
                                <Col> Select range date </Col>
                                <span> <RangePicker ranges={{
                                    "Today": [moment(), moment()],
                                    "This Week": [moment().startOf("week"), moment().endOf("week")],
                                    "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                    ],
                                    "This Quarter": [
                                        moment().startOf("quarter"),
                                        moment().endOf("quarter"),
                                    ],
                                    "This Year": [
                                        moment().startOf("year"),
                                        moment().endOf("year"),
                                    ],
                                }} onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "range_date" ? false : true} /> </span>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Row>
                <div style={{ marginTop: 20 }}>
                    <TableWidget
                        dataList={dataList}
                    />
                </div>
                <div>
                    <Row justify="start">
                        <Col flex={"auto"}>
                            <div>Start Date : {data["start_date"]}</div>
                        </Col>
                        <Col flex={"auto"}>
                            <div>End Date : {data["end_date"]}</div>
                        </Col>

                    </Row>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Row justify="space-around">
                        <Col>
                            <div>Total Sales : {getTotalSalesPercentage()}%</div>
                            <div>${getTotalSales()}</div>
                        </Col>
                        <Col>
                            <div>Rent : {getRentPercentage()}%</div>
                            <div>${getRent()}</div>
                        </Col>
                        <Col>
                            <div>Team Cost : {getTeamCostPercentage()}%</div>
                            <div>${getTeamCost()}</div>
                        </Col>
                        <Col>
                            <div>Expenses : {getExpensePowerGasOilPercentage()}%</div>
                            <div>${getExpensePowerGasOil()}</div>
                        </Col>
                        <Col>
                            <div>Investment : {getInvestmentPercentage()}%</div>
                            <div>${getInvestment()}</div>
                        </Col>
                        <Col>
                            <div>Tax VAT : {getTaxVatPercentage()}%</div>
                            <div>${getTaxVat()}</div>
                        </Col>
                        <Col>
                            <div>Gross Profit : {getGrossFitPercentage()}%</div>
                            <div>${getGrossFit()}</div>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </CommonDivWidget>

    );
}

export default GenerateReport;
