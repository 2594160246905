import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateRecipe, urlGetRecipeList, urlDeleteRecipe, urlGetRecipeById, urlUpdateRecipe, urlRecipeBasicData, urlGetRecipeCategory, urlCreateRecipeCategory, urlUpdateRecipeCategory, urlDeleteRecipeCategory, urlGetRecipeLocation, urlCreateRecipeLocation, urlUpdateRecipeLocation, urlDeleteRecipeLocation} from "./CONSTANTS";

export function apiCreateRecipe(recipeData) {
  const url = urlCreateRecipe;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  console.log("recipeData", recipeData)
  var formData = new FormData();
  formData.append("name", recipeData.name);
  formData.append("description", recipeData.description);
  formData.append("instruction", recipeData.instruction);
  formData.append("category_id", recipeData.category_id);
  formData.append("location_id", recipeData.location_id);
  formData.append("unit_id",     recipeData.unit_id);
  formData.append("salable_type_id", recipeData.salable_type_id);
  formData.append("qty",         recipeData.qty);
  formData.append("active", recipeData.active);
  formData.append("salable", recipeData.salable);
  formData.append("sub_recipe", recipeData.sub_recipe);
  formData.append("author", recipeData.author);
  formData.append("sell_price_percentage", recipeData.sell_price_percentage);
  formData.append("fixed_sell_price", recipeData.fixed_sell_price === undefined ? 0:recipeData.fixed_sell_price);
  formData.append("is_checked_fixed_sell_price", recipeData.is_checked_fixed_sell_price === true ? "1":"0");
  formData.append("guideList", JSON.stringify(recipeData.guideList));
  if (recipeData.image_name instanceof File) {
    formData.append("image_name", recipeData.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateRecipe(recipeData) {
  const url = urlUpdateRecipe;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  console.log("recipeData", recipeData)

  var formData = new FormData();
  formData.append("id", recipeData.id);
  formData.append("name", recipeData.name);
  formData.append("description", recipeData.description);
  formData.append("instruction", recipeData.instruction);
  formData.append("category_id", recipeData.category_id);
  formData.append("location_id", recipeData.location_id);
  formData.append("unit_id",     recipeData.unit_id);
  formData.append("salable_type_id", recipeData.salable_type_id);
  formData.append("qty",         recipeData.qty);
  formData.append("active", recipeData.active);
  formData.append("salable", recipeData.salable);
  formData.append("sub_recipe", recipeData.sub_recipe);
  formData.append("author", recipeData.author);
  formData.append("sell_price_percentage", recipeData.sell_price_percentage);
  formData.append("fixed_sell_price", recipeData.fixed_sell_price === undefined ? 0:recipeData.fixed_sell_price);
  formData.append("is_checked_fixed_sell_price", recipeData.is_checked_fixed_sell_price === true ? "1":"0");
  formData.append("guideList", JSON.stringify(recipeData.guideList));
  if (recipeData.image_name instanceof File) {
    formData.append("image_name", recipeData.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export const apiGetRecipeList = () => {
  const url = urlGetRecipeList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiDeleteRecipe(id) {
  const url = urlDeleteRecipe;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiGetRecipeById(id) {
  const url = urlGetRecipeById;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetRecipeBasicData = (id) => {
  const url = urlRecipeBasicData;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  if (id !== undefined) {
    formData.append("id", id);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};




export const apiGetRecipeCategory = () => {
  const url = urlGetRecipeCategory;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiCreateRecipeCategory(data) {
  const url = urlCreateRecipeCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateRecipeCategory(data) {
  const url = urlUpdateRecipeCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteRecipeCategory(id) {
  const url = urlDeleteRecipeCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}



export const apiGetRecipeLocation = () => {
  const url = urlGetRecipeLocation;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiCreateRecipeLocation(data) {
  const url = urlCreateRecipeLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateRecipeLocation(data) {
  const url = urlUpdateRecipeLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteRecipeLocation(id) {
  const url = urlDeleteRecipeLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}