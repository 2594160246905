import React from "react";
import { Row, Spin, Col, DatePicker, Space, message, Radio } from 'antd';
import "./InventoryReport.css";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import moment from "moment"
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiInventoryReport } from "services/reportService";
import TableWidget from "./TableWidget/TableWidget";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";
import { empty, getCurrencyInfo, isEmpty } from "utils/GlobalFunctions";
// import { read, writeFileXLSX } from "xlsx";
// import { set_cptable } from "xlsx";
// import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
// set_cptable(cptable);
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function InventoryReport() {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({})
    const [dataList, setDataList] = useState([]);

    useEffect(() => {

    }, []);

    const onChangeRadio = e => {
        var tmpData = data
        tmpData["selected_date_period"] = e.target.value
        tmpData["start_date"] = ""
        tmpData["end_date"] = ""
        setData({ ...tmpData })
    }

    function onChangeDatePicker(date, dateString) {
        setDataList([])
        if (!dateString) {
            message.info("Please select date")
            return
        }
        if (data["selected_date_period"] === "range_date") {
            var tmpData = data
            tmpData["start_date"] = dateString[0]
            tmpData["end_date"] = dateString[1]
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "date") {
            var tmpData = data
            tmpData["start_date"] = dateString
            tmpData["end_date"] = dateString
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "week") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('week').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('week').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "month") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('month').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('month').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "quarter") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('quarter').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('quarter').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "year") {
            var tmpData = data
            tmpData["start_date"] = moment(dateString).startOf('year').format(dateFormat)
            tmpData["end_date"] = moment(dateString).endOf('year').format(dateFormat)
            setData({ ...tmpData })
        }
        else {
            var tmpData = data
            tmpData["start_date"] = ""
            tmpData["end_date"] = ""
            setData({ ...tmpData })
        }

        onGetInventoryReportData()
    }

    const onGetInventoryReportData = () => {
        //console.log(data)
        if (!data["start_date"] || !data["end_date"]) {
            message.info("Please select date")
            return
        }
        setIsBusy(true);
        apiInventoryReport(data)
            .then((data) => {
                setIsBusy(false);
                prepareData(data)
            })
            .catch((err) => {
                setIsBusy(false);
                prepareData([])
                message.error(err);
            });
    }

    const prepareData = (res) => {
        let salableTypeList = res["salableTypeList"];
        if (!salableTypeList) return;

        let resultData = res["result_data"];
        if (empty(resultData)) return;

        let costData = res["cost_data"];
        if (empty(costData)) return;

        let tmpList = []
        for (let i = 0; i < salableTypeList.length; i++) {
            let tmp = { id: salableTypeList[i]["id"], name: salableTypeList[i]["name"] }
            tmp["items"] = resultData[i]
            tmp["cost_data"] = costData[i]
            tmpList.push(tmp)
        }
        setDataList(tmpList)

        let tmpData = data
        let settingList = res["settingList"]
        if (settingList) {
            for (let i = 0; i < settingList.length; i++) {
                tmpData[settingList[i]["option_name"]] = settingList[i]["option_value"]
            }
        }
        tmpData["numberOfDays"] = res["numberOfDays"]
        setData({ ...tmpData })
    }

    const exportPDF = () => {
        if (dataList.length == 0 || !data) {
            message.info("Firstly, Please select date. Then, try again")
            return
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Inventory Report from " + data["start_date"] + " to " + data["end_date"];
        const headers = [["", "ID", "Name", "Total Purchase QTY", "Monetary Value", "QTY used for recipes", "Monetary Value", "Stock Remaining", "Monetary Value"]];

        let fistLabel = "";
        let secondLabel = "";
        let pdf_data = []
        dataList.map((e) => {
            e.items.map((x) => {
                
                let tmpFirstLabel = ""
                if (fistLabel != e.name) {
                    tmpFirstLabel = e.name
                    fistLabel = e.name
                }

                pdf_data.push([tmpFirstLabel, x.id, x.name, x.purchased_qty, x.purchased_cost, x.deducted_qty, x.deducted_cost,x.qty, x.remain_cost])
            })
        })


        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text(title, marginLeft, 60);


        let content = {
            startY: 80,
            head: headers,
            body: pdf_data
        };

        doc.autoTable(content);

        doc.setFontSize(10)
        let finalY = doc.lastAutoTable.finalY + 20; // The y position on the page
        // doc.text(marginLeft, finalY, "Total Sales : $" + getTotalSales())
        // doc.text(marginLeft, finalY + 15, "Rent : $" + getRent())
        // doc.text(marginLeft, finalY + 30, "Team Cost : $" + getTeamCost())
        // doc.text(marginLeft, finalY + 45, "Expenses Power, Gas, Oil : $" + getExpensePowerGasOil())
        // doc.text(marginLeft, finalY + 60, "Tax VAT : $" + getTaxVat())
        // doc.text(marginLeft, finalY + 75, "Gross Profit : $" + getGrossFit())

        doc.setFontSize(12)

        doc.text(marginLeft, finalY + 100, "Generation Date : " + moment().format(dateFormat))

        doc.save("inventory_report.pdf")
    }
    const exportExcel = () => {
        if (dataList.length == 0 || !data) {
            message.info("Firstly, Please select date. Then, try again")
            return
        }
        let wb = XLSX.utils.book_new();
        dataList.map((e) => {
            let tmpData = []
            tmpData.push(["", "", "ID", "Thumbnail", "Name", "Total Purchase QTY", "Unit", "Monetary Value", "QTY used for recipes", "Unit", "Monetary Value", "Stock Remaining", "Unit", "Monetary Value"])
            e.items.map((x) => {
                // tmpData.push([x.title])
                // x.items.map((y) => {
                //     let cost = "$(" + getCurrencyInfo() + ") " + y.fixed_sell_price;
                    
                // })
                tmpData.push(["", "", x.id, x.thumbnail_image, x.name, x.purchased_qty, x.unit_name, x.purchased_cost, x.deducted_qty, x.unit_name, x.deducted_cost,x.qty, x.unit_name, x.remain_cost,])
            })
            //console.log(e);
            tmpData.push([])
            tmpData.push(["", "", "", "", "", "", "", e.cost_data.total_purchased_cost, "", "", e.cost_data.total_deducted_cost, "", "", e.cost_data.total_remain_cost,])
            let ws = XLSX.utils.aoa_to_sheet(tmpData);

            XLSX.utils.book_append_sheet(wb, ws, e.name);
        })
        let file_name = "inventory_report_from_" + data["start_date"] + "_to_" +  data["end_date"] + ".xlsx" 
        XLSX.writeFile(wb, file_name);
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row justify="space-between">
                    <Col>
                        <div className="text-header">Inventory Report</div>
                    </Col>
                    <Col>
                        {/* <ExcelFile filename={"report"} element={<CommonButton style={{ margin: 10 }}>Export XLXS</CommonButton>}>
                            <ExcelSheet dataSet={exportExcel()} name={moment().format(dateFormat)}></ExcelSheet>
                        </ExcelFile> */}
                        <CommonButton onClick={() => {
                            exportExcel()
                        }} style={{ margin: 10 }}>Export XLXS</CommonButton>
                        {/* <CommonButton onClick={() => {
                            exportPDF()
                        }} style={{ margin: 10 }}>Export PDF</CommonButton>
                        <CommonButton onClick={() => {
                            onGetSalesData()
                        }}>Generate</CommonButton> */}
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Radio.Group onChange={onChangeRadio} value={data["selected_date_period"]}>
                        <Space>
                            <Radio value={"date"}>
                                <Col> Select date </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "date" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"week"}>
                                <Col> Select week </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="week" disabled={data["selected_date_period"] === "week" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"month"}>
                                <Col> Select month </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="month" disabled={data["selected_date_period"] === "month" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"quarter"}>
                                <Col> Select quarter </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="quarter" disabled={data["selected_date_period"] === "quarter" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"year"}>
                                <Col> Select year </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="year" disabled={data["selected_date_period"] === "year" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"range_date"}>
                                <Col> Select range date </Col>
                                <span> <RangePicker ranges={{
                                    "Today": [moment(), moment()],
                                    "This Week": [moment().startOf("week"), moment().endOf("week")],
                                    "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                    ],
                                    "This Quarter": [
                                        moment().startOf("quarter"),
                                        moment().endOf("quarter"),
                                    ],
                                    "This Year": [
                                        moment().startOf("year"),
                                        moment().endOf("year"),
                                    ],
                                }} onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "range_date" ? false : true} /> </span>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Row>
                <div className="type-list">
                    {empty(dataList) ?
                        <>
                            <div style={{ marginTop: 30 }}>
                                <TableWidget
                                    dataList={[]}
                                />
                            </div>
                        </>
                        :
                        dataList.map((data, index) => {
                            return (
                                <div style={{ marginTop: 30 }} key={index}>
                                    <div className="text-header">{`${data.id} ${data.name}`}</div>
                                    <TableWidget
                                        dataList={data.items}
                                    />
                                </div>);
                        })
                    }
                </div>
            </Spin>
        </CommonDivWidget>

    );
}

export default InventoryReport;
