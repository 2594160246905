import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import "./YieldRuleTableWidget.css"

function YieldRuleTableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, handleSave, handleDelete, } = props;
  const { errorField, setErrorField, } = props;

  const {
    ownCommonData,
    setOwnCommonData,
  } = props;
  const {
    invNameList,
    invUnitList,
    yieldNameList,
  } = ownCommonData;
  if (invNameList == undefined) {
    return <div></div>;
  }
  const columns = [
    {
      title: "No",
      dataIndex: "key",
    },
    {
      title: "Yield Name",
      dataIndex: "name",
      align: 'center',
      render: (text, record) => {
        const result = ownCommonData.invNameList.filter(item => item.id === record.slave_child_inv_id);
        if (result.length === 0) {
          return "Empty";
        } else {
          return result[0].name;
        }
      },
    },
    {
      title: "QTY",
      dataIndex: "slave_v_qty",
      align: 'center',
      sorter: {
        compare: (a, b) => a.slave_v_qty - b.slave_v_qty
      },
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_name.localeCompare(b.unit_name)
      },
      render: (text, record) => {
        const inv = ownCommonData.invNameList.filter(item => item.id === record.slave_child_inv_id);
        if (inv.length === 0) {
          return "Empty INV";
        }
        const result = ownCommonData.invUnitList.filter(item => item.id === inv[0].unit_id);
        if (result.length === 0) {
          return "Empty UNIT";
        }
        else {
          return result[0].name;
        }
      },
    },
    {
      title: "Cost Per Unit",
      dataIndex: "price_per_unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.price_per_unit - b.price_per_unit
      },
      render: text => <span>$ {text}</span>,
      render: (text, record) => {
        const result = ownCommonData.invNameList.filter(item => item.id === record.slave_child_inv_id);
          if (result.length === 0) {
            return "$0";
          }
          else {
            return <span>$ {result[0].average_price_per_unit}</span>;
          }
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      align: 'center',
      sorter: {
        compare: (a, b) => a.total_price.localeCompare(b.total_price)
      },
      render: text => <span>$ {text}</span>,
    },
    {
      title: "Yield Test",
      dataIndex: "yield_test",
      align: 'center',
      sorter: {
        compare: (a, b) => a.yield_test.localeCompare(b.yield_test)
      },
      render: text => <span>{text} % </span>,
    },
    {
      title: "Action",
      key: "action",
      align: 'center',
      render: (text, record) => {
        return (
          <div size="middle" direction={"vertical"}>
            <a style={{ color: "var(--redColor)", marginLeft:20 }}  className="del-btn" onClick={ async()=>{
              var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
              if (res == false) return;
              handleDelete(record)
            }}>
              Delete
            </a>
          </div>
        );
      },
    },
  ];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.key };
  });
  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}
export default withRouter(YieldRuleTableWidget)

