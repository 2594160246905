import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCreateSalableType, urlDeleteSalableType, urlGetSalableType, urlUpdateSalableType } from "./CONSTANTS";

export const apiGetSalableType = () => {
  const url = urlGetSalableType;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiCreateSalableType(data) {
  const url = urlCreateSalableType;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("fill_color", data.fill_color);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateSalableType(data) {
  const url = urlUpdateSalableType;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("fill_color", data.fill_color);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteSalableType(id) {
  const url = urlDeleteSalableType;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}