import { Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateInventoryCategory, apiUpdateIventoryCategory } from "services/inventoryCategoryService";
import ProductTypeSelector from "../../../../components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../../../services/ajaxServices";
import "./AddNewCategoryDialog.css";
const { TextArea } = Input;
function AddNewCategoryDialog(props) {
  const { proceed, show } = props;
  const { selInfo, oldList } = props;
  const [newCategory, setNewCategory] = useState({});
  const { isEdit, title } = props;

  useEffect(()=>{
    if(selInfo !== undefined){
      setNewCategory(selInfo)
    }
  }, [selInfo])
//   console.log(oldList);
  const createNewCategory = () => {
    if (newCategory.name == ""){
      message.error(`Please enter category name`);
      return;
    } 
    console.log("oldList-", oldList);
    const newValue = newCategory.name.trim();
    setNewCategory({ ...newCategory, name: newValue });

    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    apiCreateInventoryCategory(newCategory)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Category. " + err);
        });
  };

  const updateNewCategory = () => {
    if (newCategory.name == ""){
      message.error(`Please enter category name`);
      return;
    } 
    console.log("oldList-", oldList);
    const newValue = newCategory.name.trim();
    setNewCategory({ ...newCategory, name: newValue });

    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    apiUpdateIventoryCategory(newCategory)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Category. " + err);
        });
  };

  const onOKAction = () => {
    if (isEdit === false) {
      createNewCategory()
    }
    else {
      updateNewCategory()
    }
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-new-category-dlg"
      onOk={onOKAction}
    >
      <div>Please Enter new Category Name.</div>
      <div style={{ marginTop: 10 }}>name *</div>
      <Input
        value={newCategory.name === undefined ? "":newCategory.name}
        className="normal-input"
        onChange={(e) =>
          setNewCategory({ ...newCategory, name: e.target.value })
        }
      />
    </Modal>
  );
}

export default confirmable(AddNewCategoryDialog);
