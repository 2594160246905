import { useSelector } from "react-redux";
export const isEmpty = (list, field, errorList) => {
  if (list[field] === undefined || list[field] === "") {
    var res = [...errorList, field];
    return res;
  }
  return errorList;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const ValidateEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
}
export const ValidPhone = (str)=>{
  var isphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      str
  );
  return isphone;
}

export const getCurrencyInfo = () => {
  return "THB"
  // const userDataStore = useSelector(x => x.userDataStore);
  // const userInfo = userDataStore.user;
  // return userInfo.primary_currency;
}
export const is_empty = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === false
  ) {
    return true;
  } else {
    return false;
  }
};
export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};
export const empty = (value) => {
  let res = is_empty(value);
  //return res;
  if (!res) {
    //if value is not empty (res is false)
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object") {
      return isEmptyObject(value);
    } else {
      return false;
    }
  } else {
    return true;
  }
};