import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin, Switch, Checkbox } from "antd";

import "./AddItemDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { apiGetUserSiteItemRecipeList } from 'services/userSiteItemMainSubService';
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddItemDialog(props) {
  const { show, proceed, title, itemList } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    setIsBusy(true)
    apiGetUserSiteItemRecipeList()
      .then((data) => {
        setIsBusy(false)
        for (let i = 0; i < data.length; i++) {
          let tmpData = data[i]
          var storeIndex = itemList.findIndex(x => x.id == tmpData.id);
          if (storeIndex !== -1) {
            tmpData["checked"] = true
          } else {
            tmpData["checked"] = false
          }
        }
        setDataList(data)
        setSearchList(data)
      })
      .catch((err) => {
        setIsBusy(false)
        // message.error("Sorry! " + err);
      });
  }, [itemList])

  const handleSearch = (searchText) => {
    const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
    setSearchList([...tmpList])
  };
  const handleChange = (event) => {
    if (event.target.value === "") {
      setSearchList(dataList)
    }
    else {
      handleSearch(event.target.value)
    }
  };

  const onChange = (item, checked) => {
    let updateData = [...dataList];
    var storeIndex = updateData.findIndex(x => x.id == item.id);
    let tmpData = updateData[storeIndex]
    tmpData["checked"] = checked
    updateData.splice(storeIndex, 1, tmpData);
    setDataList(updateData)
  };
  const dataListUI = searchList == undefined || searchList.length == 0 ? <div>You don't have any recipe list</div> : searchList.map((info, index) => {
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={12} key={index}>
        <Checkbox
          checked={info.checked}
          onChange={(e) => {
            onChange(info, e.target.checked)
          }}
        >{info.name}</Checkbox>
      </Col>
    );
  });

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-item-dlg"
      onOk={async () => {
        const resultList = dataList.filter((info) => info.checked == true)
        proceed(resultList)
      }}
    >
      <Spin spinning={isBusy}>
        <div style={{ fontFamily: 'mediumFont', marginBottom: 15 }}>
          <TitleSearch
            onSearch={handleSearch}
            onChange={handleChange}
            onPressEnter={handleSearch}
          />
        </div>
        <div>
          <Row align={'middle'} gutter={16}>
            {dataListUI}
          </Row>
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddItemDialog)
