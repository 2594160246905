import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, Divider } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import "./RecipeUnitConvertPage.css"
import { showAddNewRecipeUnitConvertDialog } from './RecipeUnitConvertCreateDialog/showAddNewRecipeUnitConvertDialog';
import RecipeUnitConvertTableWidget from './RecipeUnitConvertTableWidget/RecipeUnitConvertTableWidget';
import { apiGetCustomUnitConvertList, apiDeleteCustomUnitConvert } from 'services/unitConvertService';

function RecipeUnitConvertPage(props) {
    const { ownCommonData, setOwnCommonData} = props;
    const [isBusy, setIsBusy] = useState(false);
    const [unitListInv, setUnitListInv] = useState([]);
    const [searchListInv, setSearchListInv] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetCustomUnitConvertList()
            .then((data) => {
                setIsBusy(false);
                let tmpData = data.filter(e=> e.ingredient_id === "" && e.recipe_id !== "")
                setUnitListInv(tmpData);
                setSearchListInv(tmpData)
            })
            .catch((err) => {
                setIsBusy(false);
                //message.error("Server failed. " + err);
            });
    };

    
    const createNewUnitInv = async () => {
        var res = await showAddNewRecipeUnitConvertDialog({
            title: "New Recipe Unit Converstaion",
            isEdit: false,
            oldList: unitListInv,
            ownCommonData : ownCommonData
        });
        if (res == null) return;
        let tmpData = res['addedItem'].filter(e=> e.ingredient_id === "" && e.recipe_id !== "")
        setUnitListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`new Unit has been created.`);
    };

    const onUpdateActionInv = async (info) => {
        var res = await showAddNewRecipeUnitConvertDialog({
            title: "Edit Recipe Unit Converstaion",
            isEdit: true,
            oldList: unitListInv,
            ownCommonData : ownCommonData,
            selInfo: info
        });
        if (res == null) return;
        let tmpData = res['addedItem'].filter(e=> e.ingredient_id === "" && e.recipe_id !== "")
        setUnitListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`Unit has been updated.`);
    }
    const onDeleteActionInv = (pInfo) => {
        setIsBusy(true);
        apiDeleteCustomUnitConvert(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let tmpData = data.filter(e=> e.ingredient_id === "" && e.recipe_id !== "")
                setUnitListInv(tmpData);
                setSearchListInv(tmpData)

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating Location. " + err);
            });
    }

    const handleSearchInv = (searchText) => {
        const tmpList = ownCommonData.recipeList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        const tmpList__ =  unitListInv.filter(e=> tmpList.filter(ee => ee.id == e.recipe_id) != undefined &&  tmpList.filter(ee => ee.id == e.recipe_id).length > 0)
        setSearchListInv([...tmpList__])
    };
    const handleChangeInv = (event) => {
        if (event.target.value === "") {
            setSearchListInv(unitListInv)
        }
        else {
            handleSearchInv(event.target.value)
        }
    };
    return (
            <Spin spinning={isBusy}>
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Recipe Unit Conversation</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                        <TitleSearch
                                            onSearch={handleSearchInv}
                                            onChange={handleChangeInv}
                                            onPressEnter={handleSearchInv}
                                        />
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewUnitInv();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <RecipeUnitConvertTableWidget
                                        ownCommonData={ownCommonData}
                                        unitList={searchListInv}
                                        onUpdateAction={onUpdateActionInv}
                                        onDeleteAction={onDeleteActionInv}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Spin>
    );
};


export default RecipeUnitConvertPage
