import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin } from "antd";

import "./AddTeamMemberDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
function AddTeamMemberDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({});
    }
  }, [record]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-team-member-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Member Avatar*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Enter Name*</div>
              <ProductDescriptionInput
                placeholder="name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddTeamMemberDialog)
