import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateYieldRule, urlGetIngredient, urlGetYieldRuleByInvNameId, urlYieldGetBasicInfo, urlGetYieldRuleList, urlDeleteYieldRule, urlgetYieldRuleByID, urlUpdateYieldRule } from "./CONSTANTS";



export const apiGetYieldBasicData = () => {
  const url = urlYieldGetBasicInfo;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiCreateYieldRule(data, yieldRuleList) {
  const url = urlCreateYieldRule;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("master_child_inv_id", data.master_child_inv_id);
  formData.append("parent_inv_id", data.parent_inv_id);
  formData.append("parent_v_qty", data.parent_v_qty);
  formData.append("waste_v_qty", data.waste_v_qty === undefined ||  data.waste_v_qty === "" ? 0 : data.waste_v_qty);
  formData.append("unit_id", data.unit_id);
  formData.append("yield_test", data.yield_test);
  formData.append("master_v_qty", data.master_v_qty);
  formData.append("yieldRuleList", JSON.stringify(yieldRuleList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateYieldRule(data, yieldRuleList) {
  const url = urlUpdateYieldRule;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("master_child_inv_id", data.master_child_inv_id);
  formData.append("parent_inv_id", data.parent_inv_id);
  formData.append("parent_v_qty", data.parent_v_qty);
  formData.append("waste_v_qty", data.waste_v_qty === undefined ||  data.waste_v_qty === "" ? 0 : data.waste_v_qty);
  formData.append("unit_id", data.unit_id);
  formData.append("yield_test", data.yield_test);
  formData.append("master_v_qty", data.master_v_qty);
  formData.append("yieldRuleList", JSON.stringify(yieldRuleList));

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export const apiGetYieldRuleList = () => {
  const url = urlGetYieldRuleList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiDeleteYieldRule(id) {
  const url = urlDeleteYieldRule;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export const apigetYieldRuleByID = (id) => {
  const url = urlgetYieldRuleByID;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const apiGetYieldRuleByInvNameId = (inv_name_id) => {
  const url = urlGetYieldRuleByInvNameId;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("inv_name_id", inv_name_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const apiGetIngredient = () => {
  const url = urlGetIngredient;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};
