import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import DetailTable from './DetailTable';
import { getCurrencyInfo } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList,onDeleteAction} = props;

    function createMarkup(html) {
        return { __html: html };
    }
    
    if (dataList === undefined) return <div />
    
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Author Name",
            dataIndex: "author_name",
            align : "center",
            sorter: {
                compare: (a, b) => a.author_name.localeCompare(b.author_name),
            },
        },
        {
            title: "Spoilage Reason",
            dataIndex: "reason",
            align : "center",
            sorter: {
                compare: (a, b) => a.reason.localeCompare(b.reason),
            },
            render: (text, record) => {
                return <div dangerouslySetInnerHTML={createMarkup(record.reason)} />;
            },
        },
        {
            title: "Spoilage Total budget",
            dataIndex: "totalBudget",
            align : "center",
            sorter: {
                compare: (a, b) => a.totalBudget - b.totalBudget,
            },
            render: text => <span>{ "$(" + getCurrencyInfo() + ") " + text}</span>,
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align : "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            align : "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Cacel Confirmation", content: "Are you sure to cancel this order?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    return <DetailTable
                        dataList={record.cartList} />
                }}
            />
        </div>
    )
}

export default withRouter(TableWidget)
