// @flow strict

import React, { useEffect, useState } from "react";
import { PictureOutlined } from "@ant-design/icons";
// avatarBlank
function ProductImageWidget(props) {
  const [imageUrl, setImageUrl] = useState("");
  const { productInfo, setProductInfo } = props;
  const { errorField, setErrorField } = props;
  const { id, field } = props;

  useEffect(() => {
    setImageUrl(productInfo.thumbnail_image);
  }, [productInfo.thumbnail_image]);
  const onDropImage = async (e) => {
    // console.log("/////////////////");
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setImageUrl(URL.createObjectURL(files[0]));
    setProductInfo({ ...productInfo, image_name: files[0] });
  };
  //   product-image-widget-error
  return (
    <div className="product-image-widget">
      <label htmlFor={id === undefined ? "upload_product_image" : id}>
        {imageUrl == null || imageUrl == "" ? (
          <img
            className={
              "product-image-widget-image " +
              (errorField.includes("image_name") ? "product-image-widget-error" : "")
            }
            src={'/assets/icons/ic_picture.svg'}
            alt="Kalaecafe"
                />
        ) : (
          <img src={imageUrl} className={"product-image-widget-image "} />
        )}
      </label>
    </div>
  );
}

export default ProductImageWidget;
