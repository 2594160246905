// @flow strict

import { useAuth } from "navigation/Auth/ProvideAuth";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";


function RegisterPage(props) {
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUser({ token: undefined }));
  }, []);

  return <div>REGISTER PAGE</div>;
}

export default RegisterPage;
