import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, DatePicker } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import { apiCreateSalableType, apiDeleteSalableType, apiGetSalableType, apiUpdateSalableType } from 'services/salableTypeService';
import { showAddSalableTypeDialog } from './AddSalableTypeDialog/showAddSalableTypeDialog';
import "./SalableTypePage.css"
import SalableTypeListTableWidget from './TableWidget/SalableTypeListTableWidget';
const { RangePicker } = DatePicker;

function SalableTypePage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    useEffect(() => {
        apiGetSalableType()
        .then((data) => {
            setSearchList(data)
            setDataList(data)
        })
        .catch((err) => {
            // message.error("Sorry! " + err);
        });
    }, []);

  
    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    const onUpdateAction = async (pInfo) => {
        let info = pInfo
        var res = await showAddSalableTypeDialog({
          title: "Edit Sellable Type Info",
          selInfo: info,
          isEditing : true
        });
        if (res === null) return;
        setIsBusy(true);
        apiUpdateSalableType(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating a Info");
                }
                let updateData = [...dataList];
                var storeIndex = updateData.findIndex(x => x.id == data[0].id);
                updateData.splice(storeIndex, 1, data[0]);
                setDataList(updateData);
                setSearchList(updateData)
                message.success("Info has been updated.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onDeleteAction = (pInfo) => {
        setIsBusy(true);
        apiDeleteSalableType(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onCreateAction = async ()=>{
        var res = await showAddSalableTypeDialog({
            title: "Create New Sellable type",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true);
        apiCreateSalableType(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while creating a Sellable Type");
                }
                let updateData = [...dataList];
                updateData = [...data, ...updateData];
                setDataList(updateData);
                setSearchList(updateData)
                message.success("New Sellable Type has been created.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err)
            });
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                        <Row justify="center" align="middle">
                            <Col>
                                <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                                    <RangePicker size={'default'}
                                        onChange={(e, date) => {
                                            if (date == null) {
                                                handleDateFilter("", "")
                                            } else {
                                                handleDateFilter(date[0], date[1])
                                            }

                                        }} />
                                </div>
                            </Col>
                            <Col flex={'auto'}>
                                <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                                    <TitleSearch
                                        onSearch={handleSearch}
                                        onChange={handleChange}
                                        onPressEnter={handleSearch}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <Tooltip title={"Click to add new one."}>
                                    <CommonButton
                                        onClick={() => {
                                            onCreateAction()
                                        }}
                                    >
                                        New
                                    </CommonButton>
                                </Tooltip>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 10 }}>
                            <SalableTypeListTableWidget
                                dataList={searchList}
                                onUpdateAction={onUpdateAction}
                                onDeleteAction={onDeleteAction}
                            />
                        </div>


            </Spin>
        </CommonDivWidget>
    );
};


export default SalableTypePage
