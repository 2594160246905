import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, } from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import "./RecipeDetailTableWidget.css"
import { getCurrencyInfo } from 'utils/GlobalFunctions';

const RecipeDetailTableWidget = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, ownCommonData } = props;
  
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      align: 'center',
    },
    {
      title: "Name",
      dataIndex: "name",
      align: 'center',
    },
    {
      title: "Qty",
      dataIndex: "total_enter_qty",
      align: 'center',
    },
    {
      title: "Unit",
      dataIndex: "unit_id",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_id - b.unit_id,
      },
      render:(text, record) => {
        const result = ownCommonData.unitList.filter(item => item.id === record.unit_id);
        if (result.length === 0) {
          return "---";

        }
        else {
          return result[0].name;
        }
        
      }
    },
    {
      title: "Total Cost",
      dataIndex: "total_price",
      align: 'center',
      sorter: {
        compare: (a, b) => a.total_price - b.total_price
      },
      render: text => <span>{ "$(" + getCurrencyInfo() + ") " + text}</span>, 

    },
    {
      title: "Pre Instruction",
      dataIndex: "pre_instruction",
      align: 'center',
    },
    {
      title: "Post Instruction",
      dataIndex: "post_instruction",
      align: 'center',
    },
    {
      title: "Procedure",
      dataIndex: "procedure",
      align: 'center',
    },
    {
      title: "Created Date",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated Date",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(RecipeDetailTableWidget)
