
import { Modal, Spin, Row, Col, Radio, message } from "antd";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";
import { useState } from "react";
import { confirmable } from "react-confirm";
import { isEmpty } from "utils/GlobalFunctions";
import "./SpoilageGroupDialog.css"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

function SpoilageGroupDialog(props) {
  const { show, proceed, totalBudget } = props;
  const [ spoilageInfo, setSpoilageInfo ] = useState({author_name : '', reason : ''})
  const [spoilageReason, setSpoilageReason] = useState('');

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(spoilageInfo, "author_name", errorList);
    return errorList.length == 0;
  };
  const onOkAction = async () => {
    if ((await validateFields()) === false) {
      message.error("Please Enter Your Name")
      return
    }
    if (spoilageReason.isEmpty) {
      message.error("Please Enter Spoilage Reason")
      return
    }
    spoilageInfo["reason"] = spoilageReason
    setSpoilageInfo({ ...spoilageInfo });
    proceed({
      spoilageInfo : spoilageInfo,
    });
  }
  const onChangeText = (text) => {
    setSpoilageReason(text)
  };
  
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ]
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="spoilage-confirm-dlg"
      closable={true}
      maskClosable={false}
      title={`Spoilage Confirm from you!`}
      onOk={onOkAction}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>Your name*</div>
          <ProductDescriptionInput
            placeholder="Enter your name"
            field={"author_name"}
            productInfo={spoilageInfo}
            setProductInfo={setSpoilageInfo}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>Spoilage Reason*</div>
          <ReactQuill theme="snow" value={spoilageReason} onChange={onChangeText}
            modules={modules}
          />
        </Col>
      </Row>
      

    </Modal>
  );
}

export default confirmable(SpoilageGroupDialog);
