import { message, Spin, Tabs } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { useEffect } from "react";
import { useState, React } from "react";
import GenerateReport from "./GenerateReport/GenerateReport";
import "./ReportPage.css";
import SalesAnalysis from "./SalesAnalysis/SalesAnalysis";
import InventoryReport from "./InventoryReport/InventoryReport";

function ReportPage() {
  const [isBusy, setIsBusy] = useState(false);

  const tabList = [
    {
      key: "tab1",
      label: "Generate Report",
      children: <GenerateReport/>,
    },
    {
      key: "tab2",
      label: "Sales Analysis",
      children: <SalesAnalysis/>,
    },
    {
      key: "tab3",
      label: "Inventory Report",
      children: <InventoryReport/>,
    },
  ];

  useEffect(() => {
    
  }, []);


  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Tabs defaultActiveKey="tab1" type="card" items={tabList}>
        </Tabs>
      </Spin>
    </CommonDivWidget>
  );
}

export default ReportPage;
