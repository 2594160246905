import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Table, Row, List, Avatar } from "antd";
import { showColumnAction } from './RecipeUnitConvertTableColumn';
import "./RecipeUnitConvertTableWidget.css"
import { PicCenterOutlined } from '@ant-design/icons';
function RecipeUnitConvertTableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { unitList, onUpdateAction, onDeleteAction, ownCommonData } = props;
  if (unitList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      align: "center",
      dataIndex: "id"
    },
    {
      title: "Recipe Name",
      dataIndex: "recipe_id",
      align: "center",
      sorter: {
        compare: (a, b) => a.recipe_id.localeCompare(b.recipe_id),
      },
      render:(text, record) => {
        let ret = ownCommonData.recipeList.filter(e => e.id === record.recipe_id)[0]
        if (ret !== undefined) {
          return <span>{ret.name}</span>
        }
        else {
          return <span><i>--Removed--</i> </span>
        }
        
      }
    },
    {
      title: "Master Unit",
      dataIndex: "unit_id",
      align: "center",
      sorter: {
        compare: (a, b) => a.unit_id.localeCompare(b.unit_id),
      },
      render:(text, record) => {
        let ret = ownCommonData.unitList.filter(e => e.id === record.unit_id)[0]
        if (ret !== undefined) {
          return <span>1 &nbsp;{ret.name}</span>
        }
        else {
          return <span><i>--Removed--</i> </span>
        }
      }
    },
    {
      title: "Rate",
      dataIndex: "rate",
      align: "center",
      sorter: {
        compare: (a, b) => a.rate.localeCompare(b.rate),
      },
    },
    {
      title: "Standard Unit",
      dataIndex: "standard_unit_id",
      align: "center",
      sorter: {
        compare: (a, b) => a.standard_unit_id.localeCompare(b.standard_unit_id),
      },
      render:(text, record) => {
        let ret = ownCommonData.unitList.filter(e => e.id === record.standard_unit_id)[0]
        if (ret !== undefined) {
          return <span>{ownCommonData.unitList.filter(e => e.id === record.standard_unit_id)[0].name}</span>
        }
        else {
          return <span><i>--Removed--</i> </span>
        }
      }
    },
    {
      title: "Created At",
      align: "center",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated At",
      align: "center",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "active",
      sorter: {
        compare: (a, b) => a.active - b.active,
      },
      render: (text, record) => {
        if (record.active === "1") {
          return <span className="active-btn">A</span>
        }
        else {
          return <span className="inactive-btn">I</span>
        }
      }
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => {
        return showColumnAction(text, record, onUpdateAction, onDeleteAction)
      },
    },
  ];
  const locationDataSource = unitList.map((info) => {
    return { ...info, key: info.id };
  });
  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={locationDataSource}
      />
    </div>
  )
}

export default withRouter(RecipeUnitConvertTableWidget)
