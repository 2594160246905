// @flow strict

import { Input, message, Row, Col, InputNumber } from "antd";
import Modal from "antd/lib/modal/Modal";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import { axiosPost } from "services/ajaxServices";
import "./AddYieldNameDialog.css"
function AddYieldNameDialog(props) {
  const { show, proceed } = props;
  const { url, unitName, oldList, unitList } = props;
  const [value, setValue] = useState("");
  const [valuePrice, setValuePrice] = useState(0);
  const [selInventory, setSelInventory] = useState({});
  
  const createOneUnit = () => {
    //   proceed()
    if (value == "") return;
    
    const newValue = value.trim();
    setValue(newValue);
    
    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }

    if (selInventory["unit_id"] == undefined) {
      message.error("Please select unit");
      return;
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("average_price_per_unit", valuePrice);
    formData.append("name", newValue);
    formData.append("unit_id", selInventory["unit_id"]);

    new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
        .then((res) => {
          if (res == null) {
            message.error("There's same value from other vendor");
            return;
          }
          proceed({
            addedItem: res,
          });
        })
        .catch((err) => {
          message.error(err);

        });
    });
  };

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="custom-base-unit-dlg"
      closable={true}
      maskClosable={false}
      title={`${unitName} Add Dialog`}
      onOk={createOneUnit}
    >
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div>Yield Name*</div>
          <Input className="normal-input" value={value} onChange={(e) => setValue(e.target.value)} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div>Cost/Unit*</div>
          <InputNumber
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              background: `var(--backgroundColor)`,
              padding: "4px 12px",
              width: "100%"
            }}
            defaultValue={0}
            formatter= {value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            min={"0"}
            max={"1000000" }
            step={ "1" }
            onChange={(value) => {
             setValuePrice(value)
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div>Unit*</div>
          <ProductTypeSelector
                placeholder={"Select Unit"}
                field={"unit_id"}
                productInfo={selInventory}
                setProductInfo={setSelInventory}
                list={unitList}
                displayField="name"
                idField="id"
              />
        </Col>
      </Row>
    </Modal>
  );
}

export default confirmable(AddYieldNameDialog);
