import { axiosGet, axiosPost } from "./ajaxServices";
import { urlDeleteInventoryName, urlGetInventoryName, urlUpdateInventoryName} from "./CONSTANTS";

export const apiGetInventoryName = () => {
  const url = urlGetInventoryName;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function apiDeleteInventoryName(id) {
  const url = urlDeleteInventoryName
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export function apiUpdateInventoryName(info) {
  const url = urlUpdateInventoryName
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", info.id);
  formData.append("name", info.name);
  formData.append("average_price_per_unit", info.average_price_per_unit);
  formData.append("salable", info.salable);
  formData.append("salable_type_id", info.salable_type_id);
  formData.append("sell_price_percentage", info.sell_price_percentage);
  if (info.image_name instanceof File) {
    formData.append("image_name", info.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}