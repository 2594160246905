import React from "react";
import { Row, Spin, Col, DatePicker, Space, message } from 'antd';
import "./SalaryPage.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showAddSalaryDialog } from "./AddSalaryDialog/showAddSalaryDialog";
import { apiCreateSalary, apiDeleteSalary, apiGetSalary, apiUpdateSalary } from "services/salaryService";
const { RangePicker } = DatePicker;

function SalaryPage() {
    const [searchList, setSearchList] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        apiGetSalary()
            .then((data) => {
                setSearchList(data)
                setDataList(data)
            })
            .catch((err) => {
                // message.error("Sorry! " + err);
            });
    }, []);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }

    }

    const onCreateAction = async (pInfo) => {
        var res = await showAddSalaryDialog({
            title: "Add Salary",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true)
        apiCreateSalary(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while adding salary data");
                }
                var tmpData = dataList;
                let updateData = [...dataList];
                updateData.splice(tmpData.length, 0, data);
                tmpData = [...updateData];
                setDataList(tmpData)
                setSearchList(tmpData)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });
    }

    const onUpdateAction = async (pInfo) => {
        let info = pInfo
        var res = await showAddSalaryDialog({
            title: "Edit Salary",
            record: info,
            isEditing: true
        });
        if (res === null) return;

        setIsBusy(true);
        apiUpdateSalary(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating a salary data");
                }
                let updateData = [...dataList];
                var storeIndex = updateData.findIndex(x => x.id == data.id);
                updateData.splice(storeIndex, 1, data);
                setDataList(updateData)
                setSearchList(updateData)

            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }

    const onDeleteAction = (pInfo) => {
        setIsBusy(true);
        apiDeleteSalary(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
                updateData.splice(storeIndex, 1);
                var tmpData = [...updateData];
                setDataList(tmpData);
                setSearchList(tmpData);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div>
                    <Row justify={"space-between"}>
                        <Col>
                            <span style={{ fontFamily: 'mediumFont', paddingRight: 20 }}>Date Filter Period: </span>
                            <RangePicker size={'default'}
                                onChange={(e, date) => {
                                    if (date == null) {
                                        handleDateFilter("", "")
                                    } else {
                                        handleDateFilter(date[0], date[1])
                                    }

                                }} />
                        </Col>
                        <Col>
                            {
                                <div>
                                    <CommonButton onClick={() => {
                                        onCreateAction()
                                    }} style={{ margin: 10 }}>New</CommonButton>
                                </div>
                            }
                        </Col>

                    </Row>
                    <div style={{ marginTop: 10 }}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <TableWidget
                            dataList={searchList}
                            setDataList={setSearchList}
                            onDeleteAction={onDeleteAction}
                            onUpdateAction={onUpdateAction}
                        />
                    </div>
                </div>
            </Spin>
        </CommonDivWidget>

    );
}

export default SalaryPage;
