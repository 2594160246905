import { Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { confirmable } from "react-confirm";
import { isEmpty } from "utils/GlobalFunctions";
import "./AddSalableTypeDialog.css";
function AddSalableTypeDialog(props) {
  const { proceed, show } = props;
  const { selInfo, oldList, isEditing } = props;
  const [newUnit, setNewUnit] = useState({});
  const [errorField, setErrorField] = useState([]);
  const { isEdit, title } = props;
  useEffect(() => {
    if (selInfo !== undefined) {
      setNewUnit(selInfo)
    } else {
      setNewUnit({ ...newUnit, fill_color: "#000000"})
    }
  }, [selInfo])

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(newUnit, "name", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onOKAction = async () => {
    if (isEditing === false && (await validateFields()) === false) return;
    proceed({
      ...newUnit,
    });
  }
  const onChangeFillColor = (color, event) => {
    setNewUnit({ ...newUnit, fill_color: color["hex"]})
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-unit-new-dialog"
      onOk={onOKAction}
    >
      <div>Please Enter new Sellable Type Name.</div>
      <div style={{ marginTop: 10 }}>Name *</div>
      <Input
        value={newUnit.name === undefined ? "" : newUnit.name}
        className="normal-input"
        onChange={(e) =>
          setNewUnit({ ...newUnit, name: e.target.value })
        }
      />
      <div style={{ paddingTop: 20 }}>
        <div style={{ paddingBottom: 10 }}>Please Picker Color to display different color on Chef Order Site.</div>
        <TwitterPicker
          colors={[
            "#000000",
            "#FF6900",
            "#FCB900",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#ABB8C3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
          ]}
          color={newUnit["fill_color"]}
          onChange={onChangeFillColor}
        />
      </div>
    </Modal>
  );
}

export default confirmable(AddSalableTypeDialog);
