import React from "react";
import { Row, Spin, Col, Select, Button, message, Image } from 'antd';
import "./TablePlanPage.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from 'react-router-dom'
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { Stage } from "react-konva";
import { ShapeTool, ShapeType } from "config/CONSTANTS";
import ShapeEditingDraw from "./ShapeGenerator/ShapeEditingDraw";
import LayerBackground from "./LayerBackground/LayerBackground";
import LayerShapeList from "./LayerShapeList/LayerShapeList";
import EditorToolWidget from "./EditorToolWidget/EditorToolWidget";
import BackgroundToolWidget from "./BackgroundToolWidget/BackgroundToolWidget";
import ShapeEditingText from "./ShapeGenerator/ShapeEditingText";
import { showAddCookTableDialog } from "./AddCookTableDialog/showAddCookTableDialog";
import { showDetailCookTableDialog } from "./DetailCookTableDialog/showDetailCookTableDialog";
import { apiGetRoomById, apiUpdateRoomDiagram } from "services/roomService";
function TablePlanPage() {

    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false)
    const [previewVisible, setPreviewVisible] = useState(false);
    const [prevewImgData, setPreviewImgData] = useState("");
    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0
    });
    var storyWidth = 1000
    var storyHeight = 650
    const stageRef = useRef(null);
    
    const [storyInformation, setStoryInformation] = useState({
        Media: "",
        title: "",
        description: "",
    });

    const [errorField, setErrorField] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [room, setRoom] = useState({})

    const [shapeList, setShapeList] = useState([]);
    const [selectedShape, setSelectedShape] = useState(null);
    const [selTool, setSelTool] = useState(ShapeTool.NONE);

    const [baseDrawInfo, setBaseDrawInfo] = useState({
        stroke: 10,
        color: "#000",
        fill_color: "#FF6900",
        background: null,
        font_family: "serif",
    });

    useEffect(() => {
        if (isBusy) {
            setSelTool(ShapeTool.NONE);
            setSelectedShape(null);
        }
    }, [isBusy]);

    useEffect(() => {
        if (id !== undefined) {
            setIsEdit(true)
        }
    }, [id])

    useEffect(() => {
        setIsBusy(true);
        apiGetRoomById(id)
            .then(async (data) => {
                storyWidth = Math.min(storyWidth, data.width)
                storyHeight = Math.min(storyHeight, data.height)
                let tmpList = []
                for (let i = 0; i < data.shapeList.length; i++) {
                    let item = data.shapeList[i]
                    let image = new window.Image();
                    image.crossOrigin = 'Anonymous';
                    image.src = item["thumbnail_image"];
                    if (item["type"] != ShapeType.TEXT && item["type"] != ShapeType.LINE) {
                        await image.decode();
                    }
                    const shape = {
                        id: item["id"],
                        image: image,
                        isDradding: false,
                        table_name: item["table_name"],
                        seat_count: item["seat_count"],
                        image_name: item["thumbnail_image"],
                        reservation_client_name: item["reservation_client_name"],
                        reservation_start_timestamp: item["reservation_start_timestamp"],
                        reservation_end_timestamp: item["reservation_end_timestamp"],
                        reservation_phone_number: item["reservation_phone_number"],
                        reservation_seat_count: item["reservation_seat_count"],
                        reservation_description: item["reservation_description"],
                        status: item["status"],
                        type: item["type"],
                        x: parseFloat(item["x"]),
                        y: parseFloat(item["y"]),
                        rotation: parseFloat(item["rotation"]),
                        width: parseFloat(item["width"]),
                        height: parseFloat(item["height"]),
                        stroke: parseFloat(item["stroke"]),
                        points: JSON.parse(item["points"]),
                        text: item["text"],
                        fontSize: parseFloat(item["fontSize"]),
                        font_family: item["font_family"],
                        color: item["color"],
                    };


                    tmpList.push(shape)
                }
                setShapeList(tmpList)
                setRoom({ ...data })

                setIsBusy(false);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }, [])
    const onMouseDownPane = (e) => {
        if (selectedShape == null) {
            return;
        }

        if (e.target.parent != null && e.target.parent.getId() == "transformer") {
            return;
        }
        if (e.target.getId() == selectedShape) {
            return;
        }

        setSelectedShape(null);
    };
    const onAddShape = (shape) => {
        if (selTool != ShapeTool.PENCIL) {
            setSelTool(ShapeTool.NONE);
        }
        if (shape == "" || shape == undefined) {
            return;
        }
        setShapeList([...shapeList, shape]);
    };


    const Save = () => {
        setIsBusy(true);
        apiUpdateRoomDiagram(id, shapeList)
            .then((data) => {
                setIsBusy(false);
                message.success("Successfully Saved!")
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const onDetailShape = async (detail) => {
        var res = await showDetailCookTableDialog({
            title: "Cook Table Detail",
            record: detail,
            isEditing: true
        });
        if (res === null) return;
        var tmpData = shapeList;
        let updateData = [...tmpData];
        var storeIndex = shapeList.findIndex(x => x.id == res.id);
        updateData.splice(storeIndex, 1, res);
        tmpData = [...updateData];
        setShapeList(tmpData);
    }

    const NewTable = async () => {
        var res = await showAddCookTableDialog({
            title: "Add Cook Table",
            isEditing: false
        });
        if (res === null) return;
        if (res["image_name"] === undefined) {
            message.info("Please insert Cook Table Image!")
            return
        }
        setSelTool(ShapeTool.NONE);
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                const imageData = reader.result;
                const id = `local-image-${Date.now()}`;
                let image = new window.Image();
                image.crossOrigin = 'Anonymous';
                image.src = imageData;

                image.onload = function () {
                    let imageWidth = image.width;
                    let imageHeight = image.height;

                    if (imageWidth > room.width && imageHeight > room.height) {
                        const ratio = imageWidth / imageHeight;
                        imageHeight = room.height;
                        imageWidth = room.height * ratio;
                    }
                    const shape = {
                        id: id,
                        type: ShapeType.TABLE,
                        x: 30,
                        y: 30,
                        width: 150,
                        height: 150 * image.height / image.width,
                        image: image,
                        isDradding: false,
                        table_name: res["table_name"].replace(/\s+/g, '-'),
                        seat_count: res["seat_count"],
                        image_name: res["image_name"],
                        reservation_client_name: res["reservation_client_name"],
                        reservation_start_timestamp: res["reservation_start_timestamp"],
                        reservation_end_timestamp: res["reservation_end_timestamp"],
                        reservation_phone_number: res["reservation_phone_number"],
                        reservation_seat_count: res["reservation_seat_count"],
                        reservation_description: res["reservation_description"],
                        status: res["status"],
                    };
                    onAddShape(shape);
                };
            },
            false
        );

        reader.readAsDataURL(res["image_name"]);
    }


    const scaleRelativeToPoint = (point, increaseScale) => {
        const scaleBy = 1.02;
        const stage = stageRef.current;
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: point.x / oldScale - stage.x() / oldScale,
            y: point.y / oldScale - stage.y() / oldScale
        };

        const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy;

        setStage({
            scale: newScale,
            x: (point.x / newScale - mousePointTo.x) * newScale,
            y: (point.y / newScale - mousePointTo.y) * newScale
        });
    };

    const handleWheel = (e) => {
        e.evt.preventDefault();
        scaleRelativeToPoint(
            e.target.getStage().getPointerPosition(),
            e.evt.deltaY < 0
        );
    };
    
    const PreviewFunc = () => {
        const uri = stageRef.current.toDataURL();
        setPreviewImgData(uri)
        setPreviewVisible(true)
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row justify="space-between">
                    <Col>
                        <div className="text-header">Design Table Plan</div>
                    </Col>
                    <Col >
                        <CommonButton onClick={() => {
                            NewTable()
                        }} style={{ margin: 10 }}>New Table</CommonButton>
                        <CommonButton onClick={() => {PreviewFunc()}} style={{ margin: 10 }}>Preview</CommonButton>
                        <CommonButton onClick={() => { Save()}}>Save Diagram</CommonButton>
                    </Col>
                </Row>
                {room.width != null &&
                    <Row wrap={false} justify={"space-between"}>
                        <Col>
                            <EditorToolWidget
                                width={room.width}
                                height={room.height}
                                baseDrawInfo={baseDrawInfo}
                                setBaseDrawInfo={setBaseDrawInfo}
                                setSelTool={setSelTool}
                                selTool={selTool}
                                onAddShape={onAddShape}
                                storyInformation={storyInformation}
                                setStoryInformation={setStoryInformation}
                                errorField={errorField}
                                setErrorField={setErrorField}
                            />
                            <BackgroundToolWidget
                                width={room.width}
                                height={room.height}
                                baseDrawInfo={baseDrawInfo}
                                setBaseDrawInfo={setBaseDrawInfo}
                                onAddShape={onAddShape}
                                setSelTool={setSelTool}
                            />

                        </Col>
                        <Col style={{ margin: 'auto' }}>
                            <div
                                style={{
                                    border: "1px solid grey",
                                    background: "white",
                                    marginTop: 10,
                                    overflow: 'auto',
                                    width: storyWidth,
                                    height: storyHeight
                                }}
                            >
                                <Stage
                                    ref={stageRef}
                                    width={room.width}
                                    height={room.height}
                                    onMouseDown={onMouseDownPane}
                                    onTouchStart={onMouseDownPane}
                                // onWheel={handleWheel}
                                // scaleX={stage.scale}
                                // scaleY={stage.scale}
                                // x={stage.x}
                                // y={stage.y}
                                >
                                    <LayerBackground
                                        baseDrawInfo={baseDrawInfo}
                                        setBaseDrawInfo={setBaseDrawInfo}
                                        width={room.width}
                                        height={room.height}
                                    />
                                    <LayerShapeList
                                        width={room.width}
                                        height={room.height}
                                        shapeList={shapeList}
                                        setShapeList={setShapeList}
                                        selectedShape={selectedShape}
                                        setSelectedShape={setSelectedShape}
                                        onDetailShape={onDetailShape}
                                    />
                                    {selTool === ShapeTool.PENCIL && (
                                        <ShapeEditingDraw
                                            width={room.width}
                                            height={room.height}
                                            onAddShape={onAddShape}
                                            baseDrawInfo={baseDrawInfo}
                                        />
                                    )}
                                </Stage>
                                {selTool === ShapeTool.TEXT && (
                                    <ShapeEditingText
                                        width={room.width}
                                        height={room.height}
                                        oldText=""
                                        onAddShape={onAddShape}
                                        baseDrawInfo={baseDrawInfo}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                }
            </Spin>
            <Image
                width={200}
                style={{ display: 'none' }}
                preview={{
                    visible:previewVisible,
                    src: prevewImgData,
                    onVisibleChange: value => {
                        setPreviewVisible(value);
                    },
                }}
            />
        </CommonDivWidget>

    );


}

export default TablePlanPage;
