import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin, Switch } from "antd";

import "./AddMainCategoryDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddMainCategoryDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({
        "status": "0"
      });
    }
  }, [record]);

  const onViewMode = (checked) => {
    setData({
      ...data, "status": checked === true ? "1" : "0"
    });
  }

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);

    if (data["thumbnail_image"] === undefined && data["image_name"] === undefined) {
      errorList = [...errorList, "image_name"]
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-main-category-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Category Image*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              <div>Category Name*</div>
              <ProductDescriptionInput
                placeholder="Category Name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
              <Switch
                checkedChildren="Show"
                unCheckedChildren="Hide"
                onChange={onViewMode}
                checked = {data["status"] === "1" ? true:false}
                style={{ marginTop: "10px" }}
              />
            </Col>
          </Row>




        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddMainCategoryDialog)
