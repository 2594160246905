import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import {
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_CATEGORY,
  ROUTE_INVENTORY,
  ROUTE_INVENTORY_NAME,
  ROUTE_RECIPE_ADD,
  ROUTE_RECIPE_LIST,
  ROUTE_RECIPE_EDIT,
  ROUTE_YIELD_RULE,
  ROUTE_LOCATION,
  ROUTE_INGREDIENT_LIST,
  ROUTE_UNIT,
  ROUTE_PAY_HISTORY,
  ROUTE_ORDER,
  ROUTE_PROFILE,
  ROUTE_SPOILAGE_ADD,
  ROUTE_SPOILAGE_LIST,
  ROUTE_SALABLE_TYPE,
  ROUTE_ENT_ADD,
  ROUTE_ENT_LIST,
  ROUTE_TEAM_MEMBER_LIST,
  ROUTE_SALARY_ADD,
  ROUTE_SALARY,
  ROUTE_SETTING,
  ROUTE_REPORT,
  ROUTE_TABLE_PLAN,
  ROUTE_TABLE_PLAN_LIST,
  ROUTE_USER_ITEM_MAIN_CATEGORY,
  ROUTE_USER_ITEM_SUB_CATEGORY,
  ROUTE_USER_MAIN_SUB_ITEM_LIST,
  ROUTE_YIELD_RULE_LIST,
  ROUTE_YIELD_RULE_EDIT,
  ROUTE_USERS,
  ROUTE_USER_MAIN_SUB_ITEM_ADD,
  ROUTE_USER_MAIN_SUB_ITEM_EDIT,
  ROUTE_COST,

} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage/ForgotPasswordPage";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import DashboardPage from "pages/DashboardPage/Dashboard";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import InventoryPage from "pages/InventoryPage/InventoryPage";
import CategoryPage from "pages/CategoryPage/CategoryPage";
import InventoryGroupByName from "pages/InventoryGroup/InventoryGroupByName";
import YieldRulePage from "pages/YieldRulePage/YieldRulePage"
import RecipeAddPage from "pages/RecipeAddPage/RecipeAddPage";
import RecipeListPage from "pages/RecipeListPage/RecipeListPage";
import LocationPage from "pages/LocationPage/LocationPage";
import IngredientListPage from "pages/IngredientListPage/IngredientListPage";
import UnitPage from "pages/UnitPage/UnitPage";
import PayHistoryPage from "pages/PayHistoryPage/PayHistoryPage";
import OrderPage from "pages/OrderPage/OrderPage";
import UserProfilePage from "pages/ProfilePage/UserProfile/UserProfilePage";
import SpoilageAddPage from "pages/SpoilageAddPage/SpoilageAddPage";
import SpoilageListPage from "pages/SpoilageListPage/SpoilageListPage";
import SalableTypePage from "pages/SalableTypePage/SalabeTypePage";
import EntAddPage from "pages/EntAddPage/EntAddPage";
import EntListPage from "pages/EntListPage/EntListPage";
import TeamMemberPage from "pages/TeamMemberPage/TeamMemberPage";
import SalaryPage from "pages/SalaryPage/SalaryPage";
import SettingPage from "pages/SettingPage/SettingPage";
import ReportPage from "pages/ReportPage/ReportPage";
import TablePlanPage from "pages/TablePlanPage/TablePlanPage";
import TablePlanListPage from "pages/TablePlanListPage/TablePlanListPage";
import U_MainCategoryPage from "pages/UserSiteItemCategoryPage/U_MainCategoryPage/U_MainCategoryPage";
import U_SubCategoryPage from "pages/UserSiteItemCategoryPage/U_SubCategoryPage/U_SubCategoryPage";
import U_ItemMainSubListPage from "pages/UserSiteItemCategoryPage/U_ItemMainSubListPage/U_ItemMainSubListPage";
import YieldRuleListPage from "pages/YieldRuleListPage/YieldRuleListPage";
import UsersPage from "pages/UsersPage/UsersPage";
import AddItemPage from "pages/UserSiteItemCategoryPage/U_ItemMainSubListPage/AddItemPage/AddItemPage";
import CostPage from "pages/CostPage/CostPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ChangeSize({ width: screenSize.width, height: screenSize.height }))
  }, [screenSize]);
  const userDataStore = useSelector(x => x.userDataStore);
  const userInfo = userDataStore.user;
  return (
    <div>
      <Switch>
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <Route exact path={"/"} component={LoginPage} />
        <Route path={ROUTE_REGISTER} component={RegisterPage} />
        <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordPage} />

        <SidebarLayout>
          <TopbarLayout>
            <MainLayout>
              <Switch>
                <PrivateRoute
                  exact
                  path={ROUTE_DASHBOARD}
                  component={DashboardPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_INVENTORY}
                  component={InventoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_CATEGORY}
                  component={CategoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_LOCATION}
                  component={LocationPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_INVENTORY_NAME}
                  component={InventoryGroupByName}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_YIELD_RULE}
                  component={YieldRulePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_YIELD_RULE_EDIT + "/:" + "id"}
                  component={YieldRulePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_YIELD_RULE_LIST}
                  component={YieldRuleListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_INGREDIENT_LIST}
                  component={IngredientListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_RECIPE_ADD}
                  component={RecipeAddPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_RECIPE_EDIT + "/:" + "id"}
                  component={RecipeAddPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_RECIPE_LIST}
                  component={RecipeListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_ORDER}
                  component={OrderPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_UNIT}
                  component={UnitPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_PAY_HISTORY}
                  component={PayHistoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_PROFILE}
                  component={UserProfilePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SPOILAGE_ADD}
                  component={SpoilageAddPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SPOILAGE_LIST}
                  component={SpoilageListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SALABLE_TYPE}
                  component={SalableTypePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_ENT_ADD}
                  component={EntAddPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_ENT_LIST}
                  component={EntListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_TEAM_MEMBER_LIST}
                  component={TeamMemberPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SALARY}
                  component={SalaryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SETTING}
                  component={SettingPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_REPORT}
                  component={ReportPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_COST}
                  component={CostPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_TABLE_PLAN_LIST}
                  component={TablePlanListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_TABLE_PLAN + "/:" + "id"}
                  component={TablePlanPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_ITEM_MAIN_CATEGORY}
                  component={U_MainCategoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_ITEM_SUB_CATEGORY + "/:" + "main_category_id"}
                  component={U_SubCategoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_MAIN_SUB_ITEM_LIST + "/:" + "main_category_id" + "/:" + "sub_category_id"}
                  component={U_ItemMainSubListPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_MAIN_SUB_ITEM_ADD + "/:" + "main_category_id" + "/:" + "sub_category_id"}
                  component={AddItemPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_MAIN_SUB_ITEM_EDIT + "/:" + "edit_id"}
                  component={AddItemPage}
                />
                {userInfo !== undefined && userInfo.role === "1" && <PrivateRoute
                  exact
                  path={ROUTE_USERS}
                  component={UsersPage}
                />}

                {/* List a generic 404-Not Found route here */}
                <Route path="*" component={NotFound} />
              </Switch>
            </MainLayout>
          </TopbarLayout>
        </SidebarLayout>


      </Switch>

    </div>
  );
};




// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
