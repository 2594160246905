import { Select, Row, Col, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { isEmpty } from "utils/GlobalFunctions";
import "./EditInventoryInfoDialog";
import ProductImageWidget from "./ProductImageWidget";
const { Option } = Select;

function EditInventoryInfoDialog(props) {
  const { proceed, show } = props;
  const { selInfo, salableTypeList, isEditing } = props;
  const [newUnit, setNewUnit] = useState({});
  const [errorField, setErrorField] = useState([]);
  const { isEdit, title } = props;
  const [sellPrice, setSellPrice] = useState({});
  useEffect(() => {
    if (selInfo !== undefined) {
      setNewUnit(selInfo)
      if (selInfo["sell_price_percentage"] != 0) {
        sellPrice["sell_price"] = parseFloat(100 * selInfo["average_price_per_unit"] / selInfo["sell_price_percentage"]).toFixed(3)
        setSellPrice(sellPrice)
      }
    }
  }, [selInfo])

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(newUnit, "name", errorList);
    errorList = isEmpty(newUnit, "average_price_per_unit", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onOKAction = async () => {
    if (isEditing === true && (await validateFields()) === false) return;
    proceed({
      ...newUnit,
    });
  }

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-unit-new-dialog"
      onOk={onOKAction}
    >
      <div>Inventory Image*</div>
      <ProductImageWidget
        field={"thumbnail_image"}
        productInfo={newUnit}
        setProductInfo={setNewUnit}
        errorField={errorField}
        setErrorField={setErrorField}
      />
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Name*</div>
          <ProductDescriptionInput
            placeholder="Description"
            field={"name"}
            productInfo={newUnit}
            setProductInfo={setNewUnit}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Cost Per Unit*</div>
          <ProductInputNumber
            field={"average_price_per_unit"}
            productInfo={newUnit}
            setProductInfo={setNewUnit}
            errorField={errorField}
            setErrorField={setErrorField}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChangeHandle={(value) => {
              var data = parseFloat(100 * value / newUnit["sell_price_percentage"]).toFixed(3)
              if (value == 0 || value == "") {
                data = ""
              }
              sellPrice["sell_price"] = data
              setSellPrice(sellPrice)
            }}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Salable*</div>
          <Select defaultValue="0" style={{
            width: "100%",
            border: "1px solid #d9d9d9",
          }}
            value={newUnit["salable"]}
            onChange={(value) => {
              newUnit["salable"] = value
              setNewUnit({ ...newUnit })
            }}
          >
            <Option value="1">Yes</Option>
            <Option value="0">No</Option>
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Sellable Type*</div>
          <ProductTypeSelector
            placeholder={"Select Sellable Type"}
            field={"salable_type_id"}
            list={salableTypeList}
            displayField="name"
            idField="id"
            productInfo={newUnit}
            setProductInfo={setNewUnit}
          />
        </Col>

      </Row>
      <Row align={"middle"} gutter={16} style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Selling Price Percentage(%)</div>
          <ProductInputNumber
            field={"sell_price_percentage"}
            productInfo={newUnit}
            setProductInfo={setNewUnit}
            min={0}
            onChangeHandle={(value) => {
              var data = parseFloat(100 * newUnit["average_price_per_unit"] / value).toFixed(3)
              if (value == 0 || value == "") {
                data = ""
              }
              sellPrice["sell_price"] = data
              setSellPrice(sellPrice)
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} style={{ marginTop: 10 }}>
          <div>Cost*  </div>
          <span style={{ color: "var(--orangeColor)" }}> $ {newUnit["average_price_per_unit"]}</span>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} style={{ marginTop: 10 }}>
          <div>Selling Price*  </div>
          <ProductInputNumber
            field={"sell_price"}
            productInfo={sellPrice}
            setProductInfo={setSellPrice}
            min={0}
            onChangeHandle={(value) => {
              var data = parseFloat(100 * newUnit["average_price_per_unit"] / value).toFixed(3)
              if (value == 0 || value == "") {
                data = ""
              }
              newUnit["sell_price_percentage"] = data
              setNewUnit(newUnit)
            }}
          />
          {/* <span style={{ color: "var(--orangeColor)" }}> $ {parseFloat().toFixed(3)}</span> */}
        </Col>
      </Row>
    </Modal>
  );
}

export default confirmable(EditInventoryInfoDialog);
