import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, Image } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, onDeleteAction, onUpdateAction } = props;

    function createMarkup(html) {
        return { __html: html };
    }

    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Name",
            dataIndex: "name",
            align: "center",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Avatar",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.avatar_image === null) {
                    return (
                        <img className="recipe-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <Image className="recipe-thumbnail-img"
                            src={record.thumbnail_image}
                        />
                        // <img className="recipe-thumbnail-img" src={record.thumbnail_image} />
                    )
                }

            },
        },
        
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a className="edit-btn"
                            onClick={() => {
                                onUpdateAction(record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Cacel Confirmation", content: "Are you sure to cancel this order?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
