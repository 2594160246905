import React from "react";
import { Layer } from "react-konva";
import ShapeItem from "./ShapeItem/ShapeItem";
const LayerShapeList = (props) => {
  const { shapeList, setShapeList } = props;
  const { selectedShape, setSelectedShape, onDetailShape } = props;

  const handleDragStart = (e) => {
    const id = e.target.id();
    setShapeList(
      shapeList.map((star) => {
        return {
          ...star,
          isDragging: star.id === id,
        };
      })
    );
  };


  const handleDragEnd = (e) => {
    const id = e.target.id();
    var storeIndex = shapeList.findIndex(x => x.id == id);
    var shape = shapeList[storeIndex]
    shape["x"] = e.target.x()
    shape["y"] = e.target.y()
    shapeList.splice(storeIndex, 1, shape);
    setShapeList([...shapeList]);

    setShapeList(
      shapeList.map((star) => {
        return {
          ...star,
          isDragging: false,
        };
      })
    );
  };

  const onTransformEnd = (e, node) => {
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    var rotation = node.rotation();
    
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);

    const id = e.target.id();
    var storeIndex = shapeList.findIndex(x => x.id == id);
    var shape = shapeList[storeIndex]
    shape["x"] = node.x()
    shape["y"] = node.y()
    shape["rotation"] = rotation
    shape["width"] = Math.max(5, node.width() * scaleX)
    shape["height"] = Math.max(node.height() * scaleY)

    shapeList.splice(storeIndex, 1, shape);
    setShapeList([...shapeList]);

    setShapeList(
      shapeList.map((star) => {
        return {
          ...star,
          isDragging: false,
        };
      })
    );
  };

  const onDeleteShape = (id) => {
    setShapeList(shapeList.filter((x) => x.id != id));
  };


  return (
    <Layer>
      {shapeList.map((detail, index) => {
        return (
          <ShapeItem
            key={detail["id"]}
            detail={detail}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            selectedShape={selectedShape}
            setSelectedShape={setSelectedShape}
            onDeleteShape={onDeleteShape}
            onDetailShape={onDetailShape}
            onTransformEnd={onTransformEnd}
          />
        );
      })}
    </Layer>
  );
};

export default LayerShapeList;
