import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import {  urlGenerateReport, urlSalesAnalysis, urlInventoryReport } from "./CONSTANTS";


export function apiGenerateReport(data) {
  const url = urlGenerateReport;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("start_date", data.start_date);
  formData.append("end_date", data.end_date);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiSalesAnalysis(data) {
  const url = urlSalesAnalysis;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("start_date", data.start_date);
  formData.append("end_date", data.end_date);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiInventoryReport(data) {
  const url = urlInventoryReport;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("start_date", data.start_date);
  formData.append("end_date", data.end_date);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}