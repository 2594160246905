import { Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateInventoryUnit, apiUpdateIventoryUnit } from "services/unitService";
import "./AddExtraGroupDialog.css";
const { TextArea } = Input;

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Date.now())
}


function AddExtraGroupDialog(props) {
  const { proceed, show } = props;
  const { selInfo} = props;
  const [newUnit, setNewUnit] = useState({});
  const { isEdit, title } = props;

  useEffect(()=>{
    if(selInfo !== undefined){
      setNewUnit(selInfo)
    }
  }, [selInfo])
  
  const onOKAction = () => {
    if (newUnit.name == ""){
      message.error(`Please enter valid name`);
      return;
    }
    const newValue = newUnit.name.trim();
    if (isEdit) {
      const optionList = newUnit.optionList;
      proceed({optionList:optionList, name: newValue, value: false, id: randomIntFromInterval(1,100)});
    } else {
      proceed({optionList:[], name: newValue, value: false, id: randomIntFromInterval(1,100)});
    }
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-unit-new-dialog"
      onOk={onOKAction}
    >
      <div>Please Enter extra group name.</div>
      <div style={{ marginTop: 10 }}>name *</div>
      <Input
        value={newUnit.name === undefined ? "":newUnit.name}
        className="normal-input"
        onChange={(e) =>
          setNewUnit({ ...newUnit, name: e.target.value })
        }
      />
    </Modal>
  );
}

export default confirmable(AddExtraGroupDialog);
