import { message, Spin, DatePicker, Row, Col  } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import TableWidget from './TableWidget/TableWidget'
import "./IngredientListPage.css"
import { apiGetIngredient } from 'services/yieldRuleService';
const { RangePicker } = DatePicker;


function IngredientListPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const [ingredientBasicData, setIngredientBasicData] = useState({});
    const [ownCommonData, setOwnCommonData] = useState({});

    useEffect(() => {
        setIsBusy(true);
        apiGetIngredient()
            .then((data) => {
                setIsBusy(false);
                setIngredientBasicData(data);
            })
            .catch((err) => {
                setIsBusy(false);
                // message.error("Server failed. " + err);
            });
    }, [])

    useEffect(() => {
        setIsBusy(true);
        prepareData();
        setIsBusy(false);
    }, [ingredientBasicData])
    const prepareData = () => {
        const { ingredientList } = ingredientBasicData === undefined ? { ingredientList: undefined } : ingredientBasicData;
        if (ingredientBasicData != undefined && ingredientBasicData.ingredientList !== undefined) {
            setOwnCommonData({
                ingredientList: ingredientList,
            })
            setDataList(ingredientList)
            setSearchList(ingredientList)
        }
    }
    // const prepareData = () => {
    //     const { invNameList } = ingredientBasicData === undefined ? { invNameList: undefined } : ingredientBasicData;
    //     const { invUnitList } = ingredientBasicData === undefined ? { invUnitList: undefined } : ingredientBasicData;
    //     const { yieldNameList } = ingredientBasicData === undefined ? { yieldNameList: undefined } : ingredientBasicData;
    //     const { yieldRuleList } = ingredientBasicData === undefined ? { yieldRuleList: undefined } : ingredientBasicData;
    //     if (ingredientBasicData != undefined && ingredientBasicData.yieldRuleList !== undefined) {

    //         let ingredientList = yieldNameList.concat(invNameList.filter(item => item.has_yield === "0"))
    //         for (let i = 0; i < ingredientList.length; i++) {
    //             ingredientList[i]["virtual_id"] = i
    //             let ingredient_item = ingredientList[i]
    //             let subItemList = []
    //             let total_qty = 0
    //             if (ingredient_item.hasOwnProperty("has_yield") == true) {
    //                 //this is inventory name
    //                 let ruleListFilter = yieldRuleList.filter(e => e.reference_inv_name_id === ingredient_item.id)

    //                 for (let j = 0; j < ruleListFilter.length; j++) {
    //                     let ruleListFilter_item = ruleListFilter[j]
    //                     let invName_item = invNameList.filter(e => e.id === ruleListFilter_item.inv_name_id)[0]
    //                     if (invName_item === null || invName_item === undefined) {
    //                         message.error("Sorry! Inventory Name is NULL. check this yield rule ID " + ruleListFilter_item.id)
    //                     }
    //                     else {
    //                         let yield_qty = parseFloat(parseFloat(invName_item.qty) * parseFloat(ruleListFilter_item.yield_test) / 100).toFixed(2)
    //                         total_qty = parseFloat(total_qty) + parseFloat(yield_qty)
    //                         let tmp = {
    //                             virtual_id: j,
    //                             yield_name: ingredient_item.name,
    //                             yield_qty: yield_qty,
    //                             yield_test: ruleListFilter_item.yield_test,
    //                             inv_name: invName_item.name,
    //                             inv_qty: invName_item.qty,
    //                             inv_average_price_per_unit: invName_item.average_price_per_unit,
    //                             rule_add_timestamp: ruleListFilter_item.add_timestamp,
    //                             update_timestamp: ruleListFilter_item.update_timestamp,
    //                             yield_rule_id: ruleListFilter_item.id,
    //                             inv_name_id: ruleListFilter_item.inv_name_id,
    //                             yield_name_id: ruleListFilter_item.yield_name_id,
    //                             reference_inv_name_id: ruleListFilter_item.reference_inv_name_id,
    //                         }
    //                         subItemList = [...subItemList, tmp]
    //                     }
    //                 }

    //                 // add inventory name info
    //                 total_qty = parseFloat(total_qty) + parseFloat(ingredient_item.qty)
    //                 let tmp = {
    //                     virtual_id: ruleListFilter.length,
    //                     yield_name: ingredient_item.name,
    //                     yield_qty: ingredient_item.qty,
    //                     yield_test: "100",
    //                     inv_name: ingredient_item.name,
    //                     inv_qty: ingredient_item.qty,
    //                     inv_average_price_per_unit: ingredient_item.average_price_per_unit,
    //                     rule_add_timestamp: ingredient_item.add_timestamp,
    //                     update_timestamp: ingredient_item.update_timestamp,
    //                     yield_rule_id: "-1",
    //                     inv_name_id: ingredient_item.id,
    //                     yield_name_id: "-1",
    //                     reference_inv_name_id: "-1",
    //                 }
    //                 subItemList = [...subItemList, tmp]

    //             }
    //             else {
    //                 //this is yield name
    //                 let ruleListFilter = yieldRuleList.filter(e => e.yield_name_id === ingredient_item.id)

    //                 for (let j = 0; j < ruleListFilter.length; j++) {
    //                     let ruleListFilter_item = ruleListFilter[j]
    //                     let invName_item = invNameList.filter(e => e.id === ruleListFilter_item.inv_name_id)[0]
    //                     if (invName_item === null || invName_item === undefined) {
    //                         message.error("Sorry! Inventory Name is NULL. check this yield rule ID " + ruleListFilter_item.id)
    //                     }
    //                     else {
    //                         let yield_qty = parseFloat(parseFloat(invName_item.qty) * parseFloat(ruleListFilter_item.yield_test) / 100).toFixed(2)
    //                         total_qty = parseFloat(total_qty) + parseFloat(yield_qty)
    //                         let tmp = {
    //                             virtual_id: j,
    //                             yield_name: ingredient_item.name,
    //                             yield_qty: yield_qty,
    //                             yield_test: ruleListFilter_item.yield_test,
    //                             inv_name: invName_item.name,
    //                             inv_qty: invName_item.qty,
    //                             inv_average_price_per_unit: invName_item.average_price_per_unit,
    //                             rule_add_timestamp: ruleListFilter_item.add_timestamp,
    //                             update_timestamp: ruleListFilter_item.update_timestamp,
    //                             yield_rule_id: ruleListFilter_item.id,
    //                             inv_name_id: ruleListFilter_item.inv_name_id,
    //                             yield_name_id: ruleListFilter_item.yield_name_id,
    //                             reference_inv_name_id: ruleListFilter_item.reference_inv_name_id,
    //                         }
    //                         subItemList = [...subItemList, tmp]
    //                     }
    //                 }
    //             }
    //             ingredientList[i]["subItemList"] = subItemList
    //             ingredientList[i]["count"] = subItemList.length
    //             ingredientList[i]["total_qty"] = parseFloat(total_qty).toFixed(2)
    //         }
    //         setOwnCommonData({
    //             invNameList: invNameList,
    //             invUnitList: invUnitList,
    //             yieldNameList: yieldNameList,
    //             yieldRuleList: yieldRuleList,
    //         })
    //         setDataList(ingredientList)
    //         setSearchList(ingredientList)
    //     }
    // }

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {

    }
    const onClickEdit = async (pInfo) => {

    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                            <RangePicker size={'default'}
                                onChange={(e, date) => {
                                    if (date == null) {
                                        handleDateFilter("", "")
                                    } else {
                                        handleDateFilter(date[0], date[1])
                                    }

                                }} />
                        </div>
                    </Col>
                    <Col flex={'auto'}>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                            <TitleSearch
                                onSearch={handleSearch}
                                onChange={handleChange}
                                onPressEnter={handleSearch}
                            />
                        </div>
                    </Col>
                </Row>
                <div >
                    <TableWidget
                        dataList={searchList}
                        onUpdateAction={onClickEdit}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
}

export default IngredientListPage
