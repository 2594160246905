import { axiosGet, axiosPost } from "./ajaxServices";
import { urlGetDashboardDetail, } from "./CONSTANTS";

export const apiGetDashboardDetail = () => {
  const url = urlGetDashboardDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
