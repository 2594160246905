import { Button, Spin, Row, Col, Tooltip, message, Checkbox } from 'antd'
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import React, { useEffect, useState } from 'react'
import RecipeAddTableWidget from './TableWidget/RecipeAddTableWidget'
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./RecipeAddpage.css"
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput'
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector'
import { isEmpty } from 'utils/GlobalFunctions'
import { apiCreateRecipe, apiGetRecipeBasicData, apiGetRecipeById, apiUpdateRecipe } from 'services/recipeService'
import { useParams } from 'react-router-dom'
import { urlCreateInventoryUnit, urlCreateRecipeCategory, urlCreateRecipeLocation } from 'services/CONSTANTS'
import { showSelectIngredientDialog } from './SelectIngredientDialog/showSelectIngredientDialog'
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber'
import { showAddBaseUnitDialog } from 'pages/InventoryGroup/AddInventoryDialog/AddBaseUnitDialog/showAddBaseUnitDialog'
import ProductImageWidget from 'pages/InventoryGroup/AddInventoryDialog/ProductImageWidget'

function RecipeAddPage() {
    const { id } = useParams();
    const [isBusy, setIsBusy] = useState(false);
    const [guideList, setGuideList] = useState([]);
    const [recipeDescription, setRecipeDescription] = useState('');
    const [recipeInstruction, setRecipeInstruction] = useState('');

    const [recipeBasicData, setRecipeBasicData] = useState({});
    const [ownCommonData, setOwnCommonData] = useState({});
    const [selRecipe, setSelRecipe] = useState({});
    const [errorField, setErrorField] = useState([]);
    const [isEdit, setIsEdit] = useState(false)
    const [checkedList, setCheckedList] = useState([]);
    const [selIngredient, setSelIngredient] = useState({});
    const [sellPrice, setSellPrice] = useState({});

    useEffect(() => {
        if (id !== undefined) {
            setIsEdit(true)
        }
        else {
            getData()
            setIsEdit(false)
            setSelRecipe({})
            setGuideList([])
            setRecipeDescription('')
            setRecipeInstruction('')
            setCheckedList([])
        }
    }, [id])
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setIsBusy(true);
        prepareData();
        setIsBusy(false);
    }, [recipeBasicData]);

    const getData = () => {
        apiGetRecipeBasicData(id)
            .then((data) => {
                setRecipeBasicData(data);
            })
            .catch((err) => {
                //  message.error("Server failed. " + err);
            });
    }
    const prepareData = () => {
        const { categoryList } = recipeBasicData === undefined ? { categoryList: undefined } : recipeBasicData;
        const { unitList } = recipeBasicData === undefined ? { unitList: undefined } : recipeBasicData;
        const { locationList } = recipeBasicData === undefined ? { locationList: undefined } : recipeBasicData;
        const { subRecipeList } = recipeBasicData === undefined ? { subRecipeList: undefined } : recipeBasicData;
        const { ingredientList } = recipeBasicData === undefined ? { ingredientList: undefined } : recipeBasicData;
        const { unitConvertGlobalList } = recipeBasicData === undefined ? { unitConvertGlobalList: undefined } : recipeBasicData;
        const { unitConvertCustomList } = recipeBasicData === undefined ? { unitConvertCustomList: undefined } : recipeBasicData;
        const { salableTypeList } = recipeBasicData === undefined ? { salableTypeList: undefined } : recipeBasicData;
        if (recipeBasicData != undefined && recipeBasicData.ingredientList !== undefined) {

            let tmp_ingredientList = ingredientList
            //merge inventory and yield list
            for (let i = 0; i < tmp_ingredientList.length; i++) {
                tmp_ingredientList[i]["virtual_id"] = i
                tmp_ingredientList[i]["total_qty"] = tmp_ingredientList[i]["qty"]
                tmp_ingredientList[i]["subItemList"] = [{
                    add_timestamp: tmp_ingredientList[i]["add_timestamp"],
                    update_timestamp: tmp_ingredientList[i]["update_timestamp"],
                    average_price_per_unit: tmp_ingredientList[i]["average_price_per_unit"],
                    id: tmp_ingredientList[i]["id"],
                    inv_name_id: tmp_ingredientList[i]["inv_name_id"],
                    name: tmp_ingredientList[i]["name"],
                    qty: tmp_ingredientList[i]["qty"],
                    spoilage: tmp_ingredientList[i]["spoilage"],
                    status: tmp_ingredientList[i]["status"],
                    total_qty: tmp_ingredientList[i]["total_qty"],
                    unit_id: tmp_ingredientList[i]["unit_id"],
                    virtual_id: tmp_ingredientList[i]["virtual_id"],
                    yield_name_id: tmp_ingredientList[i]["yield_name_id"],
                }]
            }
            tmp_ingredientList = tmp_ingredientList.concat(subRecipeList)
            //merge ingredientList and subRecipeList
            for (let i = tmp_ingredientList.length - subRecipeList.length; i < tmp_ingredientList.length; i++) {
                tmp_ingredientList[i]["virtual_id"] = i
            }
            setOwnCommonData({
                locationList: locationList,
                unitList: unitList,
                categoryList: categoryList,
                subRecipeList: subRecipeList,
                ingredientList: tmp_ingredientList,
                unitConvertGlobalList: unitConvertGlobalList,
                unitConvertCustomList: unitConvertCustomList,
                salableTypeList: salableTypeList,
            })
            if (id !== undefined) {
                apiGetRecipeById(id)
                    .then((res) => {
                        console.log("res:", res)
                        prepareRecipeData(res, recipeBasicData)
                    })
                    .catch((err) => {
                        message.error("apiGetRecipeById. " + err);
                    });
            }
            else {
                let tmp = []
                setGuideList(tmp)
            }
        }
    }
    const prepareRecipeData = (data, ownData) => {

        let guideList = data.guideList

        if (data["sell_price_percentage"] != 0) {
            let ret = 0
            for (let i = 0; i < guideList.length; i++) {
                ret = ret + parseFloat(guideList[i]["total_price"])
            }
            sellPrice["sell_price"] = parseFloat(100 * ret / data["sell_price_percentage"]).toFixed(3)
            setSellPrice(sellPrice)
        }


        for (let j = 0; j < guideList.length; j++) {
            if (guideList[j]["sub_recipe_id"]) {
                guideList[j]["ingredient"] = { virtual_id: 100 + j, name: guideList[j]["name"], unit_id: guideList[j]["unit_id"], sub_recipe: 1 }
            }
            else {
                guideList[j]["ingredient"] = { virtual_id: 100 + j, name: guideList[j]["name"], unit_id: guideList[j]["unit_id"] }
            }
        }
        if (data.is_checked_fixed_sell_price === "1") {
            data["is_checked_fixed_sell_price"] = true
        } else {
            data["is_checked_fixed_sell_price"] = false
        }
        setSelRecipe(data)
        setGuideList(data.guideList)
        setRecipeDescription(data.description)
        setRecipeInstruction(data.instruction)
        setCheckedList(defaultCheckBoxValue(data))
    }

    const validateFields = async () => {
        var errorList = Array();
        // if (selRecipe === undefined)
        //     errorList = isEmpty(selRecipe, "active", errorList);
        // errorList = isEmpty(selRecipe, "sub_recipe", errorList);
        // errorList = isEmpty(selRecipe, "salable", errorList);
        errorList = isEmpty(selRecipe, "name", errorList);
        errorList = isEmpty(selRecipe, "author", errorList);
        errorList = isEmpty(selRecipe, "image_name", errorList);
        errorList = isEmpty(selRecipe, "location_id", errorList);
        errorList = isEmpty(selRecipe, "category_id", errorList);
        errorList = isEmpty(selRecipe, "unit_id", errorList);
        errorList = isEmpty(selRecipe, "qty", errorList);
        errorList = isEmpty(selRecipe, "sell_price_percentage", errorList);
        errorList = isEmpty(selRecipe, "salable_type_id", errorList);
        setErrorField([...errorList]);
        return errorList.length == 0;
    };

    const getSelectedItemUnit = () => {
        if (ownCommonData.unitList === undefined) return "--"
        let selectedItem = selIngredient["ingredient"]
        if (selectedItem === undefined) return "--"
        let data = ownCommonData.unitList.filter(e => e.id === selIngredient.unit_id)[0]
        return data === undefined ? "--" : data.name
    }
    const handleAddIngredient = () => {
        if (selIngredient === undefined || selIngredient["ingredient"] === undefined) {
            message.error("Sorry!. Please Select Any Ingredient.")
            return
        }
        let tmpList = [...guideList]
        let tmp = selIngredient;
        tmp["ingredient"] = { ...selIngredient["ingredient"] }
        tmp["dataList"] = selIngredient["dataList"]
        tmp["total_enter_qty"] = selIngredient["total_enter_qty"]
        tmp["rate"] = selIngredient["rate"]
        tmp["unit_id"] = selIngredient["unit_id"]
        tmp["total_price"] = selIngredient["total_price"]
        tmpList = [...tmpList, tmp]
        setGuideList(tmpList)
        setSelIngredient({})
    };

    const handleDelete = (record) => {
        let updateData = [...guideList];
        var storeIndex = updateData.findIndex(x => x["ingredient"].virtual_id == record["ingredient"].virtual_id);
        updateData.splice(storeIndex, 1);
        setGuideList(updateData)
    }

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ]
    }

    const options = [
        { label: 'Active', value: 'active' },
        { label: 'Sub Recipe', value: 'sub_recipe' },
        { label: 'Salable', value: 'salable' },
    ];

    function onChange(checkedValues) {
        selRecipe["active"] = "0"
        selRecipe["sub_recipe"] = "0"
        selRecipe["salable"] = "0"
        checkedValues.forEach(element => {
            selRecipe[element] = "1"
        });
        setCheckedList(defaultCheckBoxValue(selRecipe))
    }

    const onCheckBoxFixedPrice = (e) => {
        setSelRecipe({ ...selRecipe, is_checked_fixed_sell_price: e.target.checked })
    };

    const handleCreateRecipe = async () => {


        selRecipe["description"] = recipeDescription
        selRecipe["instruction"] = recipeInstruction
        selRecipe["guideList"] = guideList

        if ((await validateFields()) === false) return;
        setIsBusy(true);

        apiCreateRecipe(selRecipe)
            .then((data) => {

                setIsBusy(false);
                message.success(`New Recipe has been created.`);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. apiCreateRecipe " + err);
            });
    }

    const handleUpdateRecipe = async () => {

        selRecipe["description"] = recipeDescription
        selRecipe["instruction"] = recipeInstruction
        selRecipe["guideList"] = guideList

        setIsBusy(true);
        apiUpdateRecipe(selRecipe)
            .then((data) => {

                setIsBusy(false);
                message.success(`Current Recipe has been updated.`);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Server failed. handleUpdateRecipe " + err);
            });
    }
    const createNewRecipeLocation = async (fieldName, fieldTitle, createUrl) => {
        var res = await showAddBaseUnitDialog({
            url: createUrl,
            unitName: fieldTitle,
            oldList: ownCommonData[fieldName],
        });
        if (res == null) return;

        var tmpData = ownCommonData;
        let updateData = [...tmpData[fieldName]];

        updateData = [res['addedItem'], ...updateData];
        tmpData[fieldName] = [...updateData];
        setOwnCommonData({ ...tmpData });
        message.success(`${fieldTitle} has been created.`);
    };
    const createNewRecipeCategory = async (fieldName, fieldTitle, createUrl) => {
        var res = await showAddBaseUnitDialog({
            url: createUrl,
            unitName: fieldTitle,
            oldList: ownCommonData[fieldName],
        });
        if (res == null) return;

        var tmpData = ownCommonData;
        let updateData = [...tmpData[fieldName]];

        updateData = [res['addedItem'], ...updateData];
        tmpData[fieldName] = [...updateData];
        setOwnCommonData({ ...tmpData });
        message.success(`${fieldTitle} has been created.`);
    };
    const selectIngredientOne = async () => {
        var res = await showSelectIngredientDialog({
            ownCommonData: ownCommonData,
            setOwnCommonData: setOwnCommonData
        });
        if (res == null) return;
        setSelIngredient(res.addedItem)
    }
    const getTotalCost = () => {
        let ret = 0
        for (let i = 0; i < guideList.length; i++) {
            ret = ret + parseFloat(guideList[i]["total_price"])
        }
        return ret
    }

    if (isEdit && selRecipe.active == undefined) return <div />

    function defaultCheckBoxValue(data) {
        var tmp = []

        if (data.active == "1") {
            tmp.push('active')
        }
        if (data.sub_recipe == "1") {
            tmp.push('sub_recipe')
        }
        if (data.salable == "1") {
            tmp.push('salable')
        }
        return tmp;
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h1>
                                {isEdit ? "Edit Recipe" : "Create New Recipe"}
                            </h1>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row gutter={16} justify="end">
                                <CommonButton
                                    customClass={"product-upload-basic-unit-add-button"}
                                    onClick={() => {
                                        isEdit ? handleUpdateRecipe() : handleCreateRecipe()
                                    }}>
                                    {isEdit ? "Save" : "Save"}
                                </CommonButton>
                            </Row>
                        </Col>

                    </Row>


                    <Row gutter={16}>

                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <h1 style={{ marginTop: 8 }}>Recipe logo* </h1>
                                    <ProductImageWidget
                                        id={"image_recipe"}
                                        field={"image_name"}
                                        productInfo={selRecipe}
                                        setProductInfo={setSelRecipe}
                                        errorField={errorField}
                                        setErrorField={setErrorField}
                                    />
                                    <Col flex={"auto"} align={"middle"} >
                                        <Checkbox.Group options={options}
                                            value={checkedList}
                                            onChange={onChange} />
                                    </Col>
                                    <Col style={{ marginTop: 9 }}>
                                        <div>Sellable Type* </div>
                                        <ProductTypeSelector
                                            placeholder={"Select Sellable Type Info"}
                                            field={"salable_type_id"}
                                            list={ownCommonData.salableTypeList}
                                            displayField="name"
                                            idField="id"
                                            productInfo={selRecipe}
                                            setProductInfo={setSelRecipe}
                                            errorField={errorField}
                                            setErrorField={setErrorField}
                                        />
                                    </Col>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                                    <Row align={"middle"} gutter={16}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Recipe Name* </div>
                                            <ProductDescriptionInput
                                                placeholder="Name"
                                                field={"name"}
                                                productInfo={selRecipe}
                                                setProductInfo={setSelRecipe}
                                                errorField={errorField}
                                                setErrorField={setErrorField}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Author*</div>
                                            <ProductDescriptionInput
                                                placeholder="Author"
                                                field={"author"}
                                                productInfo={selRecipe}
                                                setProductInfo={setSelRecipe}
                                                errorField={errorField}
                                                setErrorField={setErrorField}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Yield</div>
                                            <ProductInputNumber
                                                field={"qty"}
                                                productInfo={selRecipe}
                                                setProductInfo={setSelRecipe}
                                                errorField={errorField}
                                                setErrorField={setErrorField}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Unit*</div>
                                            <Row align={"middle"} gutter={8}>
                                                <Col xs={18} sm={18} md={18} lg={18} xl={16}>
                                                    <ProductTypeSelector
                                                        placeholder={"Unit"}
                                                        field={"unit_id"}
                                                        list={ownCommonData.unitList}
                                                        displayField="name"
                                                        idField="id"
                                                        disabled={isEdit}
                                                        productInfo={selRecipe}
                                                        setProductInfo={setSelRecipe}
                                                        errorField={errorField}
                                                        setErrorField={setErrorField}
                                                    />
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={8}>
                                                    <Tooltip title={"Click to add new one."}>
                                                        <CommonButton
                                                            customClass={"product-upload-basic-unit-add-button"}
                                                            onClick={() => {
                                                                createNewRecipeCategory("unitList", "Unit Name", urlCreateInventoryUnit);
                                                            }}
                                                        >
                                                            New
                                                        </CommonButton>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Location*</div>
                                            <Row align={"middle"} gutter={8}>
                                                <Col xs={18} sm={18} md={18} lg={18} xl={16}>
                                                    <ProductTypeSelector
                                                        placeholder={" Location"}
                                                        field={"location_id"}
                                                        list={ownCommonData.locationList}
                                                        displayField="name"
                                                        idField="id"
                                                        productInfo={selRecipe}
                                                        setProductInfo={setSelRecipe}
                                                        errorField={errorField}
                                                        setErrorField={setErrorField}
                                                    />
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={8}>
                                                    <Tooltip title={"Click to add new one."}>
                                                        <CommonButton
                                                            customClass={"product-upload-basic-unit-add-button"}
                                                            onClick={() => {
                                                                createNewRecipeLocation("locationList", "Recipe Location Name", urlCreateRecipeLocation);
                                                            }}
                                                        >
                                                            New
                                                        </CommonButton>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                                            <div>Category*</div>
                                            <Row align={"middle"} gutter={8}>
                                                <Col xs={18} sm={18} md={18} lg={18} xl={16}>
                                                    <ProductTypeSelector
                                                        placeholder={"Category"}
                                                        field={"category_id"}
                                                        list={ownCommonData.categoryList}
                                                        displayField="name"
                                                        idField="id"
                                                        productInfo={selRecipe}
                                                        setProductInfo={setSelRecipe}
                                                        errorField={errorField}
                                                        setErrorField={setErrorField}
                                                    />
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={8}>
                                                    <Tooltip title={"Click to add new one."}>
                                                        <CommonButton
                                                            customClass={"product-upload-basic-unit-add-button"}
                                                            onClick={() => {
                                                                createNewRecipeCategory("categoryList", "Recipe Category Name", urlCreateRecipeCategory);
                                                            }}
                                                        >
                                                            New
                                                        </CommonButton>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{ marginTop: 10 }}>
                                            <div>Selling Price Percentage(%)</div>
                                            <ProductInputNumber
                                                field={"sell_price_percentage"}
                                                productInfo={selRecipe}
                                                setProductInfo={setSelRecipe}
                                                errorField={errorField}
                                                setErrorField={setErrorField}
                                                min={0}
                                                onChangeHandle={(value) => {
                                                    var data = parseFloat(100 * getTotalCost() / value).toFixed(3)
                                                    if (value == 0 || value == "") {
                                                        data = ""
                                                    }
                                                    sellPrice["sell_price"] = data
                                                    setSellPrice(sellPrice)
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{ marginTop: 10 }}>
                                            <Row gutter={8} justify="space-between" align='middle'>
                                                <Col md={12}>
                                                    <div>Total Cost*  </div>
                                                    <span style={{ color: "var(--orangeColor)" }}> $ {parseFloat(getTotalCost()).toFixed(5)}</span>
                                                </Col>
                                                <Col md={12}>
                                                    <div>Selling Price*  </div>
                                                    <ProductInputNumber
                                                        field={"sell_price"}
                                                        productInfo={sellPrice}
                                                        setProductInfo={setSellPrice}
                                                        min={0}
                                                        onChangeHandle={(value) => {
                                                            var data = parseFloat(100 * getTotalCost() / value).toFixed(3)
                                                            if (value == 0 || value == "") {
                                                                data = ""
                                                            }
                                                            selRecipe["sell_price_percentage"] = data
                                                            setSelRecipe(selRecipe)
                                                        }}
                                                    />
                                                    {/* <span style={{ color: "var(--orangeColor)" }}> $ {parseFloat(100 * getTotalCost() / selRecipe["sell_price_percentage"]).toFixed(3)}</span> */}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 10 }}>
                                            <Checkbox checked={selRecipe["is_checked_fixed_sell_price"]} onChange={onCheckBoxFixedPrice}>Fixed Selling Price*</Checkbox>
                                            <ProductInputNumber
                                                field={"fixed_sell_price"}
                                                productInfo={selRecipe}
                                                setProductInfo={setSelRecipe}
                                                errorField={errorField}
                                                setErrorField={setErrorField}
                                                min={0}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>




                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={{ marginTop: 10 }}>
                            <ReactQuill theme="snow" value={recipeDescription} onChange={setRecipeDescription}
                                modules={modules}
                            />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 10 }} className="bg-border">
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Tooltip title={"Click to add new one."}>
                                        <CommonButton
                                            customClass={"product-upload-basic-unit-add-button"}
                                            onClick={() => {
                                                selectIngredientOne()
                                            }}
                                        >
                                            Select One
                                        </CommonButton>
                                    </Tooltip>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <div>Ingredient Info*</div>
                                    <span style={{ color: "var(--orangeColor)" }}>{selIngredient !== undefined && selIngredient["ingredient"] !== undefined ? selIngredient["ingredient"].name : "0"}</span>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                    <div>QTY :  &nbsp;</div>
                                    <span style={{ color: "var(--orangeColor)" }}>{selIngredient !== undefined && selIngredient["total_enter_qty"] !== undefined ? selIngredient.total_enter_qty : "0"}</span>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                    <div>Unit :  &nbsp;</div>
                                    <span style={{ color: "var(--orangeColor)" }}> {getSelectedItemUnit()}</span>

                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                    <div>Cost :  </div>
                                    <span style={{ color: "var(--orangeColor)" }}> $ {selIngredient !== undefined && selIngredient["total_price"] !== undefined ? selIngredient.total_price : "0"}</span>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                                    <div>Pre Instruction :  </div>
                                    <ProductDescriptionInput
                                        placeholder="Enter Pre Instruction"
                                        field={"pre_instruction"}
                                        productInfo={selIngredient}
                                        setProductInfo={setSelIngredient}
                                        errorField={errorField}
                                        setErrorField={setErrorField}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                                    <div>Post Instruction :  </div>
                                    <ProductDescriptionInput
                                        placeholder="Enter Post Instruction"
                                        field={"post_instruction"}
                                        productInfo={selIngredient}
                                        setProductInfo={setSelIngredient}
                                        errorField={errorField}
                                        setErrorField={setErrorField}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                                    <div>Procedure :  </div>
                                    <ProductDescriptionInput
                                        placeholder="Enter Procedure"
                                        field={"procedure"}
                                        productInfo={selIngredient}
                                        setProductInfo={setSelIngredient}
                                        errorField={errorField}
                                        setErrorField={setErrorField}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <CommonButton
                                        customClass={"product-upload-basic-unit-add-button"}
                                        onClick={() => {
                                            handleAddIngredient()
                                        }}
                                    >
                                        Add&nbsp;
                                    </CommonButton>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <div style={{ marginTop: 20 }}>
                        <h1>Recipe Guide List</h1>
                    </div>
                    <Row gutter={16} style={{ marginTop: 10 }}>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <RecipeAddTableWidget
                                dataList={guideList}
                                handleDelete={handleDelete}
                                ownCommonData={ownCommonData}
                                errorField={errorField}
                                setErrorField={setErrorField}
                            />
                        </Col>

                    </Row>
                    <div style={{ marginTop: 20 }}>
                        <h1>Instructions for Chef</h1>
                    </div>
                    <Row gutter={16} style={{ marginTop: 10 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <ReactQuill theme="snow" value={recipeInstruction} onChange={setRecipeInstruction}
                                modules={modules}
                            />
                        </Col>

                    </Row>
                </div>
            </Spin>
        </CommonDivWidget>
    )
}

export default RecipeAddPage
