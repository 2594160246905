import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetInventoryList, urlCreateIventory, urlInventoryBasicData, urlUpdateIventory, urlDeleteIventory, urlDeleteIventoryLocation, urlUpdateIventoryLocation, urlCreateInventoryLocation, urlGetInventoryLocation, urlAppendIventory } from "./CONSTANTS";

export const apiGetInventoryList = () => {
  const url = urlGetInventoryList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetInventoryBasicData = () => {
  const url = urlInventoryBasicData;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiCreateInventory(inventoryData) {
  const url = urlCreateIventory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name_id", inventoryData.name_id);
  formData.append("description", inventoryData.description);
  formData.append("category_id", inventoryData.category_id);
  formData.append("location_id", inventoryData.location_id);
  formData.append("category", inventoryData.category);
  formData.append("unit_id", inventoryData.unit_id);
  formData.append("description", inventoryData.description);
  formData.append("qty", inventoryData.qty);
  formData.append("spoilage", inventoryData.spoilage === undefined ? 0 : inventoryData.spoilage);
  formData.append("price_per_unit", inventoryData.price_per_unit);

  
  if (inventoryData.image_name instanceof File) {
    formData.append("image_name", inventoryData.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateInventory(inventoryData) {
  const url = urlUpdateIventory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", inventoryData.id);
  formData.append("name_id", inventoryData.name_id);
  formData.append("description", inventoryData.description);
  formData.append("category_id", inventoryData.category_id);
  formData.append("location_id", inventoryData.location_id);
  formData.append("category", inventoryData.category);
  formData.append("unit_id", inventoryData.unit_id);
  formData.append("description", inventoryData.description);
  formData.append("qty", inventoryData.qty);
  formData.append("spoilage", inventoryData.spoilage);
  formData.append("price_per_unit", inventoryData.price_per_unit);

  if (inventoryData.image_name instanceof File) {
    formData.append("image_name", inventoryData.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiAppendInventory(inventoryData) {
  const url = urlAppendIventory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", inventoryData.id);
  formData.append("qty", inventoryData.qty);
  formData.append("price_per_unit", inventoryData.price_per_unit);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteInventory(id) {
  const url = urlDeleteIventory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}



export const apiGetInventoryLocation = () => {
  const url = urlGetInventoryLocation;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiCreateInventoryLocation(data) {
  const url = urlCreateInventoryLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateIventoryLocation(data) {
  const url = urlUpdateIventoryLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteIventoryLocation(id) {
  const url = urlDeleteIventoryLocation;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}