import { message, Spin, Tabs } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { useEffect } from "react";
import { useState, React } from "react";
import { apiGetOrderList } from "services/orderService";
import ApprovedOrder from "./ApprovedOrder/ApprovedOrder";
import DeliveredOrder from "./DeliveredOrder/DeliveredOrder";
import "./OrderPage.css";
import UpcomingOrder from "./UpcomingOrder/UpcomingOrder";
const { TabPane } = Tabs;

function OrderPage() {
  const [isBusy, setIsBusy] = useState(false);
  const [ownCommonData, setOwnCommonData] = useState({});

  useEffect(() => {
    setIsBusy(true)
    apiGetOrderList()
      .then((data) => {
        setIsBusy(false)
        getOrderData(data)
      })
      .catch((err) => {
        setIsBusy(false)
        message.error("Sorry! " + err);
      });
  }, []);
  const getOrderData = (data) => {
    let upcomingOrderList = data.filter(e => e.is_approved === '0' && e.is_delivered === '0')
    let approvedOrderList = data.filter(e => e.is_approved === '1' && e.is_delivered === '0')
    let deliveredOrderList = data.filter(e => e.is_approved === '1' && e.is_delivered === '1')
    setOwnCommonData({
      upcomingOrderList: upcomingOrderList,
      approvedOrderList: approvedOrderList,
      deliveredOrderList: deliveredOrderList,
    })
  }
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Upcoming Order" key="1">
            <UpcomingOrder
              setIsBusy={setIsBusy}
              ownCommonData={ownCommonData}
              setOwnCommonData={setOwnCommonData}
            />
          </TabPane>
          <TabPane tab="Approved Order" key="2">
            <ApprovedOrder
              setIsBusy={setIsBusy}
              ownCommonData={ownCommonData}
              setOwnCommonData={setOwnCommonData}
            />
          </TabPane>
          <TabPane tab="Delivered Order" key="3">
            <DeliveredOrder
              setIsBusy={setIsBusy}
              ownCommonData={ownCommonData}
              setOwnCommonData={setOwnCommonData}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </CommonDivWidget>
  );
}

export default OrderPage;
