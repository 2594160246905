import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { useEffect } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { apiUpdateUserSiteItemMainSubPosition } from 'services/userSiteItemMainSubService';
import { showAddExtraOptionDialog } from '../ExtraOptionDialog/showAddExtraOptionDialog';
import ExtraOptionTableWidget from '../ExtraOptionTableWidget/ExtraOptionTableWidget';


function ExtraGroupTableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, onUpdateAction, onDeleteAction, onDetailAction } = props;
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (dataList !== undefined) {
            setDataSource([...dataList.map((info) => {
                return { ...info, key: info.id };
            })]);
        }
    }, [dataList]);
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "Group ID",
            dataIndex: "id",
            align: "center",
        },
        {
            title: "Group Name",
            dataIndex: "name",
            align: "center",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Group Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a style={{ color: "var(--orangeColor)", marginLeft: 10 }} className="edit-btn"
                            onClick={async () => {
                                var res = await showAddExtraOptionDialog({
                                    title: "Create Extra Option on " + record.name,
                                    isEdit: false,
                                });
                                if (res === null || res.length == 0) return;
                                
                                let tmp_record = {...record}
                                let tmp_optionList = [...tmp_record["optionList"]]
                                tmp_optionList.push(res)
                                tmp_record["optionList"] = tmp_optionList
                                let updateData = [...dataList]
                                var storeIndex = updateData.findIndex(x => x.id == tmp_record.id);
                                updateData.splice(storeIndex, 1, tmp_record);
                                var tmpData = [...updateData];
                                setDataList(tmpData);
                            }}
                        >
                            Append
                        </a>
                        <a style={{ marginLeft: 20 }}
                            className="edit-btn"
                            onClick={() => {
                                onUpdateAction(record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];


    const onClickExtraOptionDelete = (group, pInfo) => {

        let tmp_record = {...group}
        let tmp_optionList = [...tmp_record["optionList"]]
        var storeIndex = tmp_optionList.findIndex(x => x.id == pInfo.id)
        tmp_optionList.splice(storeIndex, 1)
        tmp_record["optionList"] = [...tmp_optionList]
        let updateData = [...dataList]
        var storeIndex = updateData.findIndex(x => x.id == tmp_record.id);
        updateData.splice(storeIndex, 1, tmp_record);
        var tmpData = [...updateData];
        setDataList(tmpData);
    }

    const onClickExtraOptionEdit = async (group, pInfo) => {
        var res = await showAddExtraOptionDialog({
            title: "Edit Extra Option on " + group.name,
            isEdit: true,
            selInfo: pInfo
        });
        if (res === null || res.length == 0) return;
        let tmp_record = {...group}
        let tmp_optionList = [...tmp_record["optionList"]]
        var storeIndex = tmp_optionList.findIndex(x => x.id == pInfo.id)
        tmp_optionList.splice(storeIndex, 1, res)
        tmp_record["optionList"] = [...tmp_optionList]
        let updateData = [...dataList]
        var storeIndex = updateData.findIndex(x => x.id == tmp_record.id);
        updateData.splice(storeIndex, 1, tmp_record);
        var tmpData = [...updateData];
        setDataList(tmpData);
    }

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    if (record["optionList"].length !== 0) {
                        return <ExtraOptionTableWidget
                            group={record}
                            dataList={record["optionList"]}
                            onUpdateAction={onClickExtraOptionEdit}
                            onDeleteAction={onClickExtraOptionDelete}
                        />
                    }
                    else {
                        return <div className="ant-table-expanded-row-fixed" style={{ width: "1428px", position: 'sticky', left: 0, overflow: 'hidden' }}>
                            <div className="ant-empty ant-empty-normal">
                                <div className="ant-empty-image">
                                    <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                            <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">No Data</div>
                            </div>
                        </div>
                    }

                }}
            />
        </div>
    )
}

export default withRouter(ExtraGroupTableWidget)
