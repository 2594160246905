import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, Divider } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import { showAddNewCategoryDialog } from 'pages/InventoryGroup/AddInventoryDialog/AddNewCategoryDialog/showAddNewCategoryDialog';
import React, { useEffect, useState } from 'react'
import { urlCreateInventoryCategory } from 'services/CONSTANTS';
import { apiDeleteIventoryCategory, apiGetInventoryCategory } from 'services/inventoryCategoryService';
import { apiDeleteRecipeCategory, apiGetRecipeCategory } from 'services/recipeService';
import CategoryListTableWidget from './CategoryListTableWidget/CategoryListTableWidget';
import "./CategoryPage.css"
import { showAddNewCategoryDialogRecipe } from './RecipeCategoryDialog/showAddNewCategoryDialogRecipe';

function CategoryPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [categoryListInv, setCategoryListInv] = useState([]);
    const [searchListInv, setSearchListInv] = useState([]);
    const [categoryListRec, setCategoryListRec] = useState([]);
    const [searchListRec, setSearchListRec] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetInventoryCategory()
            .then((data) => {
                setIsBusy(false);
                setCategoryListInv(data);
                setSearchListInv(data)
            })
            .catch((err) => {
                setIsBusy(false);
                //message.error("Server failed. " + err);
            });

        apiGetRecipeCategory()
        .then((data) => {
            setIsBusy(false);
            setCategoryListRec(data);
            setSearchListRec(data)
        })
        .catch((err) => {
            setIsBusy(false);
            //message.error("Server failed. " + err);
        });
    };

    const createNewCategoryInv = async () => {
        var res = await showAddNewCategoryDialog({
            title: "New Category",
            isEdit: false,
            oldList: categoryListInv,
        });
        if (res == null) return;

        var tmpData = categoryListInv;
        let updateData = [...tmpData];

        updateData.splice(tmpData.length, 0, res['addedItem']);

        tmpData = [...updateData];
        setCategoryListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`new Category has been created.`);
    };

    const onUpdateActionInv = async (info) => {
        var res = await showAddNewCategoryDialog({
            title: "Edit Category",
            isEdit: true,
            oldList: categoryListInv,
            selInfo: info
        });
        if (res == null) return;

        var tmpData = categoryListInv;
        let updateData = [...tmpData];

        var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
        updateData.splice(storeIndex, 1, res['addedItem']);

        tmpData = [...updateData];
        setCategoryListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`Category has been updated.`);
    }
    const onDeleteActionInv = (pInfo) => {
        setIsBusy(true);
        apiDeleteIventoryCategory(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setCategoryListInv(data);
                setSearchListInv(data);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating Category. " + err);
            });
    }

    const handleSearchInv = (searchText) => {
        const tmpList = categoryListInv.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchListInv([...tmpList])
    };
    const handleChangeInv = (event) => {
        if (event.target.value === "") {
            setSearchListInv(categoryListInv)
        }
        else {
            handleSearchInv(event.target.value)
        }
    };


    const createNewCategoryRec = async () => {
        var res = await showAddNewCategoryDialogRecipe({
            title: "New Category",
            isEdit: false,
            oldList: categoryListRec,
        });
        if (res == null) return;

        var tmpData = categoryListRec;
        let updateData = [...tmpData];

        updateData.splice(tmpData.length, 0, res['addedItem']);

        tmpData = [...updateData];
        setCategoryListRec(tmpData);
        setSearchListRec(tmpData)
        message.success(`new Category has been created.`);
    };

    const onUpdateActionRec = async (info) => {
        var res = await showAddNewCategoryDialogRecipe({
            title: "Edit Category",
            isEdit: true,
            oldList: categoryListRec,
            selInfo: info
        });
        if (res == null) return;

        var tmpData = categoryListRec;
        let updateData = [...tmpData];

        var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
        updateData.splice(storeIndex, 1, res['addedItem']);

        tmpData = [...updateData];
        setCategoryListRec(tmpData);
        setSearchListRec(tmpData)
        message.success(`Category has been updated.`);
    }
    const onDeleteActionRec = (pInfo) => {
        setIsBusy(true);
        apiDeleteRecipeCategory(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setCategoryListRec(data);
                setSearchListRec(data);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating Category. " + err);
            });
    }

    const handleSearchRec = (searchText) => {
        const tmpList = categoryListInv.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchListRec([...tmpList])
    };
    const handleChangeRec = (event) => {
        if (event.target.value === "") {
            setSearchListRec(categoryListRec)
        }
        else {
            handleSearchRec(event.target.value)
        }
    };

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Basic Inventory Category List</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={20}>
                                        <TitleSearch
                                            onSearch={handleSearchInv}
                                            onChange={handleChangeInv}
                                            onPressEnter={handleSearchInv}
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewCategoryInv();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <CategoryListTableWidget
                                        categoryList={searchListInv}
                                        onUpdateAction={onUpdateActionInv}
                                        onDeleteAction={onDeleteActionInv}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Recipe Category List</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={20}>
                                        <TitleSearch
                                            onSearch={handleSearchRec}
                                            onChange={handleChangeRec}
                                            onPressEnter={handleSearchRec}
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewCategoryRec();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <CategoryListTableWidget
                                        categoryList={searchListRec}
                                        onUpdateAction={onUpdateActionRec}
                                        onDeleteAction={onDeleteActionRec}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                
                </Row>

            </Spin>
        </CommonDivWidget>
    );
};


export default CategoryPage
