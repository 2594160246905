import { message, Spin, Tabs } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { useEffect } from "react";
import { useState, React } from "react";
import "./CostPage.css";
import InvestmentPage from "./InvestmentPage/InvestmentPage";
import ExpensePage from "./ExpensePage/ExpensePage";

function CostPage() {
  const [isBusy, setIsBusy] = useState(false);

  const tabList = [
    {
      key: "tab1",
      label: "Investment",
      children: <InvestmentPage/>,
    },
    {
      key: "tab2",
      label: "Expense",
      children: <ExpensePage/>,
    },
  ];

  useEffect(() => {
    
  }, []);


  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Tabs defaultActiveKey="tab1" type="card" items={tabList}>
        </Tabs>
      </Spin>
    </CommonDivWidget>
  );
}

export default CostPage;
