import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin, Switch } from "antd";

import "./AddUserDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddUserDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      let tmp = record
      tmp["password"] = ""
      setData({ ...tmp });
    }
  }, [record]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "first_name", errorList);
    errorList = isEmpty(data, "last_name", errorList);
    errorList = isEmpty(data, "email", errorList);
    errorList = isEmpty(data, "password", errorList);
    if (data["image_url"] === undefined && data["image_name"] === undefined) {
      errorList = [...errorList, "image_name"]
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-user-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Avatar*</div>
          <ProductImageWidget
            field={"image_url"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>First Name*</div>
              <ProductDescriptionInput
                placeholder="First Name"
                field={"first_name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Last Name*</div>
              <ProductDescriptionInput
                placeholder="Last Name"
                field={"last_name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Email*</div>
              <ProductDescriptionInput
                placeholder="Email"
                field={"email"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Password*</div>
              <ProductDescriptionInput
                placeholder="Password"
                field={"password"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Account Role*</div>
              <Select defaultValue="0" style={{
                width: "100%",
                border: "1px solid #d9d9d9",
              }}
                value={data["role"]}
                onChange={(value) => {
                  data["role"] = value
                  setData({ ...data })
                }}
              >
                <Option value="0">Chef</Option>
                <Option value="1">Admin</Option>
              </Select>
            </Col>
          </Row>




        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddUserDialog)
