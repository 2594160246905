import React from "react";
import { Tabs, DatePicker, Row, Col } from 'antd';
import "./SpoilageInventory.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
const { RangePicker } = DatePicker;

function SpoilageInventory(props) {
    const { dataList, AddSpoilageAction, ownCommonData} = props;
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        setSearchList(dataList)
    }, [dataList]);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleChangeQtyforEachItem = (record) => {
        let updateData = [...dataList];
        var storeIndex = updateData.findIndex(x => x.id == record.id);
        updateData.splice(storeIndex, 1, record);
        setSearchList(updateData)
    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    return (
        <div>
            <Row>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                        <RangePicker size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }

                            }} />
                    </div>
                </Col>
                <Col flex={'auto'}>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                </Col>
            </Row>
            <div>
                <TableWidget
                    ownCommonData={ownCommonData}
                    handleChangeQtyforEachItem={handleChangeQtyforEachItem}
                    dataList={searchList}
                    setDataList={setSearchList}
                    AddSpoilageAction = {AddSpoilageAction}
                />
            </div>
        </div>
    );
}

export default SpoilageInventory;
