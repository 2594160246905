import { ShapeType } from "config/CONSTANTS";
import React, { useRef } from "react";
import { Circle, Image, Line, Rect, Text, Transformer } from "react-konva";
import moment from "moment"

const ShapeItem = (props) => {
  const { handleDragStart, handleDragEnd, onDeleteShape, onDetailShape, onTransformEnd } = props;
  const { selectedShape, setSelectedShape } = props;
  const { detail } = props;
  const { type } = detail;
  const {
    id,
    x,
    y,
    rotation,
    color,
    text,
    fontSize,
    isDragging,
    points,
    stroke,
    width,
    height,
    image,
    font_family,
  } = detail;
  const isSelected = id == selectedShape;
  const shapeRef = useRef();
  const trRef = useRef();
  var isBusy = false
  let shape = <></>;
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  if (type == ShapeType.TEXT) {
    shape = (
      <Text
        ref={shapeRef}
        id={id}
        text={text}
        x={x}
        y={y}
        rotation={rotation}
        fill={color}
        fontSize={fontSize}
        align={"center"}
        fontFamily={font_family}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        width={width}
        draggable
        // scaleX={isDragging === true ? 1.2 : 1}
        // scaleY={isDragging === true ? 1.2 : 1}
        onDblClick={() => {
          setSelectedShape(id);
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onTransformEnd(e, node)
        }}
      />
    );
  }
  if (type == ShapeType.LINE) {
    shape = (
      <Line
        ref={shapeRef}
        id={id}
        x={x}
        y={y}
        rotation={rotation}
        points={points}
        stroke={color}
        strokeWidth={stroke}
        tension={0.5}
        lineCap="round"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable
        onDblClick={() => {
          setSelectedShape(id);
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onTransformEnd(e, node)
        }}
      />
    );
  }
  if (type == ShapeType.IMAGE) {
    shape = (
      <Image
        ref={shapeRef}
        id={id}
        x={x}
        y={y}
        rotation={rotation}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        width={width == undefined ? null : width}
        height={height == undefined ? null : height}
        image={image}
        draggable
        onDblClick={() => {
          setSelectedShape(id);
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onTransformEnd(e, node)
        }}
      />
    );
  }
  if (type == ShapeType.TABLE) {
    shape = (
      <Image
        ref={shapeRef}
        id={id}
        x={x}
        y={y}
        rotation={rotation}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        width={width == undefined ? null : width}
        height={height == undefined ? null : height}
        image={image}
        draggable
        onDblClick={() => {
          setSelectedShape(id);
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          onTransformEnd(e, node)
        }}
      />
    );
    var now = moment();
    if (detail["status"] == "1" && moment(detail["reservation_start_timestamp"]) <= now && now <= moment(detail["reservation_end_timestamp"])) {
      isBusy = true
    }
  }
  const onClickDelete = () => {
    setSelectedShape(null);
    onDeleteShape(id);
  };
  const onClickDetail = () => {
    onDetailShape(detail);
  };
  return (
    <>
      {shape}
      {isBusy && (
        <Text rotation={detail.rotation}
          text="R" fontSize="70" fill="#ee0000" align="center" x={detail.x}
          y={detail.y} onClick={onClickDetail} />
      )}
      {isSelected && (
        <Transformer
          id={"transformer"}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        >
          <Circle radius={10} fill={"red"} onClick={onClickDelete}></Circle>
          <Text text="X" fill="white" x={-4} y={-4} onClick={onClickDelete} />
          {type == ShapeType.TABLE &&
            <Text text="Detail Info." fill="#ee0000" x={10} y={10} onClick={onClickDetail} />
          }
        </Transformer>
      )}
    </>
  );
};

export default ShapeItem;
