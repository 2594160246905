import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { message, Spin , DatePicker, Row, Col} from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import { useHistory, useParams } from 'react-router-dom';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
import TableWidget from './TableWidget/TableWidget';
import { apiAddUserSiteItemMainSub, apiDeleteUserSiteItemMainSub, apiGetUserSiteItemMainSubList } from 'services/userSiteItemMainSubService';
import { showAddItemDialog } from './AddItemDialog/showAddItemDialog';
import { ROUTE_USER_MAIN_SUB_ITEM_ADD, ROUTE_USER_MAIN_SUB_ITEM_EDIT } from 'navigation/CONSTANTS';
const { RangePicker } = DatePicker;

function U_ItemMainSubListPage() {
    const { main_category_id, sub_category_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        apiGetUserSiteItemMainSubList(main_category_id, sub_category_id)
            .then((data) => {
                setSearchList(data)
                setDataList(data)
            })
            .catch((err) => {
                // message.error("Sorry! " + err);
            });
    }, []);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onAddItem = async () => {
        dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_USER_MAIN_SUB_ITEM_ADD }))
        history.push(ROUTE_USER_MAIN_SUB_ITEM_ADD + "/" + main_category_id + "/" + sub_category_id)
    }
    const onClickDelete = (pInfo) => {
        setIsBusy(true);
        apiDeleteUserSiteItemMainSub(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
                updateData.splice(storeIndex, 1);
                var tmpData = [...updateData];
                setDataList(tmpData);
                setSearchList(tmpData);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const onClickDetail =  (pInfo) => {
       
    }
    const onClickEdit = async (pInfo) => {
        dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_USER_MAIN_SUB_ITEM_EDIT }))
        history.push(ROUTE_USER_MAIN_SUB_ITEM_EDIT + "/" + pInfo.id)
    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <a><u  onClick={()=>{history.goBack()}}>Go back</u></a>
                <Row>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                            <RangePicker size={'default'}
                                onChange={(e, date) => {
                                    if (date == null) {
                                        handleDateFilter("", "")
                                    } else {
                                        handleDateFilter(date[0], date[1])
                                    }

                                }} />
                        </div>
                    </Col>
                    <Col flex={'auto'}>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                            <TitleSearch
                                onSearch={handleSearch}
                                onChange={handleChange}
                                onPressEnter={handleSearch}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                            <CommonButton onClick={() => {
                                onAddItem()
                            }}>Add</CommonButton>
                        </div>
                    </Col>
                </Row>

                <div>
                    <TableWidget
                        dataList={searchList}
                        onDetailAction={onClickDetail}
                        onUpdateAction={onClickEdit}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    )
}

export default U_ItemMainSubListPage
