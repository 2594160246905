import { Button, Spin, Row, Col, Tooltip, message, Checkbox } from 'antd'
import { useEffect, useState } from 'react';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { apiCreateYieldRule, apiGetYieldBasicData, apigetYieldRuleByID, apiGetYieldRuleByInvNameId, apiUpdateYieldRule } from 'services/yieldRuleService';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import "./YieldRulePage.css"
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import YieldRuleTableWidget from './TableWidget/YieldRuleTableWidget';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import { urlCreateYieldName } from 'services/CONSTANTS';
import { isEmpty } from 'utils/GlobalFunctions';
import { showAddYieldNameDialog } from './AddYieldNameDialog/showAddYieldNameDialog';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { axiosPost } from 'services/ajaxServices';
import { useParams } from 'react-router-dom';
function YieldRulePage() {
    const { id } = useParams();

    const [isBusy, setIsBusy] = useState(false);
    const [ownCommonData, setOwnCommonData] = useState({});
    const [selYield, setSelYield] = useState({});
    const [yieldRuleList, setYieldRuleList] = useState([]);
    const [errorField, setErrorField] = useState([]);

    useEffect(() => {
        setIsBusy(true)
        apiGetYieldBasicData()
            .then((data) => {
                setIsBusy(false)
                const { invNameList } = data === undefined ? { invNameList: undefined } : data;
                const { invUnitList } = data === undefined ? { invUnitList: undefined } : data;
                if (data != undefined && data.invNameList !== undefined) {
                    ownCommonData["invNameList"] = invNameList
                    ownCommonData["invUnitList"] = invUnitList
                    setOwnCommonData(ownCommonData)
                }
                setYieldRuleList([])
                if (id !== undefined) {
                    setIsBusy(true)
                    apigetYieldRuleByID(id)
                        .then((data) => {
                            prepareData(data)
                        })
                        .catch((err) => {
                            setIsBusy(false)
                        });
                }
            })
            .catch((err) => {
                setIsBusy(false)
                //message.error("Server failed. " + err);
            });
    }, [])
    
    const validateFieldsNewYield = async () => {
        var errorList = Array();
        if (getMasterChildInvQTY() < selYield["slave_v_qty"]) {
            message.error("Sorry! Slave QTY should be greater than Master QTY.");
            return false;
        }
        if (getMasterChildInvUnitName() != getSlaveChildInvUnit() && parseFloat(getSlaveChildInvPricePerUnit()) != 0) {
            message.error("Sorry! Unit should be same as Inventory");
            return false;
        }
        if (selYield["parent_inv_id"] === selYield["master_child_inv_id"]) {
            message.error("Please select different inventory from Master and Parent");
            return false;
        }
        if (selYield["parent_inv_id"] === selYield["slave_child_inv_id"]) {
            message.error("Please select different inventory from Slave and Parent");
            return false;
        }
        errorList = isEmpty(selYield, "name", errorList);
        errorList = isEmpty(selYield, "parent_inv_id", errorList);
        errorList = isEmpty(selYield, "parent_v_qty", errorList);
        errorList = isEmpty(selYield, "slave_child_inv_id", errorList);
        errorList = isEmpty(selYield, "slave_v_qty", errorList);
        errorList = isEmpty(selYield, "master_child_inv_id", errorList);
        setErrorField([...errorList]);
        return errorList.length == 0;
    };
    const validateFieldsRestYield = async () => {
        var errorList = Array();
        // if (getMasterChildInvQTY() <= 0) {
        //     message.error("Sorry! Rest QTY can't be below zero.");
        //     return false;
        // }
        errorList = isEmpty(selYield, "rest_yield_name", errorList);
        setErrorField([...errorList]);
        return errorList.length == 0;
    };

    const handleDelete = (record) => {
        let updateData = [...yieldRuleList];
        var storeIndex = updateData.findIndex(x => x.key == record.key);
        updateData.splice(storeIndex, 1);
        setYieldRuleList(updateData)
    }
    const getParentInvTotalCost = () => {
        let price_unit = selYield['parent_inv_id'] !== undefined ? ownCommonData.invNameList.filter(item => item.id === selYield['parent_inv_id'])[0].average_price_per_unit : 0
        let qty = selYield["parent_v_qty"] !== undefined ? selYield["parent_v_qty"] : 0
        return parseFloat(qty) * parseFloat(price_unit)
    }
    const getMasterChildInvUnitName = () => {
        let unit_id = getMasterChildInvUnitID()
        if (unit_id !== undefined) {
            let item = ownCommonData.invUnitList.filter(item => item.id === unit_id)[0]
            if (item !== undefined) {
                return " " + item.name
            }
        }
        return "--"
    }
    const getMasterChildInvUnitID = () => {
        if (ownCommonData.invNameList === undefined) return undefined
        let selectedItem = ownCommonData.invNameList.filter(e => e.id === selYield["master_child_inv_id"])[0]
        return selectedItem !== undefined ? selectedItem.unit_id : undefined
    }
    const getMasterChildInvQTY = () => {
        let sumYieldQTY = 0
        for (let i = 0; i < yieldRuleList.length; i++) {
            sumYieldQTY += parseFloat(yieldRuleList[i].slave_v_qty)
        }
        let parent_v_qty = selYield["parent_v_qty"] !== undefined ? selYield["parent_v_qty"] : 0
        let waste_v_qty = selYield["waste_v_qty"] !== undefined ? selYield["waste_v_qty"] : 0
        let result = parseFloat(parent_v_qty) - parseFloat(sumYieldQTY) - parseFloat(waste_v_qty)
        return result.toFixed(2)
    }
    const getRestPricePerUnit = () => {
        let restTotalPrice = getRestTotalPrice()
        let restQty = getMasterChildInvQTY()
        let result = 0
        if (restQty * restTotalPrice === 0) result = 0
        else {
            result = parseFloat(restTotalPrice) / parseFloat(restQty)
        }
        return result.toFixed(2)
    }
    const getRestTotalPrice = () => {
        let sumYieldTotalPrice = 0
        for (let i = 0; i < yieldRuleList.length; i++) {
            sumYieldTotalPrice += parseFloat(yieldRuleList[i].total_price)
        }
        let result = 0
        result = getParentInvTotalCost() - parseFloat(sumYieldTotalPrice)
        return result.toFixed(2)
    }
    const getRestYieldTest = () => {
        let rest_qty = getMasterChildInvQTY()
        let master_qty = selYield["parent_v_qty"] !== undefined ? selYield["parent_v_qty"] : 0
        let result = 0
        if (rest_qty * master_qty === 0) result = 0
        else {
            result = parseFloat(rest_qty) * 100 / parseFloat(master_qty)
        }
        return result.toFixed(2)
    }
    const AddRest = async () => {
        if ((await validateFieldsRestYield()) === false) return;

        let title = "Name : " + selYield["rest_yield_name"] + "  -  price/unit : $" + getRestPricePerUnit() + " /" + getMasterChildInvUnitName()
        var result = await showConfirmDlgWidget({ title: title, content: "Are you sure to Add this record in Yield Name? " });
        if (result == false) return;

        let No = 0
        if (yieldRuleList.length !== 0) {
            No = yieldRuleList[yieldRuleList.length - 1].key + 1
        }
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        var formData = new FormData();
        formData.append("average_price_per_unit", getRestPricePerUnit());
        formData.append("name", selYield["rest_yield_name"]);
        formData.append("unit_id", getMasterChildInvUnitID());
        new Promise((resolve, reject) => {
            axiosPost(urlCreateYieldName, formData, config)
                .then((res) => {
                    if (res == null) {
                        message.error("There's same yield name");
                        return;
                    }
                    updateCommonData(res)
                    let tmp = {
                        key: No,
                        inv_name_id: selYield["inv_name_id"],
                        yield_name_id: res.id,
                        qty: getMasterChildInvQTY(),
                        unit_id: getMasterChildInvUnitID(),
                        reference_inv_name_id: "-1",
                        total_price: getRestTotalPrice(),
                        yield_test: getRestYieldTest(),
                        is_rest_yield: "1"
                    }
                    let tmpList = [...yieldRuleList]
                    tmpList = [...tmpList, tmp]
                    cleanRestYieldProperty()
                    setYieldRuleList(tmpList)
                })
                .catch((err) => {
                    message.error("sorry! " + err);

                });
        });
    }

    const getParentInvUnitName = () => {
        let unit_id = getParentInvUnitID()
        if (unit_id !== undefined) {
            let item = ownCommonData.invUnitList.filter(item => item.id === unit_id)[0]
            if (item !== undefined) {
                return " " + item.name
            }
        }
        return "--"
    }

    const getParentInvUnitID = () => {
        if (ownCommonData.invNameList === undefined) return undefined
        let selectedItem = ownCommonData.invNameList.filter(e => e.id === selYield["parent_inv_id"])[0]
        return selectedItem !== undefined ? selectedItem.unit_id : undefined
    }

    const getWasteYieldTest = () => {
        let master_qty = selYield["parent_v_qty"] !== undefined ? selYield["parent_v_qty"] : 0
        let qty = selYield["waste_v_qty"] !== undefined ? selYield["waste_v_qty"] : 0
        let result = 0
        if (master_qty * qty === 0) result = 0
        else {
            result = parseFloat(qty) * 100 / parseFloat(master_qty)
        }
        return result.toFixed(2)
    }

    const cleanRestYieldProperty = () => {
        selYield["rest_yield_name"] = ""
        setSelYield(selYield)
    }
    const getSlaveChildInvUnit = () => {
        if (ownCommonData.invNameList === undefined) return "--"
        let selectedItem = ownCommonData.invNameList.filter(e => e.id === selYield["slave_child_inv_id"])[0]
        let unit_id = selectedItem !== undefined && selectedItem.unit_id !== undefined ? selectedItem.unit_id : undefined
        if (unit_id !== undefined) {
            let item = ownCommonData.invUnitList.filter(item => item.id === unit_id)[0]
            if (item !== undefined) {
                return " " + item.name
            }
        }
        return "--"
    }
    const getSlaveChildInvPricePerUnit = () => {
        if (ownCommonData.invNameList === undefined) return 0
        let selectedItem = ownCommonData.invNameList.filter(e => e.id === selYield["slave_child_inv_id"])[0]
        let result = selectedItem !== undefined && selectedItem.average_price_per_unit !== undefined ? selectedItem.average_price_per_unit : 0
        return parseFloat(result).toFixed(2)
    }
    const getNewYieldTotalPrice = () => {
        let qty = selYield["slave_v_qty"] !== undefined ? selYield["slave_v_qty"] : 0
        return parseFloat(qty) * parseFloat(getSlaveChildInvPricePerUnit())
    }
    const getNewYieldTest = () => {
        let master_qty = selYield["parent_v_qty"] !== undefined ? selYield["parent_v_qty"] : 0
        let qty = selYield["slave_v_qty"] !== undefined ? selYield["slave_v_qty"] : 0
        let result = 0
        if (master_qty * qty === 0) result = 0
        else {
            result = parseFloat(qty) * 100 / parseFloat(master_qty)
        }
        return result.toFixed(2)
    }
    const AddYieldTest = async () => {
        if ((await validateFieldsNewYield()) === false) return;

        let No = 0
        if (yieldRuleList.length !== 0) {
            No = yieldRuleList[yieldRuleList.length - 1].key + 1
        }

        let tmp = {
            key: No,
            slave_child_inv_id: selYield["slave_child_inv_id"],
            slave_v_qty: selYield["slave_v_qty"],
            yield_test: getNewYieldTest(),
            total_price: getNewYieldTotalPrice()
        }
        let tmpList = [...yieldRuleList]
        tmpList = [...tmpList, tmp]
        cleanNewYieldProperty()
        setYieldRuleList(tmpList)
    }
    const cleanNewYieldProperty = () => {
        selYield["slave_child_inv_id"] = ""
        selYield["slave_v_qty"] = "0"
        setSelYield(selYield)
    }

    const updateCommonData = (res) => {
        if (res === null || res === undefined) return
        var tmpData = ownCommonData;
        let updateDataYiledList = [...tmpData["yieldNameList"]];
        updateDataYiledList = [res, ...updateDataYiledList];

        let updateDataMergeYiledList = [...tmpData["merge_yield_list"]];
        res["virtual_id"] = updateDataMergeYiledList.length
        updateDataMergeYiledList = [res, ...updateDataMergeYiledList];

        tmpData["yieldNameList"] = [...updateDataYiledList];
        tmpData["merge_yield_list"] = [...updateDataMergeYiledList];

        setOwnCommonData({ ...tmpData });
    }

    const saveYield = async () => {
        if (getMasterChildInvQTY() !== 0) {
            //message.error("Please configure all Yield. Rest QTY is not Zero. Please Add them to continue. ")
            //return
        }
        if (selYield === undefined || selYield['master_child_inv_id'] === "" || selYield['master_child_inv_id'] === undefined) {
            message.error("Select Master Child Inventory*")
            return
        }
        if (selYield["parent_inv_id"] === selYield["master_child_inv_id"]) {
            message.error("Please select different inventory from Master and Parent");
            return false;
        }
        selYield["unit_id"] = getMasterChildInvUnitID()
        selYield["yield_test"] = getRestYieldTest()
        selYield["master_v_qty"] = getMasterChildInvQTY()

        setIsBusy(true);
        if (id === undefined) {
            apiCreateYieldRule(selYield, yieldRuleList)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while creating a Yield Rule");
                }
                else {
                    message.success("Yield Rule has been saved.")
                }
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry! saveYield" + err);
            });
        } else {
            selYield["id"] = id
            apiUpdateYieldRule(selYield, yieldRuleList)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating a Yield Rule");
                }
                else {
                    message.success("Yield Rule has been updated.")
                }
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry! saveYield" + err);
            });
        }
        
    }

    const prepareData = (data) => {
        let yieldRuleList = data.yieldRuleList
        for (let i = 0; i < yieldRuleList.length; i++) {
            let selectedItem = ownCommonData.invNameList.filter(e => e.id === yieldRuleList[i]["slave_child_inv_id"])[0]
            yieldRuleList[i]["total_price"] = parseFloat(selectedItem.average_price_per_unit) * parseFloat(yieldRuleList[i].slave_v_qty)
        }
        setYieldRuleList(yieldRuleList)
        selYield["name"] = data.name
        selYield["master_child_inv_id"] = data.master_child_inv_id
        selYield["parent_inv_id"] = data.parent_inv_id
        selYield["parent_v_qty"] = data.parent_v_qty
        selYield["waste_v_qty"] = data.waste_v_qty
        setSelYield(selYield)
        setIsBusy(false);
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row gutter={[24, 16]}>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 0 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <div>Enter Yeild Rule Name* </div>
                                <ProductDescriptionInput
                                    placeholder="Name"
                                    field={"name"}
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={15}>
                                <div style={{ color: 'green' }}> 1. Select Master Child Inventory* (ex : Fillet Steaks) and Select Parent Inventory* (ex : Beef Tenderloin) </div>
                                <div style={{ color: 'green' }}> 2. Enter Waste or Skip it and Select Slave Child Inventory* (ex : Sliced Beef, Minced Beef, Soup Stock Material)</div>
                                <div style={{ color: 'green' }}> 3. Click Add button to append above inventory and Finally Save click</div>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 10 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <div>Select Master Child Inventory*</div>
                                <ProductTypeSelector
                                    placeholder={"Select Master Child Inventory"}
                                    field={"master_child_inv_id"}
                                    list={ownCommonData.invNameList}
                                    displayField="name"
                                    idField="id"
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>QTY :  </div>
                                {
                                    getMasterChildInvQTY()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                Unit :  &nbsp;
                                <div>
                                    {
                                        getMasterChildInvUnitName()
                                    }
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Cost/Unit :  </div> $
                                {
                                    getRestPricePerUnit()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Total Cost :  </div> $
                                {
                                    getRestTotalPrice()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Yield :  </div>
                                {
                                    getRestYieldTest()
                                } %
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <CommonButton
                                    customClass={"product-upload-basic-unit-add-button"}
                                    onClick={() => {
                                        saveYield()
                                    }}
                                >
                                    Save
                                </CommonButton>
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 10 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <div>Select Parent Inventory*</div>
                                <ProductTypeSelector
                                    placeholder={"Select Parent Inventory"}
                                    field={"parent_inv_id"}
                                    list={ownCommonData.invNameList}
                                    displayField="name"
                                    idField="id"
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />

                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                QTY :  &nbsp;
                                <ProductInputNumber
                                    field={"parent_v_qty"}
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                Unit :  &nbsp;
                                <div>
                                    {getParentInvUnitName()}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Cost/Unit :  </div> $
                                {
                                    selYield['parent_inv_id'] !== undefined ? ownCommonData.invNameList.filter(item => item.id === selYield['parent_inv_id'])[0].average_price_per_unit : "--"
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Total Cost :  </div> $
                                {
                                    getParentInvTotalCost()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Yield :  </div>
                                100%
                            </Col>

                        </Row>
                        <Row align={"middle"} gutter={[24, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <div style={{ float: 'right' }}>Waste*</div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                QTY :  &nbsp;
                                <ProductInputNumber
                                    field={"waste_v_qty"}
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                Unit :  &nbsp;
                                <div>
                                    {getParentInvUnitName()}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Cost/Unit :  </div> $
                                0
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Total Cost :  </div> $
                                0
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Yield :  </div>
                                {getWasteYieldTest()}%
                            </Col>

                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 20 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <div>Select Slave Child Inventory*</div>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <ProductTypeSelector
                                            placeholder={"Select Slave Child Inventory"}
                                            field={"slave_child_inv_id"}
                                            list={ownCommonData.invNameList}
                                            displayField="name"
                                            idField="id"
                                            productInfo={selYield}
                                            setProductInfo={setSelYield}
                                            errorField={errorField}
                                            setErrorField={setErrorField}
                                            onChangeHandle={getSlaveChildInvPricePerUnit}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                QTY :  &nbsp;
                                <ProductInputNumber
                                    field={"slave_v_qty"}
                                    productInfo={selYield}
                                    setProductInfo={setSelYield}
                                    errorField={errorField}
                                    setErrorField={setErrorField}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                Unit :  &nbsp;
                                <div>
                                    {
                                        getSlaveChildInvUnit()
                                    }
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Cost/Unit :  </div> $
                                {
                                    getSlaveChildInvPricePerUnit()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Total Cost :  </div> $
                                {
                                    getNewYieldTotalPrice()
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <div>Yield :  </div>
                                {
                                    getNewYieldTest()
                                }
                                %
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <CommonButton
                                    customClass={"product-upload-basic-unit-add-button"}
                                    onClick={() => {
                                        AddYieldTest()
                                    }}
                                >
                                    Add&nbsp;
                                </CommonButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <YieldRuleTableWidget
                            dataList={yieldRuleList}
                            handleDelete={handleDelete}
                            ownCommonData={ownCommonData}
                        />
                    </Col>
                </Row>
            </Spin>
        </CommonDivWidget>
    )

}

export default YieldRulePage
