import { axiosGet, axiosPost } from "./ajaxServices";
import { urlGetInventoryUnit, urlCreateInventoryUnit, urlUpdateIventoryUnit, urlDeleteIventoryUnit } from "./CONSTANTS";

export const apiGetInventoryUnit = () => {
  const url = urlGetInventoryUnit;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiCreateInventoryUnit(data) {
  const url = urlCreateInventoryUnit;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateIventoryUnit(data) {
  const url = urlUpdateIventoryUnit;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiDeleteIventoryUnit(id) {
  const url = urlDeleteIventoryUnit;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}