import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateInvestment, urlDeleteInvestment, urlGetInvestment, urlUpdateInvestment } from "./CONSTANTS";


export function apiCreateInvestment(data) {
  const url = urlCreateInvestment;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("cost", data.cost);
  formData.append("start_date", data.start_date);
  formData.append("month_of_depriciation", data.month_of_depriciation);
  
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateInvestment(data) {
  const url = urlUpdateInvestment;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("cost", data.cost);
  formData.append("start_date", data.start_date);
  formData.append("month_of_depriciation", data.month_of_depriciation); 
  
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetInvestmentList = () => {
  const url = urlGetInvestment;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiDeleteInvestment(id) {
  const url = urlDeleteInvestment;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}