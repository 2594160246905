import React, { useContext, useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Input, Button, Radio, Form } from 'antd';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import "./RecipeAddTableWidget.css"
import { getCurrencyInfo } from 'utils/GlobalFunctions';


const RecipeAddTableWidget = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, handleDelete, ownCommonData } = props;

  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      dataIndex: "virtual_id",
      align: 'center',
      render: (text, record) => {
        return <span> {record["ingredient"].virtual_id} </span>
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      align: 'center',
      render: (text, record) => {
        return <span> {record["ingredient"].name} </span>
      }
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: 'center',
      render: (text, record) => {
        return <span> {record["total_enter_qty"]} </span>
      }
    },
    {
      title: "Unit",
      dataIndex: "unit_id",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_id - b.unit_id,
      },
      render: (text, record) => {

        const result = ownCommonData.unitList.filter(item => item.id === record.unit_id);
        if (result.length === 0) {
          return "---";

        }
        else {
          return result[0].name;
        }
      }
    },
    {
      title: "Cost",
      dataIndex: "total_price",
      align: 'center',
      sorter: {
        compare: (a, b) => a.total_price - b.total_price
      },
      render: (text, record) => {
        return <span>{ "$(" + getCurrencyInfo() + ") " + record["total_price"]} </span>
      }
    },
    {
      title: "Pre Instruction",
      dataIndex: "pre_instruction",
      align: 'center',
      render: (text, record) => {
        return <span> {record["pre_instruction"]} </span>
      }
    },
    {
      title: "Post Instruction",
      dataIndex: "post_instruction",
      align: 'center',
      render: (text, record) => {
        return <span> {record["post_instruction"]} </span>
      }
    },
    {
      title: "Procedure",
      dataIndex: "procedure",
      align: 'center',
      render: (text, record) => {
        return <span> {record["procedure"]} </span>
      }
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {

        return <div size="middle" direction={"vertical"}>
          <a style={{ color: "var(--blueColor)" }} className="del-btn" onClick={async () => {
            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
            if (res == false) return;
            handleDelete(record)
          }}>
            Delete
          </a>
        </div>
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info["ingredient"].virtual_id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(RecipeAddTableWidget)
