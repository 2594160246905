// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

export const logoUrl = "/assets/images/logo.png";
export const googleKey = "AIzaSyDb3oLDTkoVpLzE4PxZ0PL1ENW8rVI7OU4";
export const REGISTER_WITH = {
    email: "Email",
    phone: "Phone"
}

export const ShapeType = {
    TEXT: "text",
    RECT: "rect",
    STAR: "star",
    LINE: "line",
    IMAGE: "image",
    TABLE: "table",
  };
  
  export const ShapeTool = {
    NONE: "",
    TEXT: "text",
    STAR: "star",
    PENCIL: "pencil",
    RECT: "rect",
    IMAGE: "image",
    TABLE: "table",
  };
  
  export const FontFamily = [
    "serif",
    "sans-serif",
    "monospace",
    "cursive",
    "fantasy",
  ];
  
  export var storyWidth = 1100;
  export var storyHeight = 650;