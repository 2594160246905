import { Space } from 'antd';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import React from 'react'

export function showColumnAction(text, record, onUpdateAction, onDeleteAction) {
    return (
      <div size="middle" direction={"vertical"}>
        <a className="edit-btn"
          onClick={() => {
            onUpdateAction(record)
          }}
        >
          Edit
        </a>
        <a style={{ color: "var(--redColor)", marginLeft:20 }}  className="del-btn" onClick={ async()=>{
          var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
          if (res == false) return;
          onDeleteAction(record)
        }}>
          Delete
        </a>
      </div>
    );
  
  }
  
