import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateRoom, urlGetRoomList, urlDeleteRoom, urlGetRoomById, urlUpdateRoom, urlUpdateRoomDiagram} from "./CONSTANTS";

export function apiCreateRoom(data) {
  const url = urlCreateRoom;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("width", data.width);
  formData.append("height", data.height);
  formData.append("reservation_start_timestamp", data.reservation_start_timestamp === undefined ? "" : data.reservation_start_timestamp);
  formData.append("reservation_end_timestamp", data.reservation_end_timestamp === undefined ? "" : data.reservation_end_timestamp);
  formData.append("reservation_name", data.reservation_name === undefined ? "" : data.reservation_name);
  formData.append("status", data.status);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateRoom(data) {
  const url = urlUpdateRoom;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("width", data.width);
  formData.append("height", data.height);
  formData.append("reservation_start_timestamp", data.reservation_start_timestamp === undefined ? "" : data.reservation_start_timestamp);
  formData.append("reservation_end_timestamp", data.reservation_end_timestamp === undefined ? "" : data.reservation_end_timestamp);
  formData.append("reservation_name", data.reservation_name === undefined ? "" : data.reservation_name);
  formData.append("status", data.status);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export const apiGetRoomList = () => {
  const url = urlGetRoomList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiDeleteRoom(id) {
  const url = urlDeleteRoom;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiGetRoomById(id) {
  const url = urlGetRoomById;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateRoomDiagram(room_id, dataList) {
  const url = urlUpdateRoomDiagram;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("room_id", room_id);
  var shapeList = []
  for (let i = 0; i< dataList.length; i++) {
    var data = {}
    data["table_name"] = dataList[i]["table_name"] === undefined ? "" : dataList[i]["table_name"]
    data["reservation_client_name"] = dataList[i]["reservation_client_name"] === undefined ? "" : dataList[i]["reservation_client_name"]
    data["reservation_start_timestamp"] = dataList[i]["reservation_start_timestamp"] === undefined ? "" : dataList[i]["reservation_start_timestamp"]
    data["reservation_end_timestamp"] = dataList[i]["reservation_end_timestamp"] === undefined ? "" : dataList[i]["reservation_end_timestamp"]
    data["reservation_phone_number"] = dataList[i]["reservation_phone_number"] === undefined ? "" : dataList[i]["reservation_phone_number"]
    data["reservation_seat_count"] = dataList[i]["reservation_seat_count"] === undefined ? "" : dataList[i]["reservation_seat_count"]
    data["reservation_description"] = dataList[i]["reservation_description"] === undefined ? "" : dataList[i]["reservation_description"]
    data["status"] = dataList[i]["status"] === undefined ? "" : dataList[i]["status"]
    data["seat_count"] = dataList[i]["seat_count"] === undefined ? "" : dataList[i]["seat_count"]
    data["type"] = dataList[i]["type"] === undefined ? "" : dataList[i]["type"]
    data["x"] = dataList[i]["x"] === undefined ? "" : dataList[i]["x"]
    data["y"] = dataList[i]["y"] === undefined ? "" : dataList[i]["y"]
    data["rotation"] = dataList[i]["rotation"] === undefined ? "0" : dataList[i]["rotation"]
    data["width"] = dataList[i]["width"] === undefined ? "" : dataList[i]["width"]
    data["height"] = dataList[i]["height"] === undefined ? "" : dataList[i]["height"]
    data["stroke"] = dataList[i]["stroke"] === undefined ? "" : dataList[i]["stroke"]
    data["points"] = dataList[i]["points"] === undefined ? "" : dataList[i]["points"]
    data["text"] = dataList[i]["text"] === undefined ? "" : dataList[i]["text"]
    data["fontSize"] = dataList[i]["fontSize"] === undefined ? "" : dataList[i]["fontSize"]
    data["font_family"] = dataList[i]["font_family"] === undefined ? "" : dataList[i]["font_family"]
    data["color"] = dataList[i]["color"] === undefined ? "" : dataList[i]["color"]
    data["image_name"] = dataList[i]["image_name"] === undefined ? "" : dataList[i]["image_name"]
    shapeList.push(data);
    if (data["image_name"] != "") {
      formData.append("image_name_" + i, data["image_name"]);
    }
  }
  
  formData.append("shapeList", JSON.stringify(shapeList));
  console.log("shapeList", shapeList)
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}