import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, DatePicker } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import "./YieldRuleListPage.css"
import TableWidget from './TableWidget/TableWidget';
import { apiDeleteYieldRule, apiGetYieldRuleList } from 'services/yieldRuleService';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTE_YIELD_RULE_EDIT } from 'navigation/CONSTANTS';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
const { RangePicker } = DatePicker;

function YieldRuleListPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    useEffect(() => {
        apiGetYieldRuleList()
        .then((data) => {
            setSearchList(data)
            setDataList(data)
        })
        .catch((err) => {
            // message.error("Sorry! " + err);
        });
    }, []);

  
    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    const onUpdateAction = async (pInfo) => {
        dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_YIELD_RULE_EDIT }))
        history.push(ROUTE_YIELD_RULE_EDIT + "/" + pInfo.id)
    }
    const onDeleteAction = (pInfo) => {
        setIsBusy(true);
        apiDeleteYieldRule(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
            });
    }
    const onCreateAction = async ()=>{
       
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                        <Row justify="center" align="middle">
                            <Col>
                                <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                                    <RangePicker size={'default'}
                                        onChange={(e, date) => {
                                            if (date == null) {
                                                handleDateFilter("", "")
                                            } else {
                                                handleDateFilter(date[0], date[1])
                                            }

                                        }} />
                                </div>
                            </Col>
                            <Col flex={'auto'}>
                                <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                                    <TitleSearch
                                        onSearch={handleSearch}
                                        onChange={handleChange}
                                        onPressEnter={handleSearch}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 10 }}>
                            <TableWidget
                                dataList={searchList}
                                onUpdateAction={onUpdateAction}
                                onDeleteAction={onDeleteAction}
                            />
                        </div>


            </Spin>
        </CommonDivWidget>
    );
};


export default YieldRuleListPage
