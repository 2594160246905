// @flow strict

import React, { useEffect, useState } from "react";
import { PictureOutlined } from "@ant-design/icons";
// avatarBlank
function ProductImageWidget(props) {
  const [imageUrl, setImageUrl] = useState("");
  const { productInfo, setProductInfo } = props;
  const { errorField, setErrorField } = props;
  const { id, field } = props;

  useEffect(() => {
    if (
      productInfo.image_url !== undefined &&
      productInfo.image_url !== "" &&
      (imageUrl === "" || imageUrl === undefined)
    ) {
      setImageUrl(productInfo.image_url);
    }
    else if (productInfo.image_url === undefined ||
      productInfo.image_url === "") {
      setImageUrl("");
    }
  }, [productInfo.image_url]);
  const onDropImage = async (e) => {
    // console.log("/////////////////");
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setImageUrl(URL.createObjectURL(files[0]));
    setProductInfo({ ...productInfo, image_name: files[0] });
  };
  //   product-image-widget-error
  return (
    <div className="product-image-widget">
      <label htmlFor={id === undefined ? "upload_product_image" : id}>
        <input
          type="file"
          id={id === undefined ? "upload_product_image" : id}
          name={id === undefined ? "upload_product_image" : id}
          style={{ display: "none" }}
          accept="image/x-png,image/gif,image/jpeg"
          onChange={onDropImage}
        />
        {imageUrl == null || imageUrl == "" ? (
          <img
            className={
              "product-image-widget-image " +
              (errorField.includes("image_name") ? "product-image-widget-error" : "")
            }
            src={'/assets/icons/ic_picture.svg'}
            alt="Kalaecafe"
                />
        ) : (
          <img src={imageUrl} className={"product-image-widget-image "} />
        )}
        <div style={{ textAlign: "center", cursor: "pointer", marginBottom: "10px" }}>click here to insert image</div>
      </label>
    </div>
  );
}

export default ProductImageWidget;
