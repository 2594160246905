import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Space, Radio, Spin, DatePicker } from "antd";

import "./AddSalaryDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { apiGetTeamMemberList } from 'services/teamMemberService';
import moment from "moment"
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');


function AddSalaryDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [costUnit, setCostUnit] = useState("");
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    apiGetTeamMemberList()
      .then((data) => {
        setDataList(data)
      })
      .catch((err) => {
        // message.error("Sorry! " + err);
      });
  }, []);

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({ month_date: moment().startOf("month").format(dateFormat), is_year_salary: '0', year_date: moment().startOf("year").format(dateFormat), year_salary: 0, month_salary: 0 });
    }
  }, [record]);

  const onChangeHandle = (team_member_id) => {

    let storeIndex = dataList.findIndex(x => x.id == team_member_id);
    var tmpData = dataList[storeIndex]
    tmpData["team_member_id"] = team_member_id
    tmpData["month_date"] = moment().startOf("month").format(dateFormat)
    tmpData["is_year_salary"] = '0'
    tmpData["year_date"] = moment().startOf("year").format(dateFormat)
    tmpData["year_salary"] = 0
    tmpData["month_salary"] = 0
    setData(tmpData)
  }

  const onSelectMethod = e => {
    var tmpData = data
    tmpData["is_year_salary"] = e.target.value
    setData({ ...tmpData })
  }

  
  function onChangeMonthDatePicker(date, dateString) {
    if (!dateString) {
      message.info("Please select start date")
      return
    }
    var tmpData = data
    tmpData["month_date"] = date.format(dateFormat)

    setData({ ...tmpData })
  }

  function onChangeYearDatePicker(date, dateString) {
    if (!dateString) {
      message.info("Please select start date")
      return
    }
    var tmpData = data
    tmpData["year_date"] = date.format(dateFormat)

    setData({ ...tmpData })
  }

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "team_member_id", errorList);
    errorList = isEmpty(data, "description", errorList);
    if (data["is_year_salary"] == '1') {
      errorList = isEmpty(data, "year_salary", errorList);
    } else {
      errorList = isEmpty(data, "month_salary", errorList);
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-salary-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data
        });
      }}
    >
      <Spin spinning={isBusy}>
        <Row align={"middle"} gutter={16}>
          <Col>
            <div>Select Team Member* </div>
          </Col>
          <Col flex={"auto"}>
            <ProductTypeSelector
              placeholder={"Team Member"}
              field={"team_member_id"}
              list={dataList}
              displayField="name"
              idField="id"
              onChangeHandle={onChangeHandle}
              productInfo={data}
              setProductInfo={setData}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
        </Row>

        <Row align={"middle"} gutter={16} style={{ marginTop: "22px" }}>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Row>

        <Row align={"middle"} gutter={16} style={{ marginTop: "12px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div>Description*</div>
            <ProductDescriptionInput
              placeholder="description"
              field={"description"}
              productInfo={data}
              setProductInfo={setData}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} >
            <Radio.Group onChange={onSelectMethod} value={data["is_year_salary"]}>
              <Radio value={'0'}>Month</Radio>
              <Radio value={'1'}>Year</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Month*</div>
              <DatePicker
                disabled={data["is_year_salary"] == '1'}
                value={dayjs(data["month_date"], dateFormat)}
                onChange={onChangeMonthDatePicker}
                picker="month"
                size='middle'
                style={{
                  border: errorField !== undefined && errorField.includes("month_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width: "100%"
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Month Salary*</div>
              <ProductInputNumber
                disabled={data["is_year_salary"] == '1'}
                field={"month_salary"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Year*</div>
              <DatePicker
                value={dayjs(data["year_date"], dateFormat)}
                onChange={onChangeYearDatePicker}
                disabled={data["is_year_salary"] == '0'}
                picker="year"
                size='middle'
                style={{
                  border: errorField !== undefined && errorField.includes("year_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width: "100%"
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Year Salary*</div>
              <ProductInputNumber
                disabled={data["is_year_salary"] == '0'}
                field={"year_salary"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddSalaryDialog)
