import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select } from "antd";
import DetailTable from './DetailTable';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList } = props;
    if (dataList === undefined) return <div />



    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            sorter: {
                compare: (a, b) => a.customer_name.localeCompare(b.customer_name),
            },
        },
        {
            title: "Pay Amount",
            dataIndex: "payment_amount",
            sorter: {
                compare: (a, b) => a.payment_amount - b.payment_amount,
            },
            render: text => <div>$ {text}</div>,
        },
        {
            title: "Currency",
            dataIndex: "payment_currency",
            sorter: {
                compare: (a, b) => a.payment_currency - b.payment_currency,
            },
            render: text => <div>$ {text}</div>,
        },
        {
            title: "Fee",
            dataIndex: "payment_fee",
            sorter: {
                compare: (a, b) => a.payment_fee - b.payment_fee,
            },
            render: text => <div>$ {text}</div>,
        },
        {
            title: "Network Amount",
            dataIndex: "payment_network_amount",
            align: "center",
            sorter: {
                compare: (a, b) => a.payment_network_amount - b.payment_network_amount,
            },
            render: text => <div>$ {text}</div>,
        },
        {
            title: "Txn Id",
            dataIndex: "txn_id",
            sorter: {
                compare: (a, b) => a.txn_id - b.txn_id,
            },
        },
        {
            title: "Status",
            dataIndex: "payment_status",
            sorter: {
                compare: (a, b) => a.payment_status - b.payment_status,
            },
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {

                return <div size="middle" direction={"vertical"}>
                    <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                        var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                        if (res == false) return;
                    }}>
                        Delete
                    </a>
                </div>
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    return <DetailTable
                            dataList={record.cartList} />
                }}
            />
        </div>
    )
}

export default withRouter(TableWidget)
