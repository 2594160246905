import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { message, Spin } from "antd";
import TopWidget from './TopWidget/TopWidget';
import { apiGetDashboardDetail } from 'services/dashBoardService';
import AccessLogTableWidget from './AccessLogTableWidget';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget';

const Dashboard = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetDashboardDetail()
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error Response. " + err);
            });
    };
    
    return (
        <div>
            <Spin spinning={isBusy}>
                <TopWidget/>
                <CommonDivWidget>
                <div className="part-header">Access Client Info List</div>
                    <AccessLogTableWidget
                        dataList={dataList}
                        setDataList={setDataList}
                    />
                </CommonDivWidget>
                
            </Spin>
        </div>
    );
};

export default Dashboard;