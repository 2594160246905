import { Select, Row, Col, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { isEmpty } from "utils/GlobalFunctions";
import "./AppendInventoryInfoDialog";
const { Option } = Select;

function AppendInventoryInfoDialog(props) {
  const { proceed, show, title } = props;
  const [newUnit, setNewUnit] = useState({});
  const [errorField, setErrorField] = useState([]);
  
  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(newUnit, "qty", errorList);
    errorList = isEmpty(newUnit, "price_per_unit", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onOKAction = async () => {
    if ((await validateFields()) === false) return;
    proceed({
      ...newUnit,
    });
  }

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-unit-new-dialog"
      onOk={onOKAction}
    >
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>QTY*</div>
          <ProductInputNumber
            placeholder="QTY"
            field={"qty"}
            productInfo={newUnit}
            setProductInfo={setNewUnit}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Cost Per Unit*</div>
          <ProductInputNumber
            field={"price_per_unit"}
            productInfo={newUnit}
            setProductInfo={setNewUnit}
            errorField={errorField}
            setErrorField={setErrorField}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default confirmable(AppendInventoryInfoDialog);
