import { Spin, Tabs, message, Col, Tooltip, Divider } from 'antd';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import React, { useEffect, useState } from 'react'
import { apiGetUnitBasicInfo } from 'services/unitConvertService';
import CustomUnitConvertPage from './CustomUnitConvertPage/CustomUnitConvertPage';
import GlobalUnitConvertPage from './GlobalUnitConvertPage/GlobalUnitConvertPage';
import RecipeUnitConvertPage from './RecipeUnitConvertPage/RecipeUnitConvertPage';
import UnitListPage from './UnitListPage/UnitListPage';
import "./UnitPage.css"
const { TabPane } = Tabs;

function UnitPage() {

    const [isBusy, setIsBusy] = useState(false);
    const [ownCommonData, setOwnCommonData] = useState({});
    const [inventoryBasicData, setInventoryBasicData] = useState({});

    useEffect(() => {
        setIsBusy(true)
        apiGetUnitBasicInfo()
          .then((data) => {
              setIsBusy(false)
            setInventoryBasicData(data);
          })
          .catch((err) => {
            setIsBusy(false)
            message.error("Sorry. " + err);
          });
      }, [])
    
      useEffect(() => {
        
        const { unitList } = inventoryBasicData === undefined ? { unitList: undefined } : inventoryBasicData;
        const { ingredientList } = inventoryBasicData === undefined ? { ingredientList: undefined } : inventoryBasicData;
        const { recipeList } = inventoryBasicData === undefined ? { recipeList: undefined } : inventoryBasicData;

        if (inventoryBasicData != undefined) {
          setOwnCommonData({
            ingredientList: ingredientList,
            unitList: unitList,
            recipeList: recipeList,
          });
        }
      }, [inventoryBasicData]);



    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Total Unit List" key="1">
                        <UnitListPage
                            ownCommonData={ownCommonData}
                            setOwnCommonData={setOwnCommonData}
                        />
                    </TabPane>
                    <TabPane tab="Global Unit Convert" key="2">
                        <GlobalUnitConvertPage
                            ownCommonData={ownCommonData}
                            setOwnCommonData={setOwnCommonData}
                        />
                    </TabPane>
                    <TabPane tab="Ingredient Unit Convert" key="3">
                      <CustomUnitConvertPage
                            ownCommonData={ownCommonData}
                            setOwnCommonData={setOwnCommonData}
                        />
                    </TabPane>
                    <TabPane tab="Recipe Unit Convert" key="4">
                      <RecipeUnitConvertPage
                            ownCommonData={ownCommonData}
                            setOwnCommonData={setOwnCommonData}
                        />
                    </TabPane>
                </Tabs>
            </Spin>
        </CommonDivWidget>
    );
};


export default UnitPage
