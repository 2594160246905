import React, { useEffect, useRef, useState } from "react";
import { ShapeType} from "config/CONSTANTS";

const ShapeEditingText = (props) => {
  const { oldText, onAddShape, baseDrawInfo , width, height} = props;
  const [newText, setNewText] = useState(oldText == undefined ? "" : oldText);
  const ref = useRef(null);
  const { color, stroke, font_family } = baseDrawInfo;
  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  const onFinishText = () => {
    if (newText == "") {
      onAddShape();
      return;
    }
    let fontSize = stroke * 2 + 7
    var lDiv = document.createElement('div');

    document.body.appendChild(lDiv);

    lDiv.style.fontSize = "" + fontSize + " px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.textContent = newText;

    var textWidth = 2 * lDiv.clientWidth
    document.body.removeChild(lDiv);
    lDiv = null;

    if (textWidth > width) {
      textWidth = width
    }

    const id = `text-${Date.now()}`;
    const shape = {
      id: id,
      type: ShapeType.TEXT,
      text: newText,
      y: 10,
      // x: width / 3,
      x: 10,
      fontSize: fontSize,
      font_family: font_family,
      color: color,
      isDragging: false,
      width: textWidth,
    };
    onAddShape(shape);
  };

  return (
    <div>
      {/* <div
        style={{
          top: 0,
          left: 0,
          width: width,
          height: height,
          background: "#000000f0",
          position: "absolute",
        }}
      ></div> */}
      <textarea
        autoFocus
        ref={ref}
        value={newText}
        style={{
          top: 20,
          left: 0,
          width: width,
          position: "absolute",
          background: "transparent",
          textAlign: "center",
          height: (height / 3) * 2,
          color: color,
          fontSize: stroke * 2 + 7,
          border: "none",
          outline: "none",
          fontFamily: font_family,
          lineHeight: 1,
        }}
        onChange={(e) => setNewText(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            onAddShape();
            return;
          }
          if (e.keyCode == 13 && e.shiftKey == false) {
            onFinishText(e.target.value);
            return;
          }
        }}
      />
    </div>
  );
};

export default ShapeEditingText;
