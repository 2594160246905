import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { useEffect } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { apiUpdateUserSiteItemMainSubPosition } from 'services/userSiteItemMainSubService';


function ExtraOptionTableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, group, onUpdateAction, onDeleteAction, onDetailAction } = props;
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (dataList !== undefined) {
            setDataSource([...dataList.map((info) => {
                return { ...info, key: info.id };
            })]);
        }
    }, [dataList]);
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "Option Name",
            dataIndex: "name",
            align: "center",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Option Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a className="edit-btn"
                            onClick={() => {
                                onUpdateAction(group, record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(group, record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];


    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 20, showSizeChanger: false, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(ExtraOptionTableWidget)
