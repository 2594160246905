// @flow strict

import { Input, message, Row, Col, Radio, Spin, InputNumber } from "antd";
import Modal from "antd/lib/modal/Modal";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductTypeSelector from "components/ProductPage/ProductTypeSelector/ProductTypeSelector";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import { axiosPost } from "services/ajaxServices";
import { apiGetRecipeBasicData, apiGetRecipeById } from "services/recipeService";
import { apiCreateCustomUnitConvert } from "services/unitConvertService";
import { isEmpty } from "utils/GlobalFunctions";
import RecipeTypeSelector from "../RecipeTypeSelector/RecipeTypeSelector";
import "./SelectIngredientDialog.css"
import SubRecipeTableWidget from "./TableWidget/SubRecipeTableWidget";
import TableWidget from "./TableWidget/TableWidget";
function SelectIngredientDialog(props) {
  const { show, proceed } = props;
  const { ownCommonData, setOwnCommonData } = props;
  const [errorField, setErrorField] = useState([]);
  const [selIngredient, setSelIngredient] = useState({});
  const [dataList, setDataList] = useState([]);
  const [isSubRecipe, setIsSubRecipe] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [unitConvertInfo, setUnitConvertInfo] = useState({ rate: 0, enter_unit_id: 0 });
  const [isConvertUnit, setIsConvertUnit] = useState(0);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selIngredient, "virtual_id", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onOkAction = async () => {
    if ((await validateFields()) === false) return;
    if (getCalculatedQTY() <= 0) {
      message.error("Please Enter QTY")
      return
    }
    if (unitConvertInfo['enter_unit_id'] === 0 || unitConvertInfo['enter_unit_id'] === undefined) {
      message.error("sorry! System can't find unit convert info. Please select correct Unit ")
      return
    }
    let selectedItem = ownCommonData.ingredientList.filter(e => e.virtual_id === selIngredient["virtual_id"])[0]
    selectedItem["virtual_id"] = Math.floor(Date.now())
    let res = { ingredient: selectedItem, dataList: dataList, total_enter_qty: getCalculatedQTY(), rate: unitConvertInfo['rate'], unit_id: unitConvertInfo['enter_unit_id'], total_price: getCalculatedPrice() }
    proceed({
      addedItem: res,
    });
  }
  const getSubItemList = () => {
    if (ownCommonData.ingredientList === undefined) return
    let selectedItem = ownCommonData.ingredientList.filter(e => e.virtual_id === selIngredient["virtual_id"])[0]
    if (selectedItem.hasOwnProperty('sub_recipe')) {
      setIsSubRecipe(true)
      apiGetRecipeById(selectedItem.id)
        .then((res) => {
          setDataList([res])
        })
        .catch((err) => {
          message.error("apiGetRecipeById. " + err);
        });
    }
    else {
      setIsSubRecipe(false)
      setDataList(selectedItem.subItemList)
    }
  }
  const updateUnitConvertRate = (record) => {
    setUnitConvertInfo({ rate: 0, enter_unit_id: 0 })
    if (record === undefined) return
    if (record.enter_unit_id === record.unit_id) {
      setUnitConvertInfo({ rate: 1, enter_unit_id: record.enter_unit_id })
    }
    else {
      let globalUnitConvert = ownCommonData.unitConvertGlobalList.filter(e => e.unit_id === record.unit_id && e.standard_unit_id === record.enter_unit_id)
      if (globalUnitConvert !== undefined && globalUnitConvert.length != 0) {
        setUnitConvertInfo({ rate: globalUnitConvert[0]['rate'], enter_unit_id: record.enter_unit_id })
      }
      else {
        if (record.hasOwnProperty('sub_recipe')) {
          let customUnitConvert = ownCommonData.unitConvertCustomList.filter(e => e.unit_id === record.unit_id && e.standard_unit_id === record.enter_unit_id && e.recipe_id === record.id)
          if (customUnitConvert !== undefined && customUnitConvert.length != 0) {
            setUnitConvertInfo({ rate: customUnitConvert[0]['rate'], enter_unit_id: record.enter_unit_id })
          }
          else {
            message.error("sorry! System can't find unit convert info. Please select correct Unit ")
          }
        }
        else {
          let customUnitConvert = ownCommonData.unitConvertCustomList.filter(e => e.unit_id === record.unit_id && e.standard_unit_id === record.enter_unit_id && e.ingredient_id === record.id)
          if (customUnitConvert !== undefined && customUnitConvert.length != 0) {
            setUnitConvertInfo({ rate: customUnitConvert[0]['rate'], enter_unit_id: record.enter_unit_id })
          }
          else {
            message.error("sorry! System can't find unit convert info. Please select correct Unit ")
          }
        }
      }
    }
  }
  const getSelectedItemUnitName = () => {
    if (dataList[0] === undefined) return "--"
    let enter_unit_id = dataList[0].enter_unit_id
    if (enter_unit_id == undefined) {
      return "--"
    }
    else {
      return ownCommonData.unitList.filter(e => e.id === enter_unit_id)[0].name
    }
  }
  const getCalculatedQTY = () => {
    if (dataList === undefined) return 0
    let qty = 0
    for (let i = 0; i < dataList.length; i++) {
      if (isSubRecipe) {
        qty = parseFloat(qty) + parseFloat(dataList[i].enter_qty === undefined || dataList[i].enter_qty === null ? 0 : dataList[i].enter_qty)
      }
      else {
        qty = parseFloat(qty) + parseFloat(dataList[i].enter_qty === undefined || dataList[i].enter_qty === null ? 0 : dataList[i].enter_qty)
      }

    }
    return parseFloat(qty).toFixed(2)
  }
  const getCalculatedPrice = () => {
    if (dataList === undefined) return 0
    let price = 0
    for (let i = 0; i < dataList.length; i++) {
      if (isSubRecipe) {
        price = parseFloat(price) + parseFloat(dataList[i].enter_qty === undefined || dataList[i].enter_qty === null ? 0 : dataList[i].enter_qty) * (parseFloat(dataList[i].total_price) / parseFloat(dataList[i].qty)) / unitConvertInfo['rate']
      }
      else {
        price = parseFloat(price) + parseFloat(dataList[i].enter_qty === undefined || dataList[i].enter_qty === null ? 0 : dataList[i].enter_qty) * parseFloat(dataList[i].average_price_per_unit) / unitConvertInfo['rate']
      }
    }
    return parseFloat(price).toFixed(3)
  }

  const handleChangeQtyforEachItem = (record) => {
    let updateData = [...dataList];
    var storeIndex = updateData.findIndex(x => x.virtual_id == record.virtual_id);
    updateData.splice(storeIndex, 1, record);
    setDataList(updateData)
    updateUnitConvertRate(record)
    getCalculatedQTY()
    getSelectedItemUnitName()
    getCalculatedPrice()
  }

  const onChangeConvert = e => {
    setIsConvertUnit(e.target.value);
  }

  const setupUnitCustomConvert = () => {
    if (ownCommonData.ingredientList === undefined) return
    let selectedItem = ownCommonData.ingredientList.filter(e => e.virtual_id === selIngredient["virtual_id"])[0]
    if (selectedItem === undefined) {
      message.error("Please select some ingredient.")
      return;
    }

    if (selectedItem.hasOwnProperty('sub_recipe')) {
      setIsSubRecipe(true)
      let recipe_id = selectedItem.id;
      if (recipe_id !== undefined) {
        let data = { recipe_id: recipe_id, unit_id: selIngredient["unit_id"], rate: selIngredient["rate"], standard_unit_id: selIngredient["standard_unit_id"] }
        setIsBusy(true)
        apiCreateCustomUnitConvert(data)
          .then((res) => {
            setIsBusy(false)
            var tmpData = ownCommonData;
            tmpData["unitConvertCustomList"] = [...res];
            setOwnCommonData({ ...tmpData });
            message.success("Succesfully setup!")
          })
          .catch((err) => {
            setIsBusy(false)
            message.error("Sorry. " + err);
          });
      }
      else {
        message.error("Please select some ingredient.")
      }
    }
    else {
      setIsSubRecipe(false)
      let ingredient_id = selectedItem.id;
      if (ingredient_id !== undefined) {
        let data = { ingredient_id: ingredient_id, unit_id: selIngredient["unit_id"], rate: selIngredient["rate"], standard_unit_id: selIngredient["standard_unit_id"] }
        setIsBusy(true)
        apiCreateCustomUnitConvert(data)
          .then((res) => {
            setIsBusy(false)
            var tmpData = ownCommonData;
            tmpData["unitConvertCustomList"] = [...res];
            setOwnCommonData({ ...tmpData });
            message.success("Succesfully setup!")
          })
          .catch((err) => {
            setIsBusy(false)
            message.error("Sorry. " + err);
          });
      }
      else {
        message.error("Please select some ingredient.")
      }


    }
  }

  const getData = () => {
    setIsBusy(true)
    apiGetRecipeBasicData(undefined)
      .then((data) => {
        prepareData(data);
      })
      .catch((err) => {
        //  message.error("Server failed. " + err);
      });
  }
  const prepareData = (recipeBasicData) => {
    const { categoryList } = recipeBasicData === undefined ? { categoryList: undefined } : recipeBasicData;
    const { unitList } = recipeBasicData === undefined ? { unitList: undefined } : recipeBasicData;
    const { locationList } = recipeBasicData === undefined ? { locationList: undefined } : recipeBasicData;
    const { subRecipeList } = recipeBasicData === undefined ? { subRecipeList: undefined } : recipeBasicData;
    const { ingredientList } = recipeBasicData === undefined ? { ingredientList: undefined } : recipeBasicData;
    const { unitConvertGlobalList } = recipeBasicData === undefined ? { unitConvertGlobalList: undefined } : recipeBasicData;
    const { unitConvertCustomList } = recipeBasicData === undefined ? { unitConvertCustomList: undefined } : recipeBasicData;
    const { salableTypeList } = recipeBasicData === undefined ? { salableTypeList: undefined } : recipeBasicData;
    if (recipeBasicData != undefined && recipeBasicData.ingredientList !== undefined) {

      let tmp_ingredientList = ingredientList
      //merge inventory and yield list
      for (let i = 0; i < tmp_ingredientList.length; i++) {
        tmp_ingredientList[i]["virtual_id"] = i
        tmp_ingredientList[i]["total_qty"] = tmp_ingredientList[i]["qty"]
        tmp_ingredientList[i]["subItemList"] = [{
          add_timestamp: tmp_ingredientList[i]["add_timestamp"],
          update_timestamp: tmp_ingredientList[i]["update_timestamp"],
          average_price_per_unit: tmp_ingredientList[i]["average_price_per_unit"],
          id: tmp_ingredientList[i]["id"],
          inv_name_id: tmp_ingredientList[i]["inv_name_id"],
          name: tmp_ingredientList[i]["name"],
          qty: tmp_ingredientList[i]["qty"],
          spoilage: tmp_ingredientList[i]["spoilage"],
          status: tmp_ingredientList[i]["status"],
          total_qty: tmp_ingredientList[i]["total_qty"],
          unit_id: tmp_ingredientList[i]["unit_id"],
          virtual_id: tmp_ingredientList[i]["virtual_id"],
          yield_name_id: tmp_ingredientList[i]["yield_name_id"],
        }]
      }
      tmp_ingredientList = tmp_ingredientList.concat(subRecipeList)
      //merge ingredientList and subRecipeList
      for (let i = tmp_ingredientList.length - subRecipeList.length; i < tmp_ingredientList.length; i++) {
        tmp_ingredientList[i]["virtual_id"] = i
      }

      var tmpData = ownCommonData;
      tmpData["locationList"] = locationList;
      tmpData["unitList"] = unitList;
      tmpData["categoryList"] = categoryList;
      tmpData["subRecipeList"] = subRecipeList;
      tmpData["ingredientList"] = tmp_ingredientList;
      tmpData["unitConvertGlobalList"] = unitConvertGlobalList;
      tmpData["unitConvertCustomList"] = unitConvertCustomList;
      tmpData["salableTypeList"] = salableTypeList;

      setOwnCommonData({ ...tmpData });
      setIsBusy(false)
    }
  }

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="custom-base-select-ingredient-dlg"
      closable={true}
      maskClosable={false}
      title={`Config Ingredient Info`}
      onOk={() => {
        onOkAction()
      }}
    >
      <Spin spinning={isBusy}>

        <Row align={"middle"} gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div>Available Ingredient*</div>
            <Row align={"middle"} gutter={[24,12]}>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <RecipeTypeSelector
                  placeholder={"Select Ingredient"}
                  field={"virtual_id"}
                  list={ownCommonData.ingredientList}
                  displayField="name"
                  idField="virtual_id"
                  productInfo={selIngredient}
                  setProductInfo={setSelIngredient}
                  errorField={errorField}
                  setErrorField={setErrorField}
                  onChangeHandle={getSubItemList}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <CommonButton
                  customClass={"product-upload-basic-unit-add-button"}
                  onClick={() => {
                    getData()
                  }}
                >
                  Refresh
                </CommonButton>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={4}>
            <div>Enter QTY :  </div>
            {getCalculatedQTY()}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={4}>
            <div>Selected Unit :  &nbsp;</div>
            {getSelectedItemUnitName()}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={4}>
            <div>Calculated Cost :  </div> $
            {getCalculatedPrice()}
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {isSubRecipe ?
              <SubRecipeTableWidget
                dataList={dataList}
                handleChangeQtyforEachItem={handleChangeQtyforEachItem}
                ownCommonData={ownCommonData}
              /> :
              <TableWidget
                dataList={dataList}
                handleChangeQtyforEachItem={handleChangeQtyforEachItem}
                ownCommonData={ownCommonData}
              />
            }


          </Col>

        </Row>
        <Row align={"middle"} gutter={16} style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <div>This is custom unit converstaion for selected ingredient. Will you setup the conversation to another unit (gram, ml and so on)? * &nbsp; &nbsp;
              <Radio.Group onChange={onChangeConvert} value={isConvertUnit}>
                <Radio value={0}>No</Radio>
                <Radio value={1}>Yes</Radio>
              </Radio.Group>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={1}>
            <div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={2}>
            <div>
              <InputNumber
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "4px 12px",
                  width: "100%"
                }}
                defaultValue={1}
                disabled={true}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <ProductTypeSelector
              disabled={!isConvertUnit}
              placeholder={"Select Unit"}
              field={"unit_id"}
              productInfo={selIngredient}
              setProductInfo={setSelIngredient}
              list={ownCommonData.unitList}
              displayField="name"
              idField="id"
            />
          </Col>
          <div> = </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <ProductInputNumber
              disabled={!isConvertUnit}
              field={"rate"}
              productInfo={selIngredient}
              setProductInfo={setSelIngredient}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <ProductTypeSelector
              disabled={!isConvertUnit}
              placeholder={"Select Unit"}
              field={"standard_unit_id"}
              productInfo={selIngredient}
              setProductInfo={setSelIngredient}
              list={ownCommonData.unitList}
              displayField="name"
              idField="id"
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={3}>
            <Row gutter={16} justify="center">
              <CommonButton
                customClass={"product-upload-basic-unit-add-button"}
                onClick={() => {
                  isConvertUnit ? setupUnitCustomConvert() : message.error("Please select Yes or No enable Status")
                }}>
                &nbsp;&nbsp;Setup&nbsp;&nbsp;
              </CommonButton>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(SelectIngredientDialog);
