import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCreateCustomUnitConvert, urlDeleteCustomUnitConvert, urlGetCustomUnitConvertList, urlGetGlobalUnitConvertList, urlUpdateCustomUnitConvert, urlDeleteGlobalUnitConvert, urlCreateGlobalUnitConvert, urlUpdateGlobalUnitConvert, urlGetUnitBasicInfo } from "./CONSTANTS";


export const apiGetUnitBasicInfo = () => {
  const url = urlGetUnitBasicInfo;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiCreateCustomUnitConvert(data) {
    const url = urlCreateCustomUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("ingredient_id", data.ingredient_id === undefined ? "" : data.ingredient_id);
    formData.append("recipe_id", data.recipe_id === undefined ? "" : data.recipe_id);
    formData.append("unit_id", data.unit_id);
    formData.append("rate", data.rate);
    formData.append("standard_unit_id", data.standard_unit_id);
    
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  export function apiDeleteCustomUnitConvert(id) {
    const url = urlDeleteCustomUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  export const apiGetCustomUnitConvertList = () => {
    const url = urlGetCustomUnitConvertList;
    return new Promise((resolve, reject) => {
      axiosGet(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export function apiUpdateCustomUnitConvert(data) {
    const url = urlUpdateCustomUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", data.id);
    formData.append("ingredient_id", data.ingredient_id === undefined ? "" : data.ingredient_id);
    formData.append("recipe_id", data.recipe_id === undefined ? "" : data.recipe_id);
    formData.append("unit_id", data.unit_id);
    formData.append("rate", data.rate);
    formData.append("standard_unit_id", data.standard_unit_id);
    
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  export const apiGetGlobalUnitConvertList = () => {
    const url = urlGetGlobalUnitConvertList;
    return new Promise((resolve, reject) => {
      axiosGet(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  

  export function apiCreateGlobalUnitConvert(data) {
    const url = urlCreateGlobalUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("unit_id", data.unit_id);
    formData.append("rate", data.rate);
    formData.append("standard_unit_id", data.standard_unit_id);
    
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  export function apiUpdateGlobalUnitConvert(data) {
    const url = urlUpdateGlobalUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", data.id);
    formData.append("unit_id", data.unit_id);
    formData.append("rate", data.rate);
    formData.append("standard_unit_id", data.standard_unit_id);
    
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  export function apiDeleteGlobalUnitConvert(id) {
    const url = urlDeleteGlobalUnitConvert;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }