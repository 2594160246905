import React, { useState} from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import "./InventoryItemWidget.css"
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';

function InventoryItemWidget(props) {
    const { inventoryInfo, onClickEdit, onClickDelete } = props
    const [detail_button_flag, setDetailShown] = useState(false);
    return (
        <div className={"one-product-widget-container"}
            style={{ position: "relative" }}
            onMouseEnter={() => setDetailShown(false)}
            onMouseLeave={() => setDetailShown(false)}>
      <div className="one-product-image-container">
        <img
          className="one-product-image"
          src={inventoryInfo.thumbnail_image}
        />
        
      </div>

      <div className="one-product-info-container">
        <div className="one-product-info-title">{inventoryInfo.name.name}</div>
        <div
          className="one-product-info-text"
          style={{
            color: "var(--orangeColor)",
            fontWeight: "bold",
          }}
        >
          {inventoryInfo.update_timestamp}
        </div>
        <div className="one-product-info-text"  style={{marginTop:"10px"}}>
          QTY : {inventoryInfo.qty}
        </div>
        <div className="one-product-info-text">
          Unit : &nbsp;
          {inventoryInfo.unit.name}
        </div>
        <div className="one-product-info-text">
          Cost Per Unit : &nbsp;$
          {inventoryInfo.price_per_unit}
        </div>
      </div>
      {inventoryInfo.status !== undefined && (
        <div>
          <div
          style={{
            background:
            inventoryInfo.status === "0"
                ? "var(--blueColor)"
                : inventoryInfo.status == "1"
                ? "var(--blueColor)"
                : 'var(--blueColor)',
            fontWeight: "bold",
            color: "white",
            position: "absolute",
            top: "3%",
            right:"33%",
            width: "100%",
            textAlign: "center",
            padding: 10,
            opacity: 0.8,
            transform: 'rotate(320deg)'
          }}
        >
          <span className="one-product-info-status">{inventoryInfo.status === '0' ? "Inventory" :"Pending" }</span>
        </div>
          <div className="detail-button-group" style={{display:detail_button_flag?"block":"none"}}>
            <div className="detail-button-view-bg" onClick={ async()=>{
                 onClickEdit(inventoryInfo)
              }}>
              <EditOutlined/>
            </div>
            <div className="detail-button-delete-bg" onClick={ async()=>{
                    var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this inventory?" });
                    if (res == false) return;
                    onClickDelete(inventoryInfo)
              }}>
              <DeleteOutlined/>
            </div>
            
          </div>
        </div>
      )}
    </div>
    )
}

export default InventoryItemWidget
