import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin } from "antd";
import moment from "moment"
import "./DetailCookTableDialog.css"
import QRCodeSVG from 'qrcode.react';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { isEmpty } from 'utils/GlobalFunctions';
const { RangePicker } = DatePicker;
const { Option } = Select;

function DetailCookTableDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      if (record["reservation_start_timestamp"] == "") {
        record["reservation_start_timestamp"] = moment('00:00:00', 'HH:mm:ss')
      }
      if (record["reservation_end_timestamp"] == "") {
        record["reservation_end_timestamp"] = moment('23:59:59', 'HH:mm:ss')
      }
      setData({ ...record });
    } else {
      setData({ table_name: "", seat_count: "1"});
    }
  }, [record]);

  const validateFields = async () => {
    var errorList = Array();
    if (data["status"] == "1") {
      errorList = isEmpty(data, "reservation_client_name", errorList);
      errorList = isEmpty(data, "reservation_start_timestamp", errorList);
      errorList = isEmpty(data, "reservation_end_timestamp", errorList);
      errorList = isEmpty(data, "reservation_phone_number", errorList);
      errorList = isEmpty(data, "reservation_seat_count", errorList);
      errorList = isEmpty(data, "reservation_description", errorList);
      if (data["reservation_seat_count"] > data["seat_count"]) {
        message.error("People Number should be blow than Table Seat Count.")
        return false    
      }
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  function onChangeDatePicker(date, dateString) {
    var tmpData = data
    tmpData["reservation_start_timestamp"] = dateString[0]
    tmpData["reservation_end_timestamp"] = dateString[1]
    setData({ ...tmpData })
  }

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-cook-dlg"
      onOk={async () => {
        if ((await validateFields()) === false) return;
        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>QR code</div>
          <Row align={"middle"} justify={'center'}>
            <Col>
              <QRCodeSVG
                value={"https://guest.fnb-max.com?table_name=" + data["table_name"]}
                //value={"http://192.168.0.69:3000?table_name=" + data["table_name"]}
                size={256}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
              />
            </Col>
          </Row>
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Table Name*</div>
              <div style={{
                border: "1px solid #d9d9d9",
                borderRadius: "8px",
                background: `var(--backgroundColor)`,
                padding: "8px 12px"
              }}>
                <span> {data["table_name"]} </span>
              </div>

            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Table Seat Count*</div>
              <div style={{
                border: "1px solid #d9d9d9",
                borderRadius: "8px",
                background: `var(--backgroundColor)`,
                padding: "8px 12px"
              }}>
                <span> {data["seat_count"]} </span>
              </div>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation Status*</div>
              <Select defaultValue="0" style={{
                border: errorField !== undefined && errorField.includes("status")
                  ? "1px solid red"
                  : "1px solid #d9d9d9",
                width: "100%"
              }}
                value={data["status"]}
                onChange={(value) => {
                  data["status"] = value
                  setData({ ...data })
                }}
              >
                <Option value="1">Yes</Option>
                <Option value="0">No</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation Client Name*</div>
              <ProductDescriptionInput
                disabled={data["status"] == "1" ? false : true}
                placeholder="Name"
                field={"reservation_client_name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation People Number*</div>
              <Select 
                disabled={data["status"] == "1" ? false : true}
                defaultValue="0"
                style={{
                  border: errorField !== undefined && errorField.includes("reservation_seat_count")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  width: "100%"
                }}
                value={data["reservation_seat_count"]}
                onChange={(value) => {
                  data["reservation_seat_count"] = value
                  setData({ ...data })
                }}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="4">4</Option>
                <Option value="6">6</Option>
                <Option value="8">8</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Reservation Phone Number*</div>
              <ProductDescriptionInput
                disabled={data["status"] == "1" ? false : true}
                placeholder="Phone Number"
                field={"reservation_phone_number"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Reservation Description*</div>
              <ProductDescriptionInput
                disabled={data["status"] == "1" ? false : true}
                placeholder="Description"
                field={"reservation_description"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Reservation Time*</div>
              <RangePicker
                disabled={data["status"] == "1" ? false : true}
                style={{
                  border: errorField !== undefined && (errorField.includes("reservation_start_timestamp") || errorField.includes("reservation_end_timestamp"))
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  width: "100%"
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }}
                format="YYYY-MM-DD HH:mm:ss"
                size={"large"}
                ranges={{
                  "Today": [moment(), moment()],
                  "This Week": [moment().startOf("week"), moment().endOf("week")],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "This Quarter": [
                    moment().startOf("quarter"),
                    moment().endOf("quarter"),
                  ],
                  "This Year": [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                }}
                value={[moment(data["reservation_start_timestamp"]), moment(data["reservation_end_timestamp"])]}
                onChange={onChangeDatePicker} 
                />

            </Col>

          </Row>

        </div>
      </Spin>
    </Modal >
  );
}

export default confirmable(DetailCookTableDialog)
