import React from "react";
import { message, Tabs, DatePicker, Row, Col  } from 'antd';
import "./UpcomingOrder.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import { apiApproveOrder, apiPaymentConfirmOrder } from "services/orderService";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
const { RangePicker } = DatePicker;

function UpcomingOrder(props) {
    const { ownCommonData, setOwnCommonData, setIsBusy} = props;
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        setSearchList(ownCommonData.upcomingOrderList)
    }, [ownCommonData.upcomingOrderList]);

    const handleSearch = (searchText) => {
        const tmpList = ownCommonData.upcomingOrderList.filter((info) => info.customer_name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(ownCommonData.upcomingOrderList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const approveAction = async(record) => {
        if (record.payment_status === null || record.payment_status === undefined || record.payment_status === '') {
            var res_1 = await showConfirmDlgWidget({ title: "Payment Confirm to Approve this order", content: "Payment for this order is completed?" });
            if (res_1 == false) {
                var res = await showConfirmDlgWidget({ title: "Payment Confirm to Approve this order", content: "Payment is not completed for order.  After deliver is finished, you can check payament, again. Will you continue to approve?" });
                if (res == false) return;
                handleApproveAction(record)
            }
            else {
                paymentConfirmAction(record)       
            }
            
        }
        else {
            handleApproveAction(record)
        }
    }
    const paymentConfirmAction = async(record) => {
        
        setIsBusy(true);
        apiPaymentConfirmOrder(record)
            .then((data) => {
                setIsBusy(false)
                message.success("Payment Confirm Success")
                record.payment_status = 'Completed';

                ownCommonData.upcomingOrderList.splice(ownCommonData.upcomingOrderList.findIndex(e => e.id === record.id),1);
                let upcomingOrderList = ownCommonData.upcomingOrderList.concat(record)
                setOwnCommonData({...ownCommonData, upcomingOrderList: upcomingOrderList})
                setSearchList(ownCommonData.upcomingOrderList)

                handleApproveAction(record)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Payment Confirm Error. " + err);
            });
    }
    const handleApproveAction = (record)=> {
        setIsBusy(true);
        apiApproveOrder(record.id)
        .then((data) => {
            setIsBusy(false)
            ownCommonData.upcomingOrderList.splice(ownCommonData.upcomingOrderList.findIndex(e => e.id === data.id),1);
            let approvedOrderList = ownCommonData.approvedOrderList.concat(data)
            setOwnCommonData({...ownCommonData, approvedOrderList: approvedOrderList})
        })
        .catch((err) => {
            setIsBusy(false);
            message.error("Approve Error. " + err);
        });
    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...ownCommonData.upcomingOrderList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = ownCommonData.upcomingOrderList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    return (
        <div>
            <Row>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                        <RangePicker size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }

                            }} />
                    </div>
                </Col>
                <Col flex={'auto'}>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                </Col>
            </Row>
            <div>
                <TableWidget
                    dataList={searchList}
                    setDataList={setSearchList}
                    approveAction = {approveAction}
                />
            </div>
        </div>
    );
}

export default UpcomingOrder;
