import React, { useEffect, useState } from "react";

import { Button, Select, Space, Spin, message, Row } from "antd";
import { IMAGE_BASE_URL } from "../../config/CONSTANTS";
import { urlSearchProduct } from "../../services/CONSTANTS";
import { axiosGet } from "../../services/ajaxServices";
import "./ProductSearch.css"
const { Option } = Select;

function ProductSearch(props) {
  const { ownProducts,  onSelectProduct } = props;
  
  const [fetching, setFetching] = useState(false);
  const [tempProduct, setTempProduct] = useState([]);

  useEffect(() => {
  }, []);
  const fetchProduct = (value) => {
    console.log("fetching Product", value);
    setFetching(true);
    setTempProduct([]);
    const tmpList = ownProducts.filter((info)=>info.name.name.includes(value))
    console.log(tmpList)
    setTempProduct([...tmpList]);
    setFetching(false);

  };
  const handleFocus = (value) => {
    setFetching(false);
    setTempProduct(ownProducts);
  };

  return (
    <Row justify="center" style={{marginTop:20}}>
      <Select
        labelInValue 
        placeholder="Search inventory by name"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        showSearch={true}
        onSearch={(v) => fetchProduct(v)}
        onFocus={handleFocus}
        className="custom-product-search-select"
        onSelect={(v) => {
          var index = 0
          index = v.value
          var product = tempProduct[index];
          onSelectProduct(product)
        }}
        style={{
          width:"90%",
        }}
      >
        {tempProduct.map((pInfo, index) => {
          //   console.log(d);
          console.log("info", pInfo)

          return (
            <Option key={index} className="product-search-one-item-container">
              <Space dispatch={"horizontal"}>
                <img
                  src={pInfo.thumbnail_image}
                  className="product-search-image"
                />
                <div>
                  {pInfo.name.name} - {pInfo.description}
                  {/* <br/>
                  Category: {pInfo.category.name} */}
                </div>
              </Space>
            </Option>
          );
        })}
      </Select>
    </Row>
  );
}

export default ProductSearch;
