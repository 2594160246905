import React, { useRef } from "react";
import "./BackgroundToolWidget.css";
import { TwitterPicker } from "react-color";
import {
  ShapeTool,
  ShapeType,
} from "config/CONSTANTS";
import { message } from "antd";
import Picker from "emoji-picker-react";

const BackgroundToolWidget = (props) => {
  const { baseDrawInfo, setBaseDrawInfo, onAddShape, setSelTool, width, height} = props;

  const imageRef = useRef(null);
  const onChangeFillColor = (color, event) => {
    setBaseDrawInfo({
      ...baseDrawInfo,
      fill_color: color["hex"],
    });
  };
  const onDropBackgroundImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    const file = files[0];
    if (file.size / 1000 / 1000 > 1) {
      message.error("Image size should not be greater than 1Mb.");
      return;
    }
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        const imageData = reader.result;
        let image = new window.Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageData;

        image.onload = function () {
          let imageWidth = image.width;
          let imageHeight = image.height;

          if (imageWidth > imageHeight) {
            const ratio = imageWidth / imageHeight;
            imageHeight = height;
            imageWidth = height * ratio;
          } else {
            const ratio = imageHeight / imageWidth;
            imageWidth = width;
            imageHeight = width * ratio;
          }

          const shape = {
            type: ShapeType.IMAGE,
            x: (width - imageWidth) / 2,
            y: (height - imageHeight) / 2,
            width: imageWidth,
            height: imageHeight,
            image: image,
          };

          setBaseDrawInfo({
            ...baseDrawInfo,
            background: shape,
          });
        };
      },
      false
    );

    reader.readAsDataURL(file);

    imageRef.current.value = "";
  };

  // const onEmojiClick = (event, emojiObject) => {
  //   setSelTool(ShapeTool.NONE);
  //   const { unified } = emojiObject;
  //   const { stroke } = baseDrawInfo;
  //   const baseEmojiUrl =
  //     "https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/";
  //   const emojiUrl = `${baseEmojiUrl}${unified}.png`;
  //   let image = new window.Image();
  //   image.crossOrigin = "Anonymous";
  //   image.src = emojiUrl;
  //   const id = `remote-image-${Date.now()}`;

  //   const shape = {
  //     id: id,
  //     type: ShapeType.IMAGE,
  //     x: (width - 28 - stroke * 3) / 2,
  //     y: (height - 28 - stroke * 3) / 2,
  //     width: 28 + stroke * 3,
  //     height: 28 + stroke * 3,
  //     image: image,
  //     isDradding: false,
  //   };
  //   onAddShape(shape);
  // };
  return (
    <div className="background-too-widget">
      {/* <div style={{ marginTop: 20 }}>
        <Picker onEmojiClick={onEmojiClick} />
      </div> */}
      <div className="custom-button story-button" style={{ marginTop: 50 }}>
        <label htmlFor="background_image_uploader">
          <input
            type="file"
            id="background_image_uploader"
            name="background_image_uploader"
            style={{ display: "none" }}
            accept="image/x-png,image/jpeg"
            onChange={onDropBackgroundImage}
            ref={imageRef}
          />
          <span>Add Background</span>
        </label>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TwitterPicker
          colors={[
            "#FFFFFF",
            "#FF6900",
            "#FCB900",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#ABB8C3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
          ]}
          color={baseDrawInfo["fill_color"]}
          onChange={onChangeFillColor}
        />
      </div>
    </div>
  );
};

export default BackgroundToolWidget;
