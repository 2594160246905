import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateUserSiteMainCategory, urlDeleteUserSiteMainCategory, urlGetUserSiteMainCategoryList, urlUpdateUserSiteMainCategory, urlUpdateUserSiteMainCategoryPosition } from "./CONSTANTS";

export function apiCreateMainCategory(data) {
  const url = urlCreateUserSiteMainCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("status", data.status);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateMainCategory(data) {
  const url = urlUpdateUserSiteMainCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("status", data.status);
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateMainCategoryPosition(dataList) {
  const url = urlUpdateUserSiteMainCategoryPosition;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var idList = []
  for (let i = 0; i<dataList.length; i++) {
    idList.push({id : dataList[i]['id']})
  }
  var formData = new FormData();
  formData.append("dataList", JSON.stringify(idList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetMainCategoryList = () => {
  const url = urlGetUserSiteMainCategoryList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiDeleteMainCategory(id) {
  const url = urlDeleteUserSiteMainCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
