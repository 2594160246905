import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { getCurrencyInfo, isEmpty } from 'utils/GlobalFunctions';
import DetailTable from './DetailTable';
const { Option } = Select;

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList } = props;

    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "title",
        }
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                showHeader={false}
                pagination={dataList.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    return <DetailTable
                        dataList={record.items} />
                }}
            />
        </div>
    )
}

export default withRouter(TableWidget)
