import React from "react";
import { message, Tabs, DatePicker, Row, Col } from 'antd';
import "./DeliveredOrder.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import { apiPaymentConfirmOrder } from "services/orderService";
const { RangePicker } = DatePicker;
function DeliveredOrder(props) {
    const { ownCommonData, setOwnCommonData, setIsBusy} = props;
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        setSearchList(ownCommonData.deliveredOrderList)
    }, [ownCommonData.deliveredOrderList]);

    const handleSearch = (searchText) => {
        const tmpList = ownCommonData.deliveredOrderList.filter((info) => info.customer_name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(ownCommonData.deliveredOrderList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const paymentConfirmAction = async(record) => {
        
        setIsBusy(true);
        apiPaymentConfirmOrder(record)
            .then((data) => {
                setIsBusy(false)
                message.success("Payment Confirm Success")
                record.payment_status = 'Completed';
                ownCommonData.deliveredOrderList.splice(ownCommonData.deliveredOrderList.findIndex(e => e.id === record.id),1);
                let deliveredOrderList = ownCommonData.deliveredOrderList.concat(record)
                setOwnCommonData({...ownCommonData, deliveredOrderList: deliveredOrderList})
                setSearchList(ownCommonData.deliveredOrderList)

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Payment Confirm Error. " + err);
            });
    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...ownCommonData.deliveredOrderList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = ownCommonData.deliveredOrderList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    return (
        <div>
            <Row>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                        <RangePicker size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }

                            }} />
                    </div>
                </Col>
                <Col flex={'auto'}>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                </Col>
            </Row>
            <div>
                <TableWidget
                    dataList={searchList}
                    setDataList={setSearchList}
                    paymentConfirmAction={paymentConfirmAction}
                />
            </div>
        </div>
    );
}

export default DeliveredOrder;
