// @flow strict

import { Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../../../services/ajaxServices";
import "./AddBaseUnitDialog.css"
function AddBaseUnitDialog(props) {
  const { show, proceed } = props;
  const { url, unitName, oldList } = props;
  const [value, setValue] = useState("");
  const createOneUnit = () => {
    //   proceed()
    if (value == "") return;
    const newValue = value.trim();
    setValue(newValue);
    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("name", newValue);
    new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then((res) => {
        if (res == null) {
          message.error("There's same value from other vendor");
          return;
        }
        proceed({
          addedItem: res,
        });
      })
      .catch((err) => {

      });
    });
  };
  
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="custom-base-unit-dlg"
      closable={true}
      maskClosable={false}
      title={`${unitName} Add Dialog`}
      onOk={createOneUnit}
    >
      <div>Please specify new {unitName} you want to add.</div>
      <Input className="normal-input" value={value} onChange={(e) => setValue(e.target.value)} />
    </Modal>
  );
}

export default confirmable(AddBaseUnitDialog);
