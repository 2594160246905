import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin } from "antd";
import "./AddInventoryDialog.css"
import { apiGetInventoryBasicData } from 'services/inventoryService';
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { urlCreateInventoryLocation, urlCreateInventoryName, urlCreateInventoryUnit } from 'services/CONSTANTS';
import { showAddBaseUnitDialog } from './AddBaseUnitDialog/showAddBaseUnitDialog';
import { showAddNewCategoryDialog } from './AddNewCategoryDialog/showAddNewCategoryDialog';
import { apiGetUnitConvertByID } from 'services/unitConvertService';

function AddInventoryDialog(props) {
  const { show, proceed, inventoryInfo, title, isEditing } = props

  const [inventoryBasicData, setInventoryBasicData] = useState({});
  const [ownCommonData, setOwnCommonData] = useState({});
  const [selInventory, setSelInventory] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    apiGetInventoryBasicData()
      .then((data) => {
        setInventoryBasicData(data);
      })
      .catch((err) => {
        // message.error("Server failed. " + err);
      });
  }, [])

  useEffect(() => {
    const { categoryList } = inventoryBasicData === undefined ? { categoryList: undefined } : inventoryBasicData;
    const { unitList } = inventoryBasicData === undefined ? { unitList: undefined } : inventoryBasicData;
    const { locationList } = inventoryBasicData === undefined ? { locationList: undefined } : inventoryBasicData;
    const { nameList } = inventoryBasicData === undefined ? { nameList: undefined } : inventoryBasicData;

    if (inventoryBasicData != undefined) {
      setOwnCommonData({
        locationList: locationList,
        unitList: unitList,
        categoryList: categoryList,
        nameList: nameList,
      });
    }
  }, [inventoryBasicData]);

  useEffect(() => {
    if (inventoryInfo !== undefined) {
      setSelInventory({ ...inventoryInfo });
    } else {
      setSelInventory({});
    }
  }, [inventoryInfo]);
  const {
    nameList,
    locationList,
    unitList,
    categoryList,
  } = ownCommonData;
  if (categoryList == undefined) {
    return <div></div>;
  }
  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selInventory, "image_name", errorList);
    errorList = isEmpty(selInventory, "unit_id", errorList);
    errorList = isEmpty(selInventory, "location_id", errorList);
    errorList = isEmpty(selInventory, "category_id", errorList);
    errorList = isEmpty(selInventory, "description", errorList);
    errorList = isEmpty(selInventory, "name_id", errorList);
    errorList = isEmpty(selInventory, "price_per_unit", errorList);
    errorList = isEmpty(selInventory, "qty", errorList);
    errorList = isEmpty(selInventory, "spoilage", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const createNewCategory = async () => {
    var res = await showAddNewCategoryDialog({
      title: "New Category",
      isEdit: false,
      oldList: categoryList
    });
    if (res == null) return;

    var tmpData = ownCommonData;
    let updateData = [...tmpData["categoryList"]];

    var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
    updateData.splice(storeIndex + 1, 0, res['addedItem']);

    tmpData["categoryList"] = [...updateData];
    setOwnCommonData({ ...tmpData });
    message.success(`new Category has been created.`);
  };


  const addNewBaseUnit = async (fieldName, fieldTitle, createUrl) => {
    var res = await showAddBaseUnitDialog({
      url: createUrl,
      unitName: fieldTitle,
      oldList: ownCommonData[fieldName],
    });
    if (res == null) return;

    var tmpData = ownCommonData;
    let updateData = [...tmpData[fieldName]];

    updateData = [res['addedItem'], ...updateData];
    tmpData[fieldName] = [...updateData];
    setOwnCommonData({ ...tmpData });
    message.success(`${fieldTitle} has been created.`);
  };

  
  return (
    <Modal
      open={inventoryBasicData == undefined ? !show : show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-inventory-dlg"
      onOk={async () => {

        if (isEditing === false && (await validateFields()) === false) return;

        proceed({
          ...selInventory,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Inventory Image*</div>
          <ProductImageWidget
            field={"image_name"}
            productInfo={selInventory}
            setProductInfo={setSelInventory}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Name*</div>
              <Row align={"middle"} gutter={16}>
                <Col flex={"auto"}>
                  <ProductTypeSelector
                    disabled={isEditing}
                    placeholder={"Select Name"}
                    field={"name_id"}
                    productInfo={selInventory}
                    setProductInfo={setSelInventory}
                    list={nameList}
                    displayField="name"
                    idField="id"
                    errorField={errorField}
                    setErrorField={setErrorField}
                  />
                </Col>
                <Col flex={"30px"}>
                  <Tooltip title={"Click to add new one."}>
                    <CommonButton
                      customClass={"product-upload-basic-unit-add-button"}
                      onClick={() => {
                        addNewBaseUnit("nameList", "Name", urlCreateInventoryName);
                      }}
                    >
                      Add
                    </CommonButton>
                  </Tooltip>
                </Col>

              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Category*</div>
              <Row align={"middle"}>
                <Col flex={"auto"}>
                  <ProductTypeSelector
                    disabled={isEditing}
                    field={"category_id"}
                    productInfo={selInventory}
                    setProductInfo={setSelInventory}
                    placeholder={"Select Category"}
                    list={categoryList}
                    errorField={errorField}
                    setErrorField={setErrorField}
                    displayField="name"
                    idField="id"
                    isCategory={true}
                  />
                </Col>
                <Col flex={"30px"} style={{ marginLeft: "20px" }}>
                  <Tooltip title={"Click to add new one."}>
                    <CommonButton
                      customClass={"product-upload-basic-unit-add-button"}
                      onClick={() => {
                        createNewCategory();
                      }}
                    >
                      Add
                    </CommonButton>
                  </Tooltip>
                </Col>

              </Row>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Location*</div>
              <Row align={"middle"} gutter={16}>
                <Col flex={"auto"}>
                  <ProductTypeSelector
                    disabled={isEditing}
                    placeholder={"Select Location"}
                    field={"location_id"}
                    productInfo={selInventory}
                    setProductInfo={setSelInventory}
                    list={locationList}
                    displayField="name"
                    idField="id"
                    errorField={errorField}
                    setErrorField={setErrorField}
                  />
                </Col>
                <Col flex={"30px"}>
                  <Tooltip title={"Click to add new one."}>
                    <CommonButton
                      customClass={"product-upload-basic-unit-add-button"}
                      onClick={() => {
                        addNewBaseUnit("locationList", "Location", urlCreateInventoryLocation);
                      }}
                    >
                      Add
                    </CommonButton>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Unit*</div>
              <Row align={"middle"} gutter={16}>
                <Col flex={"auto"}>
                  <ProductTypeSelector
                    disabled={isEditing}
                    placeholder={"Select Unit"}
                    field={"unit_id"}
                    productInfo={selInventory}
                    setProductInfo={setSelInventory}
                    list={unitList}
                    displayField="name"
                    idField="id"
                    errorField={errorField}
                    setErrorField={setErrorField}
                  />
                </Col>
                <Col flex={"30px"}>
                  <Tooltip title={"Click to add new one."}>
                    <CommonButton
                      customClass={"product-upload-basic-unit-add-button"}
                      onClick={() => {
                        addNewBaseUnit("unitList", "Unit", urlCreateInventoryUnit);
                      }}
                    >
                      Add
                    </CommonButton>
                  </Tooltip>
                </Col>

              </Row>
            </Col>
          </Row>

          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>QTY*</div>
              <ProductInputNumber
                disabled={isEditing}
                field={"qty"}
                min={"0.0000001"}
                productInfo={selInventory}
                setProductInfo={setSelInventory}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Description*</div>
              <ProductDescriptionInput
                placeholder="Description"
                field={"description"}
                productInfo={selInventory}
                setProductInfo={setSelInventory}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>
          <Row align={"middle"} gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Spoilage*</div>
              <ProductInputNumber
                min={"-10000"}
                field={"spoilage"}
                productInfo={selInventory}
                setProductInfo={setSelInventory}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Cost Per Unit*</div>
              <ProductInputNumber
                disabled={isEditing}
                field={"price_per_unit"}
                productInfo={selInventory}
                setProductInfo={setSelInventory}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>

          



        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddInventoryDialog)
