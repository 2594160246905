import React from 'react';
import "./CommonDivWidget.css"
const CommonDivWidget = (props) => {
    return (
        <div className="common-background" style={props.style}>
            {props.children}
        </div>
    );
};

export default CommonDivWidget;