import React, { useState } from "react";
import { Layer, Line, Rect } from "react-konva";
import { ShapeType} from "config/CONSTANTS";
const ShapeEditingDraw = (props) => {
  const { onAddShape, baseDrawInfo, width, height } = props;
  const { color, stroke } = baseDrawInfo;
  const [pointList, setPointList] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const handleMouseDown = (e) => {
    setIsDrawing(true);

    // const pos = e.target.getStage().getPointerPosition();
    // setPointList([...pointList, [pos.x, pos.y]]);
    setPointList([...pointList, e["evt"]["offsetX"], e["evt"]["offsetY"]]);
  };
  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (isDrawing == false) {
      return;
    }
    // const pos = e.target.getStage().getPointerPosition();
    // setPointList([...pointList, [pos.x, pos.y]]);
    setPointList([...pointList, e["evt"]["offsetX"], e["evt"]["offsetY"]]);
  };
  const handleMouseUp = () => {
    if (isDrawing == false) {
      return;
    }
    setIsDrawing(false);
    const id = `line-${Date.now()}`;

    const shape = {
      id: id,
      type: ShapeType.LINE,
      color: color,
      isDragging: false,
      stroke: stroke,
      points: pointList,
    };
    setPointList([]);
    onAddShape(shape);
  };
  return (
    <Layer
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
    >
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={"transparent"}
      />
      <Line
        points={pointList}
        stroke={color}
        strokeWidth={stroke}
        tension={0.5}
        lineCap="round"
      />
    </Layer>
  );
};

export default ShapeEditingDraw;
