import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, } from "antd";
import DetailTable from './DetailTable';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { getCurrencyInfo } from 'utils/GlobalFunctions';
function TableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, onUpdateAction, onDeleteAction, } = props;
  if (dataList === undefined) return <div />



  const columns = [
    {
      title: "No",
      dataIndex: "id",
      align: 'center',
    },
    {
      title: "Name",
      dataIndex: "name",
      align: 'center',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      render:(text, record) => {
        if (record["inv_name_id"]) {
          return <span className="ingredient-rule-mix-name-item">  {text} </span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item">  {text} </span>
        }
        
      }
    },
    {
      title: "Current Qty",
      dataIndex: "qty",
      align: 'center',
      sorter: {
        compare: (a, b) => a.qty - b.qty,
      },
      render:(text, record) => {
        if (record["inv_name_id"]) {
          return <span className="ingredient-rule-mix-name-item">  {text} </span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item">  {text} </span>
        }
        
      }
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_name - b.unit_name,
      },
    },
    {
      title: "Average Cost/Unit",
      dataIndex: "average_price_per_unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.average_price_per_unit - b.average_price_per_unit,
      },
      render:(text, record) => {
        if (record["inv_name_id"]) {
          return <span className="ingredient-rule-mix-name-item"> { "$(" + getCurrencyInfo() + ") " + text}</span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item">  { "$(" + getCurrencyInfo() + ") " + text}</span>
        }
      }
    },
    {
      title: "Created At",
      dataIndex: "add_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated At",
      dataIndex: "update_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: 'center',
    //   render: (text, record) => {

    //     return <div size="middle" direction={"vertical"}>
    //       <a className="edit-btn"
    //       onClick={() => {

    //       }}
    //     >
    //       Detail
    //     </a>
    //       <a style={{ color: "var(--redColor)" , marginLeft:20}} className="del-btn" onClick={async () => {
    //         var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
    //         if (res == false) return;
    //       }}>
    //         Delete
    //       </a>
    //     </div>
    //   },
    // },
  ];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id , unit_name: info.unit.name};
  });

  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={dataSource}
        // expandedRowRender={record => {
        //   if (record.total_count !== 0) {
        //     return <DetailTable
        //       dataList={record.subItemList}
        //       onUpdateAction={onUpdateAction}
        //       onDeleteAction={onDeleteAction} />
        //   }
        //   else {
        //     return <div className="ant-table-expanded-row-fixed" style={{ width: "1428px", position: 'sticky', left: 0, overflow: 'hidden' }}>
        //       <div className="ant-empty ant-empty-normal">
        //         <div className="ant-empty-image">
        //           <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
        //           <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        //             <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
        //             <g className="ant-empty-img-simple-g" fillRule="nonzero">
        //               <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
        //             </g>
        //           </g>
        //         </svg>
        //         </div>
        //         <div className="ant-empty-description">No Data</div>
        //       </div>
        //     </div>
        //   }

        // }}
      />
    </div>
  )
}

export default withRouter(TableWidget)
