import { Row, Spin, Col, DatePicker, Space } from 'antd';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget';
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { apiGetPayHistoryList, apiGetPayHistoryListWithDate } from 'services/payHistoryService';
import "./PayHistoryPage.css";
import TableWidget from './TableWidget/TableWidget';
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CsvDownloader from 'react-csv-downloader';
import ReactExport from "react-export-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;


function PayHistoryPage() {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    useEffect(() => {
        var data = {
            start_date : "",
            end_date : ""
        }
        getData(data);
    }, []);

    const getData = (data) => {
        setDataList([]);
        setSearchList([]);

        setIsBusy(true);
        apiGetPayHistoryListWithDate(data)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data);
            })
            .catch((err) => {
                setIsBusy(false);
                // message.error("Server failed. " + err);
            });
    };

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.customer_name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            // const start_date = new Date(startDate + "T00:00:00");
            // const end_date = new Date(endDate + "T23:59:59");
            // const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            // setSearchList([...tmpList])
            
            var data = {
                start_date : startDate,
                end_date : endDate
            }
            getData(data)
        }
    }
    const columns = [{
        id: 'id',
        displayName: 'Id'
    }, {
        id: 'customer_name',
        displayName: 'Customer Name'
    }, {
        id: 'payment_amount',
        displayName: 'Pay Amount'
    }, {
        id: 'payment_currency',
        displayName: 'Pay Currency'
    }, {
        id: 'payment_fee',
        displayName: 'Pay Fee'
    }, {
        id: 'payment_status',
        displayName: 'Pay Status'
    }, {
        id: 'add_timestamp',
        displayName: 'Created At'
    }, {
        id: 'update_timestamp',
        displayName: 'Updated At'
    }
    ];
    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Pay History PDF";
        const headers = [["Id", "Customer Name", 'Pay Amount', 'Pay Currency', 'Pay Fee', 'Pay Status', 'Created At', 'Updated At']];
        const data = searchList.map(elt => [elt.id, elt.customer_name, elt.payment_amount, elt.payment_currency, elt.payment_fee, elt.payment_status, elt.add_timestamp, elt.update_timestamp]);


        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("pay_history_info.pdf")
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <Row justify={"space-between"}>
                    <Col>
                        <span style={{ fontFamily: 'mediumFont', paddingRight: 20 }}>Date Filter Period: </span>
                        <RangePicker
                            allowClear={false}
                            defaultValue={[moment(startDate), moment(endDate)]}
                            size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }

                            }} />
                    </Col>
                    <Col>
                        {
                            <div>
                                <CsvDownloader
                                    filename="pay_history_info"
                                    extension=".csv"
                                    className="common-button"
                                    style={{ border: 'none', margin: 10 }}
                                    columns={columns}
                                    datas={searchList}
                                    text="Export CSV" />
                                <ExcelFile filename={"pay_history_info"} element={<button className="common-button"
                                    style={{ border: 'none', margin: 10 }} >Export XLXS</button>}>
                                    <ExcelSheet data={searchList} name="pay_history">
                                        <ExcelColumn label="Id" value="id" widthCh={300} />
                                        <ExcelColumn label="Customer Name" value="customer_name" />
                                        <ExcelColumn label="Pay Amount" value="payment_amount" />
                                        <ExcelColumn label="Pay Currency" value="payment_currency" />
                                        <ExcelColumn label="Pay Fee" value="payment_fee" />
                                        <ExcelColumn label="Pay Status" value="payment_status" />
                                        <ExcelColumn label="Created At" value="add_timestamp" />
                                        <ExcelColumn label="Updated At" value="update_timestamp" />
                                    </ExcelSheet>
                                </ExcelFile>
                                <CommonButton onClick={() => {
                                    exportPDF()
                                }} style={{ margin: 10 }}>Export PDF</CommonButton>
                            </div>
                        }
                    </Col>
                </Row>
                <div className="pay-history-search" style={{ marginTop: 20 }}>
                    <TitleSearch
                        onSearch={handleSearch}
                        onChange={handleChange}
                        onPressEnter={handleSearch}
                    />
                </div>

                <div>
                    <TableWidget
                        dataList={searchList}
                        setDataList={setSearchList}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
};

export default PayHistoryPage;