import React from 'react';
import { Col, Row, } from "antd";
import Icon from '@ant-design/icons';
import "./CategoryItemWidget.css"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget';
const CategoryItemWidget = (props) => {
    return (
        <CommonDivWidget>
            <Row>
                <Icon component={props.icon} theme="outlined" className="category-item-icon" />
            </Row>
            <Row className="category-txt-title">
                <span>{props.title} {props.count}</span>
            </Row>
            <Row justify={'space-between'} align={'middle'} style={{ marginTop: "22px" }}>
                <Col className="category-txt-type">{props.type}</Col>
                <Col>
                    <CommonButton customClass="category-item-create-button" onClick={() => {
                        props.onClick()
                    }}>
                        View
                    </CommonButton>
                </Col>
            </Row>
        </CommonDivWidget>
    );
};

export default CategoryItemWidget;