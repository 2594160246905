import React from "react";
import { Typography } from "antd";
import { LinkRoute } from "components/LinkRoute";
import { ROOT } from "./CONSTANTS";

export const NotFound = () => {
  return (
    <div>
      {/* Page Not Found! */}
      <LinkRoute to={ROOT}>Home</LinkRoute>
      <Typography variant="h2">404: page not found!</Typography>
    </div>
  );
};
