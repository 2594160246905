import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, Divider } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import { apiDeleteIventoryUnit, apiGetInventoryUnit } from 'services/unitService';
import { showAddNewUnitDialog } from './UnitCreateDialog/showAddNewUnitDialog';
import UnitListTableWidget from './UnitListTableWidget/UnitListTableWidget';
import "./UnitListPage.css"

function UnitListPage(props) {
    const { ownCommonData, setOwnCommonData} = props;
    const [isBusy, setIsBusy] = useState(false);
    const [unitListInv, setUnitListInv] = useState([]);
    const [searchListInv, setSearchListInv] = useState([]);
    
    useEffect(() => {
        setUnitListInv(ownCommonData.unitList);
        setSearchListInv(ownCommonData.unitList)
    }, [ownCommonData.unitList]);

    
    const createNewUnitInv = async () => {
        var res = await showAddNewUnitDialog({
            title: "New Unit",
            isEdit: false,
            oldList: unitListInv,
        });
        if (res == null) return;

        var tmpData = unitListInv;
        let updateData = [...tmpData];

        updateData.splice(tmpData.length, 0, res['addedItem']);

        tmpData = [...updateData];
        setUnitListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`new Unit has been created.`);
    };

    const onUpdateActionInv = async (info) => {
        var res = await showAddNewUnitDialog({
            title: "Edit Unit",
            isEdit: true,
            oldList: unitListInv,
            selInfo: info
        });
        if (res == null) return;

        var tmpData = unitListInv;
        let updateData = [...tmpData];

        var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
        updateData.splice(storeIndex, 1, res['addedItem']);

        tmpData = [...updateData];
        setUnitListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`Unit has been updated.`);
    }
    const onDeleteActionInv = (pInfo) => {
        setIsBusy(true);
        apiDeleteIventoryUnit(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setUnitListInv(data);
                setSearchListInv(data);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }

    const handleSearchInv = (searchText) => {
        const tmpList = unitListInv.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchListInv([...tmpList])
    };
    const handleChangeInv = (event) => {
        if (event.target.value === "") {
            setSearchListInv(unitListInv)
        }
        else {
            handleSearchInv(event.target.value)
        }
    };
    return (
            <Spin spinning={isBusy}>
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Unit List</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                        <TitleSearch
                                            onSearch={handleSearchInv}
                                            onChange={handleChangeInv}
                                            onPressEnter={handleSearchInv}
                                        />
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewUnitInv();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <UnitListTableWidget
                                        unitList={searchListInv}
                                        onUpdateAction={onUpdateActionInv}
                                        onDeleteAction={onDeleteActionInv}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Spin>
    );
};


export default UnitListPage
