import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, } from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';

const DetailTable = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, onUpdateAction, onDeleteAction, } = props;
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "No",
      dataIndex: "virtual_id",
      align: 'center',
    },
    {
      title: "Yield Name",
      dataIndex: "yield_name",
      align: 'center',
      render:(text, record) => {
        if (record.yield_rule_id === "-1" || record.yield_rule_id === "-1") {
          console.log("record", record)
          return <span className="ingredient-rule-inventory-name-item"> &nbsp; {text} &nbsp;</span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item"> &nbsp; {text} &nbsp;</span>
        }
      }
    },
    {
      title: "Yield Qty",
      dataIndex: "yield_qty",
      align: 'center',
    },
    {
      title: "Unit",
      dataIndex: "unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit - b.unit,
      },
      render: text => <span>kg</span>,
    },
    {
      title: "Yield Test",
      dataIndex: "yield_test",
      align: 'center',
      render: text => <span>{text} %</span>,

    },
    {
      title: "Inventory",
      dataIndex: "inv_name",
      align: 'center',
      sorter: {
        compare: (a, b) => a.inv_name.localeCompare(b.inv_name)
      },
      render:(text, record) => {
        if (record.yield_rule_id === "-1" || record.yield_rule_id === "-1") {
          console.log("record", record)
          return <span className="ingredient-rule-inventory-name-item"> &nbsp; {text} &nbsp;</span>
        }
        else {
          return <span className="ingredient-rule-yield-name-item"> &nbsp; {text} &nbsp;</span>
        }
      }
    },
    {
      title: "Qty",
      dataIndex: "inv_qty",
      align: 'center',
      sorter: {
        compare: (a, b) => a.inv_qty - b.inv_qty
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit - b.unit,
      },
      render: text => <span>kg</span>,
    },
    {
      title: "Cost/Unit",
      dataIndex: "inv_average_price_per_unit",
      align: 'center',
      sorter: {
        compare: (a, b) => a.inv_average_price_per_unit - b.inv_average_price_per_unit
      },
      render: text => <span>$ {text}</span>,
    },
    {
      title: "Created At",
      dataIndex: "rule_add_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.rule_add_timestamp.localeCompare(b.rule_add_timestamp),
      },
    },
    {
      title: "Updated At",
      dataIndex: "update_timestamp",
      align: 'center',
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return showColumnAction(text, record, onUpdateAction, onDeleteAction)
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.virtual_id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(DetailTable)
