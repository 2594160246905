import React, { useState,useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import "./AvatarWidget.css";
import { showImageReadDlg } from "components/ImageReadDlg/showImageReadDlg";
const AvatarWidget = (props) => {
  const {defaultImageUrl, setNewImageUrl, disabled} = props;
  const [ imageUrl, setImageUrl ] = useState("")
  
  useEffect(() => {
    setImageUrl(defaultImageUrl);
  }, [defaultImageUrl]);
  
  const onShowImageDlg = async()=>{
    const newImage = await showImageReadDlg({
      title: "Upload Photo",
      currentImage: defaultImageUrl,
    });
    if (newImage !== null) {
      if (newImage.length === 0) return;
      setImageUrl(URL.createObjectURL(newImage));
      setNewImageUrl(newImage)
    }
  }
  const onDropImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setImageUrl(URL.createObjectURL(files[0]));
    setNewImageUrl(files[0])
  };
  return (
    <div className="avatar-container">
        <div className="avatar-image-container">
          <img className="avatar-image" src={imageUrl === null ? '/assets/icons/ic_logout.svg' : imageUrl} />
          {(disabled === undefined || (disabled !== undefined && !disabled)) && <div className="avatar-edit-button-container">
            <div
              className="avatar-edit-button custom-button"
              onClick={onShowImageDlg}
            >
              <EditOutlined />
            </div>
          </div>}
        </div>
      </div>
  );
}

export default AvatarWidget;
