import { ConsoleSqlOutlined, ToTopOutlined } from '@ant-design/icons';
import { Spin, message, Row, Col, Tooltip, Divider } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import React, { useEffect, useState } from 'react'
import { apiGetInventoryLocation,  apiDeleteIventoryLocation} from 'services/inventoryService';
import { apiDeleteRecipeLocation, apiGetRecipeLocation } from 'services/recipeService';
import { showAddNewLocationDialog } from './InventoryLocationDialog/showAddNewLocationDialog';
import LocationListTableWidget from './LocationListTableWidget/LocationListTableWidget';
import "./LocationPage.css"
import { showAddNewLocationDialogRecipe } from './RecipeLocationDialog/showAddNewLocationDialogRecipe';

function LocationPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [locationListInv, setLocationListInv] = useState([]);
    const [searchListInv, setSearchListInv] = useState([]);
    const [locationListRec, setLocationListRec] = useState([]);
    const [searchListRec, setSearchListRec] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetInventoryLocation()
            .then((data) => {
                setIsBusy(false);
                setLocationListInv(data);
                setSearchListInv(data)
            })
            .catch((err) => {
                setIsBusy(false);
                //message.error("Server failed. " + err);
            });

        apiGetRecipeLocation()
        .then((data) => {
            setIsBusy(false);
            setLocationListRec(data);
            setSearchListRec(data)
        })
        .catch((err) => {
            setIsBusy(false);
            //message.error("Server failed. " + err);
        });
    };

    const createNewLocationInv = async () => {
        var res = await showAddNewLocationDialog({
            title: "New Location",
            isEdit: false,
            oldList: locationListInv,
        });
        if (res == null) return;

        var tmpData = locationListInv;
        let updateData = [...tmpData];

        updateData.splice(tmpData.length, 0, res['addedItem']);

        tmpData = [...updateData];
        setLocationListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`new Location has been created.`);
    };

    const onUpdateActionInv = async (info) => {
        var res = await showAddNewLocationDialog({
            title: "Edit Location",
            isEdit: true,
            oldList: locationListInv,
            selInfo: info
        });
        if (res == null) return;

        var tmpData = locationListInv;
        let updateData = [...tmpData];

        var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
        updateData.splice(storeIndex, 1, res['addedItem']);

        tmpData = [...updateData];
        setLocationListInv(tmpData);
        setSearchListInv(tmpData)
        message.success(`Location has been updated.`);
    }
    const onDeleteActionInv = (pInfo) => {
        setIsBusy(true);
        apiDeleteIventoryLocation(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setLocationListInv(data);
                setSearchListInv(data);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating Location. " + err);
            });
    }

    const handleSearchInv = (searchText) => {
        const tmpList = locationListInv.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchListInv([...tmpList])
    };
    const handleChangeInv = (event) => {
        if (event.target.value === "") {
            setSearchListInv(locationListInv)
        }
        else {
            handleSearchInv(event.target.value)
        }
    };


    const createNewLocationRec = async () => {
        var res = await showAddNewLocationDialogRecipe({
            title: "New Location",
            isEdit: false,
            oldList: locationListRec,
        });
        if (res == null) return;

        var tmpData = locationListRec;
        let updateData = [...tmpData];

        updateData.splice(tmpData.length, 0, res['addedItem']);

        tmpData = [...updateData];
        setLocationListRec(tmpData);
        setSearchListRec(tmpData)
        message.success(`new Location has been created.`);
    };

    const onUpdateActionRec = async (info) => {
        var res = await showAddNewLocationDialogRecipe({
            title: "Edit Location",
            isEdit: true,
            oldList: locationListRec,
            selInfo: info
        });
        if (res == null) return;

        var tmpData = locationListRec;
        let updateData = [...tmpData];

        var storeIndex = updateData.findIndex(x => x.id == res['addedItem'].id);
        updateData.splice(storeIndex, 1, res['addedItem']);

        tmpData = [...updateData];
        setLocationListRec(tmpData);
        setSearchListRec(tmpData)
        message.success(`Location has been updated.`);
    }
    const onDeleteActionRec = (pInfo) => {
        setIsBusy(true);
        apiDeleteRecipeLocation(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setLocationListRec(data);
                setSearchListRec(data);

            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }

    const handleSearchRec = (searchText) => {
        const tmpList = locationListRec.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchListRec([...tmpList])
    };
    const handleChangeRec = (event) => {
        if (event.target.value === "") {
            setSearchListRec(locationListRec)
        }
        else {
            handleSearchRec(event.target.value)
        }
    };

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Basic Inventory Location List</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={20}>
                                        <TitleSearch
                                            onSearch={handleSearchInv}
                                            onChange={handleChangeInv}
                                            onPressEnter={handleSearchInv}
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewLocationInv();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <LocationListTableWidget
                                        locationList={searchListInv}
                                        onUpdateAction={onUpdateActionInv}
                                        onDeleteAction={onDeleteActionInv}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row gutter={[24, 16]}  className="table-bg">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1>Recipe Location List</h1>
                                <Row align={"middle"} gutter={16}>
                                    <Col xs={18} sm={18} md={18} lg={18} xl={20}>
                                        <TitleSearch
                                            onSearch={handleSearchRec}
                                            onChange={handleChangeRec}
                                            onPressEnter={handleSearchRec}
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                                        <Tooltip title={"Click to add new one."}>
                                            <CommonButton
                                                customClass={"product-upload-basic-unit-add-button"}
                                                onClick={() => {
                                                    createNewLocationRec();
                                                }}
                                            >
                                                New
                                            </CommonButton>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10}}>
                                    <LocationListTableWidget
                                        locationList={searchListRec}
                                        onUpdateAction={onUpdateActionRec}
                                        onDeleteAction={onDeleteActionRec}             
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                
                </Row>

            </Spin>
        </CommonDivWidget>
    );
};


export default LocationPage
