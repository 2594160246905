import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Spin, Col, Select, Space, message } from 'antd';
import "./SettingPage.css";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiGetSetting, apiUpdateSetting } from "services/settingService";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import { updateUser } from "redux/actions/userActions";
import ProductInputMultiText from "components/InputWidgets/InputMultiText/ProductInputMultiText";
const { Option } = Select;

function SettingPage() {
    const userDataStore = useSelector(x => x.userDataStore);
    const userInfo = userDataStore.user;
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({})
    useEffect(() => {
        apiGetSetting()
            .then((res) => {
                for (let i = 0; i < res.length; i++) {
                    data[res[i]["option_name"]] = res[i]["option_value"]
                }
                setData({ ...data })
            })
            .catch((err) => {
                // message.error("Sorry! " + err);
            });
    }, []);

    const Save = () => {
        setIsBusy(true);
        apiUpdateSetting(data)
            .then((res) => {
                setIsBusy(false);
                message.success("Succesfully Saved!")
                let tmpUserData = { ...userInfo, "primary_currency": data["primary_currency"] }
                dispatch(updateUser({ user: tmpUserData }))
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry ! error. " + err);
            });
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div className="text-header">Setting Info
                    <div className="text-extra">
                        <CommonButton onClick={() => {
                            Save()
                        }}>Save</CommonButton>
                    </div>
                </div>
                <Row align={"middle"} gutter={[24, 24]} style={{ marginTop: 5, marginBottom: 8 }}>
                    <Col xs={24} sm={8} md={24} lg={8} xl={8}>
                        <div style={{ marginBottom: 5 }}>Rent (%)</div>
                        <ProductInputNumber
                            min={"0"}
                            field={"rent"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                    <Col xs={24} sm={8} md={24} lg={8} xl={8}>
                        <div style={{ marginBottom: 5 }}>TAX VAT (%)</div>
                        <ProductInputNumber
                            min={"0"}
                            field={"tax_vat"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                    <Col xs={24} sm={8} md={24} lg={8} xl={8}>
                        <div style={{ marginBottom: 5 }}>Primary Currency
                            (
                            <span>{data["primary_currency"]}</span>
                            )
                        </div>
                        <Select defaultValue="0" style={{
                            width: "100%",
                            border: "1px solid #d9d9d9",
                        }}
                            value={data["primary_currency"]}
                            onChange={(value) => {
                                data["primary_currency"] = value
                                setData({ ...data })
                            }}
                        >
                            <Option value="USD">USD</Option>
                            <Option value="SGD">SGD</Option>
                            <Option value="THB">THB</Option>
                        </Select>
                    </Col>
                </Row>
                <Row align={"middle"} gutter={[24, 24]} style={{ marginTop: 16, marginBottom: 8 }}>
                    <Col xs={24} sm={8} md={24} lg={24} xl={12}>
                        <div style={{ marginBottom: 5 }}>Chef Receipt Print Header Title</div>
                        <ProductInputMultiText
                            field={"chef_print_header_title"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                    <Col xs={24} sm={8} md={24} lg={24} xl={12}>
                        <div style={{ marginBottom: 5 }}>Chef Receipt Print Header Description</div>
                        <ProductInputMultiText
                            field={"chef_print_header_description"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                </Row>
                <Row align={"middle"} gutter={[24, 24]} style={{ marginTop: 16, marginBottom: 8 }}>
                    <Col xs={24} sm={8} md={24} lg={24} xl={12}>
                        <div style={{ marginBottom: 5 }}>Chef Receipt Print Footer Title</div>
                        <ProductInputMultiText
                            field={"chef_print_footer_title"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                    <Col xs={24} sm={8} md={24} lg={24} xl={12}>
                        <div style={{ marginBottom: 5 }}>Chef Receipt Print Footer Description</div>
                        <ProductInputMultiText
                            field={"chef_print_footer_description"}
                            productInfo={data}
                            setProductInfo={setData}
                        />
                    </Col>
                </Row>
            </Spin>
        </CommonDivWidget>

    );
}

export default SettingPage;
