// @flow strict

import * as React from "react";
import { Select } from "antd";
import "./RecipeTypeSelector.css";
const { Option } = Select;

function RecipeTypeSelector(props) {
  const { field, productInfo, setProductInfo, onChangeHandle } = props;
  const { displayField, idField } = props;
  const { errorField, setErrorField } = props;
  const { placeholder, list, disabled } = props;
  const display = displayField === undefined ? "name" : displayField;
  const id = idField === undefined ? "id" : idField;
  return (
    <div>
      <Select
        showSearch
        disabled={list == undefined || list.length == 0 || disabled == true}
        style={{
          width: "100%",
          border: errorField !== undefined && errorField.includes(field) ? "1px solid red" : "1px solid #d9d9d9",
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        value={
          productInfo === undefined || productInfo[field] == undefined ||
            list.filter((x) => x[id] == productInfo[field]).length == 0
            ? null
            : productInfo[field]
        }
        onChange={(value) => {
          var info = productInfo;
          info[field] = value;
          if (errorField !== undefined && errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            setErrorField([...errors]);
          }
          setProductInfo({ ...info });
          if (onChangeHandle !== undefined) {
            onChangeHandle(value)
          }
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {list !== undefined &&
          list.map((info) => {
            // if (productInfo !== undefined && productInfo["inv_name_id"] !== undefined) {
            //   if (info.hasOwnProperty('has_yield') == true && info.id === productInfo["inv_name_id"]) {
            //     <Option
            //       key={`${field}_key${info.virtual_id}`}
            //       disabled={true}
            //       value={info.virtual_id}
            //       style={{
            //         fontWeight: "normal",
            //         color: (info.hasOwnProperty('sub_recipe') == true) ? "var(--greenColor)" : ((info.yield_name_id === undefined || info.yield_name_id === null) ? "var(--blueColor)" : "var(--orangeColor)"),
            //       }}
            //     >
            //       {info[display]}
            //     </Option>
            //   }
            // }
            return (
              <Option
                key={`${field}_key${info.virtual_id}`}
                value={info.virtual_id}
                style={{
                  fontWeight: "normal",
                  color: (info.hasOwnProperty('sub_recipe') == true) ? "var(--greenColor)" : ((info.yield_name_id === undefined || info.yield_name_id === null) ? "var(--blueColor)" : "var(--orangeColor)"),
                }}
              >
                {info[display]}
              </Option>

            );
          })}
      </Select>
    </div>
  );
}

export default RecipeTypeSelector;
