import { Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateInventoryLocation, apiUpdateIventoryLocation } from "services/inventoryService";
import "./AddNewLocationDialog.css";
const { TextArea } = Input;
function AddNewLocationDialog(props) {
  const { proceed, show } = props;
  const { selInfo, oldList } = props;
  const [newLocation, setNewLocation] = useState({});
  const { isEdit, title } = props;

  useEffect(()=>{
    if(selInfo !== undefined){
      setNewLocation(selInfo)
    }
  }, [selInfo])
//   console.log(oldList);
  const createNewLocation = () => {
    if (newLocation.name == ""){
      message.error(`Please enter location name`);
      return;
    } 
    console.log("oldList-", oldList);
    const newValue = newLocation.name.trim();
    setNewLocation({ ...newLocation, name: newValue });

    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    apiCreateInventoryLocation(newLocation)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Location. " + err);
        });
  };

  const updateNewLocation = () => {
    if (newLocation.name == ""){
      message.error(`Please enter Location name`);
      return;
    } 
    console.log("oldList-", oldList);
    const newValue = newLocation.name.trim();
    setNewLocation({ ...newLocation, name: newValue });

    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    apiUpdateIventoryLocation(newLocation)
        .then((data) => {
            proceed({
              addedItem: data,
            });

        })
        .catch((err) => {
            message.error("There's something wrong while creating Location. " + err);
        });
  };

  const onOKAction = () => {
    if (isEdit === false) {
      createNewLocation()
    }
    else {
      updateNewLocation()
    }
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-new-category-dlg"
      onOk={onOKAction}
    >
      <div>Please Enter new Location Name.</div>
      <div style={{ marginTop: 10 }}>name *</div>
      <Input
        value={newLocation.name === undefined ? "":newLocation.name}
        className="normal-input"
        onChange={(e) =>
          setNewLocation({ ...newLocation, name: e.target.value })
        }
      />
    </Modal>
  );
}

export default confirmable(AddNewLocationDialog);
