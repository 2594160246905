import React from "react";
import { Layer, Rect, Image } from "react-konva";
const LayerBackground = (props) => {
  const { baseDrawInfo, setBaseDrawInfo, width, height } = props;
  const { fill_color, background } = baseDrawInfo;
  
  return (
    <Layer id="background-layer">
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        //   fill={fill_color}
        fillLinearGradientStartPoint={{ x: width / 2, y: 0 }}
        fillLinearGradientEndPoint={{ x: width / 2, y: height }}
        fillLinearGradientColorStops={[0, fill_color, 1, `${fill_color}5f`]}
      />
      {background != null && (
        <Image
          x={background["x"]}
          y={background["y"]}
          width={background["width"] == undefined ? null : background["width"]}
          height={
            background["height"] == undefined ? null : background["height"]
          }
          image={background["image"]}
          draggable
        />
      )}
    </Layer>
  );
};

export default LayerBackground;
