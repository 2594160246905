import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin, DatePicker } from "antd";

import "./AddInvestmentDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

function AddInvestmentDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({month_of_depriciation:1, start_date:moment().format(dateFormat)});
    }
  }, [record]);

  function onChangeDatePicker(date, dateString) {
      if (!dateString) {
        message.info("Please select start date")
        return
      }
      var tmpData = data
      tmpData["start_date"] = date.format(dateFormat)
      
      setData({ ...tmpData }) 
  }

  const validateFields = async () => {
    
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    errorList = isEmpty(data, "cost", errorList);
    errorList = isEmpty(data, "start_date", errorList);
    errorList = isEmpty(data, "month_of_depriciation", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-investment-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Investment Avatar*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Enter Name*</div>
              <ProductDescriptionInput
                placeholder="name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Cost*</div>
              <ProductInputNumber
                field={"cost"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Start Date*</div>
              <DatePicker 
                value={dayjs(data["start_date"], dateFormat)}
                onChange={onChangeDatePicker} 
                picker="date"
                size='middle' 
                style={{
                  border: errorField !== undefined && errorField.includes("start_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width:"100%"
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Month of depriciation*</div>
              <ProductInputNumber
                min={1}
                field={"month_of_depriciation"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>

          
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddInvestmentDialog)
