import { message, Slider, Select, Input, Divider } from "antd";
import React, { useRef, useState } from "react";
import { TwitterPicker } from "react-color";
import {
  FontFamily,
  ShapeTool,
  ShapeType,
} from "config/CONSTANTS";

import "./EditorToolWidget.css";

import {
  FontColorsOutlined,
  EditOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import NormalInput from "components/StorePage/NormalInput";

const { Option } = Select;
const { TextArea } = Input;
const EditorToolWidget = (props) => {
  const { baseDrawInfo, setBaseDrawInfo } = props;
  const { storyInformation, setStoryInformation } = props;
  const { errorField, setErrorField } = props;
  const { selTool, setSelTool } = props;
  const { onAddShape } = props;
  const imageRef = useRef();

  const storyHeight = 100
  const storyWidth = 100
  const onChangeColor = (color, event) => {
    setBaseDrawInfo({
      ...baseDrawInfo,
      color: color["hex"],
    });
  };

  const onClickAddImage = async (e) => {
    setSelTool(ShapeTool.NONE);
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    const file = files[0];
    if (file.size / 1000 / 1000 > 1) {
      message.error("Image size should not be greater than 1Mb.");
      return;
    }
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        const imageData = reader.result;
        const id = `local-image-${Date.now()}`;
        let image = new window.Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageData;

        image.onload = function () {
          let imageWidth = image.width;
          let imageHeight = image.height;

          if (imageWidth > storyWidth && imageHeight > storyHeight) {
            const ratio = imageWidth / imageHeight;
            imageHeight = storyHeight;
            imageWidth = storyHeight * ratio;
          }
          const shape = {
            id: id,
            type: ShapeType.IMAGE,
            x: 30,
            y: 30,
            width: imageWidth,
            height: imageHeight,
            image: image,
            isDradding: false,
            image_name: file,

          };
          onAddShape(shape);
        };
      },
      false
    );

    reader.readAsDataURL(file);

    imageRef.current.value = "";
  };

  return (
    <div className="editor-tool-container">
      {/* <div>
        <div style={{ paddingBottom: "5px" }}>Title</div>
        <NormalInput
          placeholder="Title"
          field={"title"}
          vendorInfo={storyInformation}
          setVendorInfo={setStoryInformation}
          errorField={errorField}
          setErrorField={setErrorField}
        />
      </div> */}
      {/* <div>
        <div style={{ marginTop: 5, paddingBottom: "2px" }}>
          Description(optional)
        </div>
        <TextArea
          placeholder="Description"
          rows={5}
          value={storyInformation.description}
          onChange={(e) => {
            setStoryInformation({
              ...storyInformation,
              description: e.target.value,
            });
          }}
          className="common-input"
        />
      </div> */}
      <Divider />

      <div>
        <div>
          <div>Size</div>
          <Slider
            min={1}
            max={20}
            onChange={(v) => {
              setBaseDrawInfo({ ...baseDrawInfo, stroke: v });
            }}
            value={baseDrawInfo["stroke"]}
          />
        </div>
        <div>
          <TwitterPicker
            colors={[
              //   "#FFFFFF",
              "#000000",
              "#FF6900",
              "#FCB900",
              "#00D084",
              "#8ED1FC",
              "#0693E3",
              "#ABB8C3",
              "#EB144C",
              "#F78DA7",
              "#9900EF",
            ]}
            color={baseDrawInfo["color"]}
            onChange={onChangeColor}
          />
        </div>
        <div style={{ marginTop: 10}}>
          <Select
            value={baseDrawInfo["font_family"]}
            onChange={(e) => {
              setBaseDrawInfo({ ...baseDrawInfo, font_family: e });
            }}
            style={{ width: "100%" }}
          >
            {FontFamily.map((font) => (
              <Option key={font} value={font}>
                {font[0].toUpperCase()}
                {font.substring(1)}
              </Option>
            ))}
          </Select>
        </div>
        <div
          className="custom-button story-button"
          style={{ marginTop: 20 }}
          onClick={() => {
            message.info("Press Enter after you type a text.")
            if (selTool == ShapeTool.TEXT) {
              setSelTool(ShapeTool.NONE);
            } else {
              setSelTool(ShapeTool.TEXT);
            }
          }}
        >
          <FontColorsOutlined /> Add Text
        </div>


        <div
          className="custom-button story-button"
          style={{
            background:
              selTool == ShapeTool.PENCIL ? "#1A73E8" : "var(--blueColor)",
            marginTop: 20,
          }}
          onClick={() => {
            if (selTool == ShapeTool.PENCIL) {
              setSelTool(ShapeTool.NONE);
            } else {
              setSelTool(ShapeTool.PENCIL);
            }
          }}
        >
          <EditOutlined /> Add Draw
        </div>

        <div className="custom-button story-button" style={{ marginTop: 20 }}>
          <label htmlFor="new_image_uploader">
            <input
              type="file"
              id="new_image_uploader"
              name="new_image_uploader"
              style={{ display: "none" }}
              accept="image/x-png,image/jpeg"
              onChange={onClickAddImage}
              ref={imageRef}
            />
            <div>
              <FileImageOutlined /> Add Image
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default EditorToolWidget;
